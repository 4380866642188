import React from 'react';
import BlockSkeleton from '../../Skeleton/Block';
import Slider from '../../Slider';
import RestaurantSlide from './Slide';
import useWindowSize from '../../../hooks/useResize';

export default function Gallery({ pictures }) {
  const { isDesktop } = useWindowSize();

  if (!pictures) {
    return (
      <BlockSkeleton
        width="100%"
        className="section"
        height={500}
        style={{ marginTop: '35px', marginBottom: '20px' }}
      />
    );
  }
  return (
    <Slider
      slidesPerView="auto"
      scrollbarReduced={isDesktop}
      reduced={isDesktop}
      slideClassName="restaurant-slider"
      elements={pictures.bottomSlider}
      spaceBetween={30}
      slide={RestaurantSlide}
    />
  );
}
