import { useEffect, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import utilities from '../../../../../utilities';
import Autocomplete from '../../../../UI/Form/Autocomplete';

export default function AddressSelect() {
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);
  const { register, setValue, getValues } = useFormContext();
  const autocompleteInitialValue = useMemo(() => {
    if (!getValues('street') && !getValues('house_number')) return null;
    return {
      label: `${getValues('street')} ${getValues('house_number')}`,
      value: `${getValues('street')} ${getValues('house_number')}`,
    };
  }, []);
  const fetchAddress = async (value) => {
    const response = await utilities.address.get({
      query: value,
      from_bound: {
        value: 'street',
      },
      to_bound: {
        value: 'house',
      },
      locations: [
        {
          city_fias_id: '7dfa745e-aa19-4688-b121-b655c11e482f',
        },
      ],
    });
    setOptions(
      response
        .filter((item) => !item.data.settlement)
        .map((suggestion) => ({
          origin: suggestion,
          label: suggestion.value,
          value: suggestion.value,
        }))
    );
  };

  const handleChange = ({ origin }) => {
    if (!origin.data.house) {
      setError(
        utilities.translate('Необходимо как минимум указать номер дома')
      );
    } else {
      setError(null);
      setValue('street', origin.data.street, { shouldDirty: true });
      setValue('house_number', origin.data.house, { shouldDirty: true });
      setValue(
        'point',
        {
          type: 'Point',
          coordinates: [+origin.data.geo_lon, +origin.data.geo_lat],
        },
        { shouldDirty: true }
      );
    }
  };

  useEffect(() => {
    register('point', '');
    register('street', '');
    register('house_number', '');
  }, [register]);

  return (
    <Autocomplete
      label={utilities.translate('Адрес')}
      error={error}
      initialValue={autocompleteInitialValue}
      asyncCallback={fetchAddress}
      options={options}
      onChange={handleChange}
    />
  );
}
