/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Dishes from '../components/Dishes/Dishes';
import Filter from '../components/Dishes/Filter';
import Info from '../components/Dishes/Info';
import {
  DishesContextProvider,
  DishesFilterContextProvider,
} from '../context/Dishes';
import { EstablishmentsContext } from '../context/Establishments';

const RestaurantMenu = () => {
  const { establishmentId } = useParams();
  const [establishment, setEstablishment] = useState(null);
  const establishments = useContext(EstablishmentsContext);

  useEffect(() => {
    if (establishments) {
      setEstablishment(
        establishments.find(({ slug }) => slug === establishmentId)
      );
    }
  }, [establishments]);

  return (
    <>
      {establishment && (
        <Helmet>
          <title>{`Меню ${establishment?.name}: меню ресторана в Краснодаре`}</title>
          <meta
            name="title"
            content={`Меню ${establishment?.name}: меню ресторана в Краснодаре`}
          />
          <meta
            name="description"
            content={`${establishment?.name} в Краснодаре. Подробнее смотрите на сайте сети ресторанов Мадьяр.`}
          />
        </Helmet>
      )}
      <Breadcrumbs
        exact={{
          [`${establishmentId}`]: establishment?.name,
        }}
      />
      <Info />
      <DishesFilterContextProvider>
        <DishesContextProvider>
          <Filter />
          <Dishes />
        </DishesContextProvider>
      </DishesFilterContextProvider>
    </>
  );
};

export default RestaurantMenu;
