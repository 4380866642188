/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { EstablishmentsContext } from '../../../context/Establishments';
import useWindowSize from '../../../hooks/useResize';
import routes from '../../../routes';
import utilities from '../../../utilities';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import Description from '../../Description/Description';
import Section from '../../Section/Section';
import Share from '../../Share/Share';
import BlockSkeleton from '../../Skeleton/Block';
import GridSkeleton from '../../Skeleton/Grid';
import MultipleSkeleton from '../../Skeleton/Multiple';
import TextSkeleton from '../../Skeleton/Text';
import Title from '../../Title/Title';
import Slider from './Slider';

export default function NewsItemHead() {
  const { newsId } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  const [currentEstablishment, setCurrentEstablishment] = useState(null);
  const establishments = useContext(EstablishmentsContext);

  const { isDesktop } = useWindowSize();

  useEffect(() => {
    const fetchEvent = async () => {
      const response = await utilities.api.get(routes.api.news(newsId));
      setNewsItem(response.item);
    };
    fetchEvent();
  }, []);

  useEffect(() => {
    if (establishments && newsItem?.establishment_id) {
      setCurrentEstablishment(
        establishments.find(
          ({ id }) => id.toString() === newsItem.establishment_id
        )
      );
    }
  }, [establishments, newsItem]);

  return (
    <>
      <Helmet>
        <title>{newsItem?.title || 'Madyar'}</title>
        <meta name="title" content={newsItem?.title || ''} />
        <meta name="description" content={newsItem?.description || ''} />
      </Helmet>
      <Breadcrumbs
        exact={{
          [`${newsId}`]: newsItem?.title,
        }}
      />
      <Section className="event">
        {establishments ? (
          currentEstablishment ? (
            <img
              className="event__logo"
              src={currentEstablishment.logo}
              alt={currentEstablishment.name}
            />
          ) : (
            <img className="event__logo" src="/images/madyarLogo.png" alt="" />
          )
        ) : (
          <BlockSkeleton
            width={isDesktop ? 120 : 85}
            height={isDesktop ? 120 : 85}
            style={
              isDesktop ? { margin: '30px auto' } : { margin: '25px auto' }
            }
          />
        )}
        {newsItem ? (
          <Title className="event__title" tag="h1" align="center">
            {newsItem.title}
          </Title>
        ) : (
          <TextSkeleton
            style={
              isDesktop ? { marginBottom: '30px' } : { marginBottom: '20px' }
            }
            width={isDesktop ? 400 : 150}
            height={isDesktop ? 70 : 50}
            label="Имя"
          />
        )}
        <div className="event__address">
          {currentEstablishment && (
            <div>{currentEstablishment?.branches[0]?.address?.street}</div>
          )}
        </div>
        <div className="event__info event__info-news">
          {newsItem ? (
            utilities.date.toString(newsItem.date, {
              format: 'YYYY-MM-DD HH:mm:ss',
            })
          ) : (
            <GridSkeleton
              count={2}
              columns={2}
              gap={isDesktop ? 20 : 10}
              proportion={isDesktop ? 0.4 : 0.3}
              label="Загрузка истории"
            />
          )}
        </div>
        {establishments ? (
          currentEstablishment && (
            <a
              className="event__button button button--medium"
              href={`tel:${currentEstablishment?.phone}`}
            >
              {utilities.translate('Забронировать стол')}
            </a>
          )
        ) : (
          <BlockSkeleton
            width={250}
            height={40}
            style={{ margin: '0 0 25px' }}
          />
        )}
        <Slider data={newsItem} />
        {newsItem ? (
          <Description
            className="event__description"
            align="center"
            color="black"
            dangerHTML={newsItem.description}
          />
        ) : (
          <MultipleSkeleton
            style={{ marginBottom: '15px' }}
            width={isDesktop ? 600 : 250}
            gap={15}
            count={4}
            height={20}
            size="downgrade"
            downgradeStep={40}
          />
        )}
        <Share className="event__socials" />
      </Section>
    </>
  );
}
