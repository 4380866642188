/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  useContext,
} from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../../routes';
import utilities from '../../utilities';

export const FavoriteDishesContext = createContext();
export const FavoriteDishesFilterContext = createContext();

export const FavoriteDishesFilterContextProvider = (props) => {
  const location = useLocation();
  const { children } = props;
  const categorySearchRef = useRef(utilities.params.toObject(location.search));
  const [categories, setCategories] = useState(null);
  const [active, setActive] = useState(null);
  const [dishes, setDishes] = useState(null);
  const [updateDishes, setUpdateDishes] = useState(0);
  const firstMount = useRef(true);

  const setFilter = useCallback((id) => {
    setActive(categories.filter(({ id: categoryId }) => categoryId === id));
  }, []);

  const data = useMemo(
    () => ({
      categories,
      active,
    }),
    [categories, active]
  );

  useEffect(() => {
    const fetchDishes = async () => {
      const response = await utilities.api.get(
        routes.apiSwagger.dishes(),
        {
          favorite: true,
        },
        {
          headers: {
            Authorization: `Token ${utilities.storage.get(
              'madyar-auth-token'
            )}`,
          },
        }
      );
      setDishes(response.results);
    };
    fetchDishes();
  }, []);

  useEffect(() => {
    if (!firstMount.current) {
      const categorySearch = utilities.params.toObject(location.search);
      if (categorySearch.category === 'all') {
        setActive('all');
      } else if (categorySearch.category) {
        setActive(
          categories.find(
            ({ slug: categorySlug }) =>
              categorySlug.toString() === categorySearch.category
          )
        );
      }
    }
  }, [location]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await utilities.api.get(
        routes.apiSwagger.dishCategories(),
        {}
      );
      const filteredCategories = response.filter((category) =>
        dishes?.some(({ category_id }) => category_id === category.id)
      );
      setCategories(filteredCategories);
      // if (
      //   filteredCategories.find(
      //     ({ slug: categorySlug }) =>
      //       categorySlug.toString() === categorySearchRef.current.category
      //   )
      // ) {
      //   setActive(
      //     filteredCategories.find(
      //       ({ slug: categorySlug }) =>
      //         categorySlug.toString() === categorySearchRef.current.category
      //     )
      //   );
      // } else {
      //   setActive('all');
      // }
      setActive('all');
    };
    fetchCategories();
    firstMount.current = false;
  }, [dishes]);

  return (
    <FavoriteDishesFilterContext.Provider value={{ ...data }}>
      {children}
    </FavoriteDishesFilterContext.Provider>
  );
};

export const FavoriteDishesContextProvider = (props) => {
  const { children } = props;
  const [dishes, setDishes] = useState(null);
  const { active, categories } = useContext(FavoriteDishesFilterContext);
  const [clickedCategory, setClickedCategory] = useState(null);
  const location = useLocation();
  // const [updateDishes, setUpdateDishes] = useState(0);

  useEffect(() => {
    if (active === 'all') return setClickedCategory(active);
    if (active) {
      const filteredCategories = categories
        ?.filter((category) => category.slug === active.slug)
        .map(({ id }) => id);
      return setClickedCategory(filteredCategories);
    }
    return setClickedCategory(null);
  }, [active]);

  useEffect(() => {
    const fetchDishes = async () => {
      const response = await utilities.api.get(
        routes.apiSwagger.dishes(),
        {
          dish_category_id__in:
            clickedCategory !== 'all' && clickedCategory.length > 1
              ? clickedCategory
              : '',
          category_id:
            clickedCategory === 'all' || clickedCategory.length > 1
              ? ''
              : clickedCategory[0],
          favorite: true,
        },
        {
          headers: {
            Authorization: `Token ${utilities.storage.get(
              'madyar-auth-token'
            )}`,
          },
        }
      );
      setDishes(response.results);
    };
    if (clickedCategory) {
      setDishes(null);
      fetchDishes(clickedCategory);
    }
  }, [clickedCategory]);

  return (
    <FavoriteDishesContext.Provider
      value={{
        dishes,
        categories,
        activeCategory: active,
      }}
    >
      {children}
    </FavoriteDishesContext.Provider>
  );
};
