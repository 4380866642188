/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useEffect, useLayoutEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import routes from '../../routes';
import SearchForm from '../SearchForm/SearchForm';
import utilities from '../../utilities';
import Socials from '../Socials/Socials';

const Footnote = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const onMenuChange = ({ detail }) => {
    setTimeout(() => {
      setMenuOpen(detail);
    }, 500);
  };

  useEffect(() => {
    document.addEventListener('menu:status', onMenuChange);

    return () => {
      document.removeEventListener('menu:status', onMenuChange);
    };
  }, []);

  return (
    <div className="footnote">
      <Route
        path={[
          routes.page.restaurants({ link: false }),
          routes.page.home({ link: false }),
        ]}
        // eslint-disable-next-line react/no-children-prop
        render={({ match }) =>
          match?.isExact ? isMenuOpen ? <Socials /> : null : <Socials />
        }
      />
      <SearchForm
        placeHolder={utilities.translate('Введите название блюда')}
        className="footnote__search-form"
        canExpand
      />
    </div>
  );
};

export default Footnote;
