import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useMemo } from 'react';
import routes from '../../routes';

const More = (props) => {
  const { className, href } = props;
  const moreClassName = cn('more', className);

  const SVG = useMemo(
    () => (
      <svg className="more-icon" viewBox="0 0 153 10">
        <path className="more-icon__shaft" d="M0 5.03171H150" />
        <path
          className="more-icon__head"
          d="M147.071 0.757324L151.314 4.99996L147.071 9.24261"
        />
      </svg>
    ),
    []
  );

  const isExternalLink = () => {
    return href?.includes('http') || href?.includes('https');
  };

  return isExternalLink() ? (
    <a className={moreClassName} target="_blank" rel="noreferrer" href={href}>
      {SVG}
    </a>
  ) : (
    <NavLink to={href || routes.page.home()} className={moreClassName}>
      {SVG}
    </NavLink>
  );
};

export default More;
