/* eslint-disable no-unused-vars */
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '../../UI/Form/Checkbox';
import utilities from '../../../utilities';

export default function ReasonsCheckboxes({ data }) {
  const { control } = useFormContext();
  return (
    <>
      {data.map(({ id, name }) => (
        <Controller
          control={control}
          name={id.toString()}
          key={id}
          render={({ field }) => (
            <Checkbox
              square
              label={name}
              type="checkbox"
              name={id}
              id={id}
              onChange={field.onChange}
            />
          )}
        />
      ))}
    </>
  );
}
