/* eslint-disable no-unused-vars */
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Clusterer, Map, Placemark } from 'react-yandex-maps';
import utilities from '../../utilities';

const MapComponent = (props) => {
  const { className, placemarks, zoom } = props;
  const [ymap, setYmaps] = useState(null);
  const mapClassName = cn('map', className);

  const generateCenter = () => {
    if (
      placemarks.length === 1 &&
      placemarks[0]?.branches[0]?.address?.coordinates
    ) {
      return [
        placemarks[0].branches[0].address.coordinates.latitude,
        placemarks[0].branches[0].address.coordinates.longitude,
      ];
    }
    return [45.03547, 38.975313];
  };

  const [state, setState] = useState({
    center: generateCenter(),
    zoom: zoom || 13,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      center: generateCenter(),
    }));
  }, [placemarks]);

  const generateOption = (name, endDeliveryTime) => {
    if (placemarks.length === 1) {
      return {
        iconLayout: 'default#imageWithContent',
        iconImageHref: '/images/restaurant.svg',
        iconImageSize: [50, 50],
        iconImageOffset: [-25, -50],
        iconContentOffset: [15, 15],
        iconContentLayout: ymap.templateLayoutFactory.createClass(
          `<div style="display: flex; position: relative; flex-wrap: wrap; background-color: #fff; font-weight: bold; border-radius: 5px; padding: 5px; transform: translateY(-50%); margin: 50% 0 0 35px; width: fit-content; white-space: nowrap"><div style="display: flex; font-size: 13px" witdh: 100%>${name}</div><div style="font-size: 10px; color: #949494">${utilities.translate(
            'до'
          )} ${endDeliveryTime}</div></div>`
        ),
      };
    }
    return {
      iconLayout: 'default#imageWithContent',
      iconImageHref: '/images/circle.svg',
      iconImageSize: [30, 30],
      iconImageOffset: [-15, -30],
      iconContentOffset: [15, 15],
      iconContentLayout: ymap.templateLayoutFactory.createClass(
        `<div style="display: flex; justify-content: center; font-size: 9px; color: #000000; font-weight: bold; margin: 20px 0 0 -75px; width: 150px; white-space: nowrap">${name}</div>`
      ),
    };
  };

  const customBalloonContentLayout = useMemo(() => {
    if (ymap) {
      return ymap.templateLayoutFactory.createClass(
        [
          '<ul class=list style="list-style: none; padding: 0; margin: 10px;">',
          // Outputting a list of all geo objects in the cycle.
          '{% for geoObject in properties.geoObjects %}',
          '<li><span data-placemarkid="{{ geoObject.properties.placemarkId }}" style="font-weight: bold;" class="list_item">{{ geoObject.properties.balloonContentHeader|raw }}</span></li>',
          '{% endfor %}',
          '</ul>',
        ].join('')
      );
    }
    return '';
  }, [ymap]);

  return (
    <Map
      className={mapClassName}
      options={{
        suppressMapOpenBlock: true,
        maxZoom: 17,
      }}
      hint="hello"
      state={state}
      modules={[
        'clusterer.addon.balloon',
        'templateLayoutFactory',
        'layout.ImageWithContent',
      ]}
      onLoad={(ymaps) => {
        setYmaps(ymaps);
      }}
    >
      <Clusterer
        defaultOptions={{
          clusterBalloonContentLayout: customBalloonContentLayout,
          preset: 'islands#invertedBlackClusterIcons',
          gridSize: 64,
        }}
      >
        {ymap &&
          placemarks.map(
            ({ branches, name, end_delivery_time: endDeliveryTime }) =>
              branches.map(({ id, address: location }) => (
                <div key={id}>
                  {location?.coordinates?.latitude &&
                    location?.coordinates?.longitude && (
                      <Placemark
                        key={id}
                        geometry={[
                          location.coordinates.latitude,
                          location.coordinates.longitude,
                        ]}
                        properties={{
                          balloonContentHeader: name,
                        }}
                        options={generateOption(name, endDeliveryTime)}
                      />
                    )}
                </div>
              ))
          )}
      </Clusterer>
    </Map>
  );
};

export default MapComponent;
