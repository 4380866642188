/* eslint-disable no-unused-vars */
import { useCallback, useContext, useEffect, useState } from 'react';
import utilities from '../../../utilities';
import Counter from './Counter';
import Property from './Property';
import { ReactComponent as DeleteIcon } from '../../../icons/delete.svg';
import { GlobalBasketContext } from '../../../context/GlobalBasketContext';
import routes from '../../../routes';
import BlockSkeleton from '../../Skeleton/Block';
import callGtmEvent from '../../../utilities/gtm';

const OrderItem = ({ order }) => {
  const { remove, establishment_name: establishmentName } = useContext(
    GlobalBasketContext
  );
  const [dishData, setDishData] = useState();

  const onRemoveClick = useCallback(
    async (data) => {
      const category = await utilities.api.get(
        routes.apiSwagger.dishCategoryInfo(data.category_id)
      );
      callGtmEvent('remove_from_cart', [
        {
          item_id: data.item_id,
          item_name: data.title,
          item_category: category.name,
          price: data.price,
          currency: 'RUB',
          quantity: data.count,
        },
      ]);
      remove({ ...order, dish_id: data.item_id });
    },
    [establishmentName]
  );

  useEffect(() => {
    const fetchDishData = async () => {
      const response = await utilities.api.get(
        routes.apiSwagger.dishes(order.item_id)
      );
      setDishData({
        ...order,
        weight: response.weight,
        options: response.options,
        property: response.property,
      });
    };

    fetchDishData();
  }, []);

  if (!dishData) {
    return <BlockSkeleton width="100%" height={275} />;
  }

  return (
    <li
      className="order"
      style={{ '--rows': dishData.options?.length ? 5 : 4 }}
    >
      <h2 className="order-title">{`${dishData.title} ${
        dishData.dish_property_value_name || ''
      }`}</h2>
      <div className="order-weight">
        <span className="order-termin">
          {`${utilities.translate('Вес')}: `}
        </span>
        <span className="order-description">{dishData.weight}</span>
      </div>
      <Counter order={order} />
      {/* {dishData.property && <Property order={dishData} />} */}
      <div className="order-price">
        <span className="order-termin">{`${utilities.translate(
          'Цена'
        )}: `}</span>
        <span className="order-description order-price__description">
          {utilities.currency.toString(dishData.price)}
        </span>
      </div>
      {dishData.options?.length ? (
        <ul className="search-item-options">
          {dishData.options.map(({ name, value }) => (
            <li className="search-item-option" key={name}>
              <span className="search-item-option-name">{name}</span>
              <span className="search-item-option-value">{value}</span>
            </li>
          ))}
        </ul>
      ) : null}
      <button
        onClick={() => onRemoveClick(order)}
        className="order__delete"
        type="button"
      >
        Удалить
        <DeleteIcon className="order__delete-icon" />
      </button>
      <div className="order-image__holder">
        <img
          className="order-image"
          src={dishData.image}
          alt={dishData.title}
        />
      </div>
    </li>
  );
};

export default function Orders() {
  const { items } = useContext(GlobalBasketContext);

  return (
    <ul className="orders-list">
      {Object.values(items).map((order) => (
        <OrderItem key={order.id} order={order} />
      ))}
    </ul>
  );
}
