import React, { useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalBasketContext } from '../../../../context/GlobalBasketContext';
import ModalComponent from '../../../Modal';
import DishBasketModal from '../../../Modals/DishBasket';
import routes from '../../../../routes';
import utilities from '../../../../utilities';
import Button from '../../../Button/Button';

export default function GenerateActions({ isPhone, items }) {
  const [isDisabled, setDisabled] = useState(false);
  const { items: basketDishes, update } = useContext(GlobalBasketContext);
  const history = useHistory();
  const [isModalOpen, setModalOpen] = useState(false);

  const pushDishesToBasket = async (dishes) => {
    setDisabled(true);
    await dishes.reduce(async (promise, item) => {
      await promise;
      await update({ ...item, dish_id: item.id });
    }, Promise.resolve());
    setDisabled(false);
  };

  const handleClick = useCallback(async () => {
    setDisabled(true);

    if (basketDishes?.length > 0) {
      setModalOpen(true);
      setDisabled(false);
    } else {
      await pushDishesToBasket(items);
      setDisabled(false);
      history.push(routes.page.basket());
    }
  }, [items, update]);

  return (
    <>
      <Button
        onClick={handleClick}
        className="history-action"
        size={!isPhone ? 'medium' : 'full'}
        disabled={isDisabled}
      >
        {utilities.translate('Повторить')}
      </Button>
      <ModalComponent state={[isModalOpen, setModalOpen]}>
        <DishBasketModal
          dish={basketDishes}
          dishPropertyCallback={async () => {
            await pushDishesToBasket(items);
            setDisabled(false);
            history.push(routes.page.basket());
          }}
          shouldUpdateDish={false}
        />
      </ModalComponent>
    </>
  );
}
