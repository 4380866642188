/* eslint-disable no-unused-vars */
import { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GlobalBasketContext } from '../../../../context/GlobalBasketContext';
import utilities from '../../../../utilities';
import Button from '../../../Button/Button';
import Modal from '../../../Modal';
import DishBasketModal from '../../../Modals/DishBasket';
import callGtmEvent from '../../../../utilities/gtm';
import DishPropertySelect from '../../../Modals/DishPropertySelect';
import routes from '../../../../routes';

export default function DishBasket({ dish }) {
  const [isDifferentModalOpen, setDifferentModalOpen] = useState(false);
  const [isPropertyOpen, setPropertyOpen] = useState(false);
  const { update, isSameEstablishment } = useContext(GlobalBasketContext);

  const handleClick = useCallback(async () => {
    if (!isSameEstablishment(dish.establishment_id)) {
      setDifferentModalOpen(true);
    } else if (dish.property) {
      setPropertyOpen(true);
    } else {
      await update({ ...dish, dish_id: dish.id });
      const category = await utilities.api.get(
        routes.apiSwagger.dishCategoryInfo(dish.category_id)
      );
      callGtmEvent('add_to_cart', [
        {
          item_id: dish.id,
          item_name: dish.name,
          item_category: category.name,
          price: dish.price,
          currency: 'RUB',
          quantity: 1,
        },
      ]);
    }
  }, [dish, update]);

  return (
    <>
      <Modal state={[isDifferentModalOpen, setDifferentModalOpen]}>
        <DishBasketModal
          dishPropertyCallback={() => setPropertyOpen(true)}
          dish={dish}
        />
      </Modal>
      <Modal state={[isPropertyOpen, setPropertyOpen]}>
        <DishPropertySelect dish={dish} />
      </Modal>
      <Button onClick={handleClick}>{utilities.translate('В корзину')}</Button>
    </>
  );
}
