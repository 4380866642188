import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EstablishmentsContext } from '../../context/Establishments';
import routes from '../../routes';
import utilities from '../../utilities';
import EventTeaser from '../EventTeaser/EventTeaser';
import Section from '../Section/Section';
import BlockSkeleton from '../Skeleton/Block';
import Slider from '../Slider';
import Title from '../Title/Title';

const EventTeasers = ({ data }) => {
  const { id } = useParams();
  const establishments = useContext(EstablishmentsContext);
  const [teasers, setTeasers] = useState(null);
  const [intermediateData, setIntermediateData] = useState(null);

  useEffect(() => {
    const fetchEstablishmentTeasers = async () => {
      const response = await utilities.api.get(routes.api.eventsAndNews(), {
        establishment_id: id,
      });
      setIntermediateData(response.poster_items);
    };
    if (id) {
      fetchEstablishmentTeasers();
    }
  }, []);

  useEffect(() => {
    if (establishments && intermediateData) {
      const currentEstablishment = establishments.find(
        ({ id: estId }) => estId.toString() === id.toString()
      );
      setTeasers(
        intermediateData.map((item) => ({
          ...item,
          logo: currentEstablishment.logo,
        }))
      );
    }
  }, [establishments, intermediateData]);

  if (!data?.length && !teasers?.length) {
    return null;
  }

  if (!data && !teasers) {
    return (
      <BlockSkeleton
        width="100%"
        height={490}
        className="section"
        label="Загрузка новостей и событий этого ресторана"
      />
    );
  }

  return (
    <Section column largeMargin>
      <Title className="swiper-container-title" size="huge" align="right">
        <Title.Link href={routes.page.events()}>
          {utilities.translate('Афиша')}
        </Title.Link>
      </Title>
      <Title
        className="swiper-container-title swiper-container-title__doppelganger"
        size="huge"
        align="right"
      >
        <Title.Link href={routes.page.events()}>
          {utilities.translate('Афиша')}
        </Title.Link>
      </Title>
      <Slider
        spaceBetween={30}
        scrollbarShift
        scrollbarShrinked
        shift
        autoplay={{
          delay: 5000,
        }}
        slideProps={{ size: 'large' }}
        elements={data || teasers}
        slide={EventTeaser}
      />
    </Section>
  );
};

export default EventTeasers;
