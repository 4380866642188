/* eslint-disable no-unused-vars */
import React, { useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { EstablishmentsContext } from '../../../context/Establishments';
import { SearchFilterContext } from '../../../context/Search';
import utilities from '../../../utilities';
import EstablishmentsFilter from './Establishments';
import InputFilter from './InputFilter';

export default function SearchFilter() {
  const establishments = useContext(EstablishmentsContext);
  const [filter] = useContext(SearchFilterContext);
  const history = useHistory();
  const initialSearch = useRef(
    utilities.params.toObject(history.location.search)
  );

  const onEstablishmentChange = (id) => {
    history.replace({
      ...history.location,
      search: utilities.params.toString({
        ...filter,
        establishment_id: id,
      }),
    });
  };

  const onInputChange = (value) => {
    history.replace({
      ...history.location,
      search: utilities.params.toString({
        ...filter,
        search: value,
      }),
    });
  };

  if (!establishments) {
    return null;
  }

  return (
    <>
      <EstablishmentsFilter
        initialSearch={initialSearch}
        onChange={onEstablishmentChange}
      />
      <InputFilter initialSearch={initialSearch} onChange={onInputChange} />
    </>
  );
}
