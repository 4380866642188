/* eslint-disable no-unused-vars */
import { createContext, useContext, useEffect } from 'react';
import { EstablishmentsContext } from '../Establishments';

export const OrderContext = createContext();

export const OrderContextProvider = (props) => {
  const { children } = props;
  const establishments = useContext(EstablishmentsContext);

  // const currentEstablishment = useRef

  // const data = useMemo(() => ({

  // }), []);

  return <OrderContext.Provider value={123}>{children}</OrderContext.Provider>;
};
