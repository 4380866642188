import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import Checkbox from '../../../UI/Form/Checkbox';
import FormField from '../../../UI/Form/Field';
import utilities from '../../../../utilities';

const MAX_CHANGE = 999999;

export default function Change() {
  const { control } = useFormContext();
  const [needChange, setNeedChange] = useState(false);
  const changeInputRef = useRef();
  const firstMount = useRef(true);

  const setInputRef = (element) => {
    changeInputRef.current = element;
  };

  useEffect(() => {
    if (!needChange) {
      changeInputRef.current.value = '';
    }
  }, [needChange]);

  useEffect(() => {
    firstMount.current = false;
    return () => {
      firstMount.current = true;
      changeInputRef.current = null;
    };
  }, []);

  return (
    <div className="change-holder">
      <span className="change-holder__text">
        {utilities.translate('С какой суммы подготовить сдачу?')}
      </span>
      <FormField
        nomargin
        component={
          <Controller
            control={control}
            name="need_change"
            render={({ field }) => (
              <NumberFormat
                getInputRef={setInputRef}
                isAllowed={({ value }) => value < MAX_CHANGE}
                className="form__input"
                {...field}
              />
            )}
          />
        }
      />
      <Controller
        name="need_change"
        control={control}
        render={({ field }) => (
          <Checkbox
            square
            type="checkbox"
            name={field.name}
            defaultChecked={needChange}
            onChange={(checked) => setNeedChange(!checked)}
            label={utilities.translate('Без сдачи')}
            value="0"
            onValueChange={field.onChange}
            id="no_change"
          />
        )}
      />
    </div>
  );
}
