import useWindowSize from '../../hooks/useResize';
import MultipleSkeleton from '../Skeleton/Multiple';

const RestaurantPreloader = () => {
  const { isDesktop, isTablet } = useWindowSize();

  if (isDesktop) {
    return (
      <MultipleSkeleton
        width={960}
        className="section"
        gap={15}
        count={4}
        height={20}
        size="downgrade"
        downgradeStep={40}
      />
    );
  }
  if (isTablet) {
    return (
      <MultipleSkeleton
        width={630}
        gap={15}
        count={6}
        height={20}
        size="downgrade"
        downgradeStep={40}
      />
    );
  }
  return (
    <MultipleSkeleton
      width={isDesktop ? 960 : 230}
      className={isDesktop ? 'section' : ''}
      gap={15}
      count={4}
      height={20}
      size="downgrade"
      downgradeStep={40}
    />
  );
};

export default RestaurantPreloader;
