/* eslint-disable no-unused-vars */
import React, { useContext, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  ActivitiesContext,
  ActivitiesFilterContext,
} from '../../../context/Activities';
import utilities from '../../../utilities';
import { EstablishmentsContext } from '../../../context/Establishments';
import routes from '../../../routes';
import Select from '../../UI/Form/Select';

export default function Filter({ entity }) {
  const [, setParams] = useContext(ActivitiesFilterContext);
  const establishments = useContext(EstablishmentsContext);
  const { years } = useContext(ActivitiesContext);
  const eventsMatch = useRouteMatch(routes.page.events());
  const MONTHS = [
    { label: utilities.translate('Январь'), value: 1 },
    { label: utilities.translate('Февраль'), value: 2 },
    { label: utilities.translate('Март'), value: 3 },
    { label: utilities.translate('Апрель'), value: 4 },
    { label: utilities.translate('Май'), value: 5 },
    { label: utilities.translate('Июнь'), value: 6 },
    { label: utilities.translate('Июль'), value: 7 },
    { label: utilities.translate('Август'), value: 8 },
    { label: utilities.translate('Сентябрь'), value: 9 },
    { label: utilities.translate('Октябрь'), value: 10 },
    { label: utilities.translate('Ноябрь'), value: 11 },
    { label: utilities.translate('Декабрь'), value: 12 },
  ];

  const generateMonthsOptions = () => {
    if (years) {
      return [
        { label: `${utilities.translate('Все месяцы')}`, value: 0 },
        ...MONTHS,
      ].map(({ label, value }) => ({
        label,
        value,
        name: 'month',
      }));
    }
    return [];
  };

  const generateYearsOptions = () => {
    if (years) {
      return [
        { label: `${utilities.translate('Все года')}`, value: 0 },
        ...years,
      ].map((year) => ({
        label: year.label || year,
        value: year.value ?? year,
        name: 'year',
      }));
    }
    return [];
  };

  const monthsOptions = useMemo(generateMonthsOptions, [years]);
  const yearOptions = useMemo(generateYearsOptions, [years]);

  const handleChange = ({ name, value }) => {
    setParams((prev) => {
      if (eventsMatch) {
        return { ...prev, [name]: value };
      }
      return {
        [name]: value,
      };
    });
  };

  return (
    <form className="filter">
      {establishments && (
        <>
          <Select
            className="filter__select"
            name="restaurant"
            onChange={handleChange}
            options={[
              { name: `${utilities.translate('Все рестораны')}`, id: 0 },
              ...establishments,
            ].map(({ id, name }) => ({
              label: name,
              value: id,
              name: 'establishment_id',
            }))}
          />
          {entity === 'events' && yearOptions.length > 0 && (
            <>
              <Select
                className="filter__select"
                name="month"
                onChange={handleChange}
                options={monthsOptions}
              />
              <Select
                onChange={handleChange}
                className="filter__select"
                name="year"
                controlled
                options={yearOptions}
              />
            </>
          )}
        </>
      )}
    </form>
  );
}
