/* eslint-disable no-unused-vars */
import { AuthContext, AuthContextProvider } from '../../../context/Auth';
import PhoneForm from './PhoneForm';
import CodeForm from './CodeForm';

const AUTH_STEPS = {
  0: () => <PhoneForm />,
  1: (setModalOpen, redirect) => (
    <CodeForm redirect={redirect} setModalOpen={setModalOpen} />
  ),
};

export default function AuthForm({ setModalOpen, redirect = true }) {
  return (
    <AuthContextProvider>
      <AuthContext.Consumer>
        {({ step: [step] }) => AUTH_STEPS[step](setModalOpen, redirect)}
      </AuthContext.Consumer>
    </AuthContextProvider>
  );
}
