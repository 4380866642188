/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import utilities from '../../../utilities';

const FormField = ({
  label,
  error,
  name,
  rules = {},
  type = 'text',
  component: Component,
  disabled,
  inputClass,
  fullWidth,
  direction = 'row',
  holderClassname,
  nomargin,
  required = false,
  rawInput = false,
  onChange,
  componentLabel,
  inputRef,
  onFocus,
  shouldRegister = true,
  columnComponent = false,
  style,
  unregisterOnUnmount = true,
  customId,
}) => {
  const [field, setField] = useState(null);
  const { register, unregister } = useFormContext();

  useEffect(() => {
    if (shouldRegister && name) {
      setField(
        register(name, {
          required: required
            ? utilities.translate('Поле обязательно для заполнения')
            : false,
          shouldUnregister: true,
          ...rules,
        })
      );
    }
  }, [register]);
  const inputClasses = cn('form__input', inputClass);

  const { current: id } = useRef(
    `form-field-${name || Math.random().toString(36).substr(2, 9)}`
  );

  const rowClassName = cn('form__row', holderClassname, {
    'form__row--error': error,
    'form__row--disabled': disabled,
    [`form__row--${direction}`]: direction,
    'form__row--fullWidth': fullWidth,
    [`form__row--marginless`]: nomargin,
  });

  const labelClasses = cn('form__label');

  const handleChange = (event) => {
    if (field) field.onChange(event);
    if (onChange) onChange(event.target.value);
  };

  const handleFocus = () => {
    if (onFocus) onFocus();
  };

  useEffect(() => {
    return () => {
      if (unregisterOnUnmount) {
        unregister(name);
      }
    };
  }, []);

  if (rawInput) {
    if (label) {
      return (
        <div className="form__item--raw">
          <label htmlFor={id}>{label}</label>
          <input
            disabled={Boolean(disabled)}
            className={inputClasses}
            id={id}
            name={field?.name || name}
            type={type}
            onChange={handleChange}
            onBlur={field?.onBlur}
            ref={(element) => {
              if (shouldRegister) field?.ref(element);
              if (inputRef) inputRef(element);
            }}
          />
        </div>
      );
    }
    return (
      <input
        disabled={Boolean(disabled)}
        className={inputClasses}
        id={id}
        name={field?.name || name}
        type={type}
        onChange={handleChange}
        onBlur={field?.onBlur}
        ref={(element) => {
          if (shouldRegister) field?.ref(element);
          if (inputRef) inputRef(element);
        }}
      />
    );
  }

  const renderComponent = () => {
    if (columnComponent) {
      return <div className="form__row--content-column">{Component}</div>;
    }
    if (componentLabel) {
      return (
        <div className="form__item--raw">
          <label htmlFor={customId || id}>{componentLabel}</label>
          {Component}
        </div>
      );
    }
    return Component;
  };

  return (
    <div style={style} className={rowClassName}>
      {label && (
        <label className={labelClasses} htmlFor={id}>
          {`${label}${required ? '*' : ''}`}
        </label>
      )}
      {error && <span className="form__error-text">{error.message}</span>}
      {Component ? (
        renderComponent()
      ) : (
        <input
          disabled={Boolean(disabled)}
          className={inputClasses}
          id={customId || id}
          onFocus={handleFocus}
          name={field?.name || name}
          type={type}
          onChange={handleChange}
          onBlur={field?.onBlur}
          ref={(element) => {
            if (shouldRegister) field?.ref(element);
            if (inputRef) inputRef(element);
          }}
        />
      )}
    </div>
  );
};

export default FormField;
