/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { DevTool } from '@hookform/devtools';
import { useContext, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AccountContext } from '../../../../context/Account';
import routes from '../../../../routes';
import utilities from '../../../../utilities';
import Button from '../../../Button/Button';
import FormField from '../../../UI/Form/Field';
import Phone from '../../../UI/Form/Phone';

export default function Form({ hideForm }) {
  const { account, setAccount } = useContext(AccountContext);
  const methods = useForm({
    defaultValues: {
      ...account,
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
  } = methods;

  const onSubmit = async (values) => {
    const response = await utilities.api.patch(
      routes.apiSwagger.updateAccount(),
      values
    );
    setAccount(response);
    hideForm();
  };

  return (
    <FormProvider {...methods}>
      <form className="account--form" onSubmit={handleSubmit(onSubmit)}>
        <FormField name="first_name" label={`${utilities.translate('Имя')}:`} />
        <FormField
          name="last_name"
          label={`${utilities.translate('Фамилия')}:`}
        />
        <FormField
          label={`${utilities.translate('Телефон')}:`}
          component={
            <Controller
              name="phone"
              control={control}
              render={({ field, fieldState }) => (
                <Phone {...field} fieldState={fieldState} />
              )}
            />
          }
        />
        <FormField
          name="email"
          label="Email:"
          rules={{
            validate: (value) =>
              !value || utilities.validation.validateEmail(value),
          }}
          error={errors.email}
        />
        <Button
          className="account-info-button"
          onClick={() => {
            if (!(isDirty && Object.keys(dirtyFields).length)) {
              hideForm();
            }
          }}
          type={
            isDirty && Object.keys(dirtyFields).length ? 'submit' : 'button'
          }
        >
          {utilities.translate(
            isDirty && Object.keys(dirtyFields).length ? 'Сохранить' : 'Отмена'
          )}
        </Button>
      </form>
      {/* <DevTool control={methods.control} /> */}
    </FormProvider>
  );
}
