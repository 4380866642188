/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Description from '../components/Description/Description';
import Form from '../components/Vacancy/Form';
import Stories from '../components/Stories/Stories';
import Title from '../components/Title/Title';
import Hero from '../components/Hero/Hero';
import Section from '../components/Section/Section';
import Slider from '../components/Slider';
import PhotoSlide from '../components/Catering/PhotoSlide';
import TeamSchool from '../components/TeamSchool/TeamSchool';
import BlockSkeleton from '../components/Skeleton/Block';
import routes from '../routes';
import utilities from '../utilities';
import useWindowSize from '../hooks/useResize';
import Meta from '../components/Meta';

const Team = () => {
  const [data, setData] = useState(null);
  const { isDesktop } = useWindowSize();
  const language = utilities.getLang();

  useEffect(() => {
    const fetchTeam = async () => {
      const response = await utilities.api.get(routes.api.team(), {
        lang: language,
      });
      setData(response);
    };
    fetchTeam();
  }, []);

  return (
    <>
      <Meta url="/team" />
      <Breadcrumbs />
      <Section className="team">
        <Hero image={data?.page_cover_team} />
        <Title
          tag="h1"
          align="middle"
          className="team__title"
          size="large"
          color="black"
        >
          {data?.title_team}
        </Title>
        <Description
          className="team__description"
          dangerHTML={data?.about_team}
        />
        {data?.slider ? (
          <Slider
            scrollbarReduced={isDesktop}
            reduced={isDesktop}
            elements={data.slider}
            slide={PhotoSlide}
            spaceBetween={15}
            className="team__slider"
          />
        ) : (
          <BlockSkeleton
            width="100%"
            className="section"
            height={500}
            style={{ marginTop: '35px', marginBottom: '20px' }}
          />
        )}
        <Description className="team__text" dangerHTML={data?.slogan_team} />
        {data ? (
          <TeamSchool data={data} />
        ) : (
          <BlockSkeleton
            width="100%"
            height={500}
            className="section"
            label="Загрузка информации"
          />
        )}
        {data?.successStories ? (
          <Stories stories={data.successStories} />
        ) : (
          <BlockSkeleton
            width="100%"
            height={500}
            className="section"
            label="Загрузка историй"
          />
        )}
        <Form team />
      </Section>
    </>
  );
};

export default Team;
