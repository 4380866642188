import cn from 'classnames';

export default function DeliveryOption({ style, children, label, modifier }) {
  const optionClasses = cn('delivery-option', {
    [`delivery-option--${modifier}`]: modifier,
  });
  return (
    <div style={style} className={optionClasses}>
      {label && <div className="delivery-option-label">{label}</div>}
      <div className="delivery-option-wrapper">{children}</div>
    </div>
  );
}
