/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { createRef, useState, useRef } from 'react';
import cn from 'classnames';
import utilities from '../../../../utilities';
import Actions from './Actions';

export default function HistoryItem({ order, isPhone }) {
  const { id, created, amount, actual_amount, status_display, items } = order;
  const [expanded, setExpanded] = useState(false);
  const itemsRef = createRef();
  const itemsHolderRef = createRef();
  const animating = useRef(false);

  const rowClasses = cn('history__row', {
    'history__row--expanded': expanded,
  });

  const holderClassNames = cn('history__items-holder', {
    'history__items-holder--active': expanded,
  });

  const handleClick = () => {
    if (!animating.current) {
      if (expanded) {
        const height = itemsRef.current.clientHeight;
        animating.current = true;
        utilities.animation
          .go(500, (fraction) => {
            itemsHolderRef.current.style.height = `${
              height - height * fraction
            }px`;
          })
          .then(() => {
            animating.current = false;
            setExpanded(false);
          });
      } else {
        animating.current = true;
        utilities.animation
          .go(500, (fraction) => {
            itemsHolderRef.current.style.height = `${
              itemsRef.current.clientHeight * fraction
            }px`;
          })
          .then(() => {
            animating.current = false;
            itemsHolderRef.current.style = '';
            setExpanded(true);
          });
      }
    }
  };

  return (
    <article className={rowClasses}>
      <div className="history__id">
        {isPhone && (
          <span className="history__id--title">{`${utilities.translate(
            'Заказ'
          )} №`}</span>
        )}
        {!isPhone ? id : <span className="history__id--value">{id}</span>}
      </div>
      <div className="history__date">
        {isPhone && (
          <span className="history__date--title">
            {utilities.translate('Дата')}
          </span>
        )}
        {!isPhone ? (
          created.split(' ')[0]
        ) : (
          <span className="history__date--value">{created.split(' ')[0]}</span>
        )}
      </div>
      <div className="history__cost">
        {isPhone && (
          <span className="history__cost--title">
            {utilities.translate('Стоимость')}
          </span>
        )}
        {!isPhone ? (
          utilities.currency.toString(amount || actual_amount)
        ) : (
          <span className="history__cost--value">
            {utilities.currency.toString(amount || actual_amount)}
          </span>
        )}
      </div>
      <div className="history__status">
        {isPhone && (
          <span className="history__status--title">
            {utilities.translate('Статус')}
          </span>
        )}
        {!isPhone ? (
          status_display
        ) : (
          <span className="history__status--value">{status_display}</span>
        )}
      </div>
      <div className={holderClassNames} ref={itemsHolderRef}>
        <div ref={itemsRef} className="history__items-wrapper">
          <ul className="history__items">
            {items.map(
              ({
                id: itemId,
                image,
                title,
                count,
                price,
                dish_property_value_name,
              }) => (
                <li key={itemId} className="history__item">
                  <h2 className="history__title">{`${title} ${
                    dish_property_value_name || ''
                  }`}</h2>
                  {count && (
                    <div className="history__info-list">
                      <span className="history__info-termin">
                        {`${utilities.translate('Количество')}: `}
                      </span>
                      <span className="history__info-desc">{count}</span>
                    </div>
                  )}
                  {price && (
                    <div className="history__info-list">
                      <span className="history__info-termin">
                        {`${utilities.translate('Цена')}: `}
                      </span>
                      <span className="history__info-desc">
                        {utilities.currency.toString(price)}
                      </span>
                    </div>
                  )}
                  <div className="history__image-holder">
                    <img src={image} className="history__image" alt={title} />
                  </div>
                </li>
              )
            )}
          </ul>
          <Actions {...order} isPhone={isPhone} />
          <div className="history__summary">
            <div className="history__info-list">
              <span className="history__info-termin">
                {utilities.translate('Итого')}:
              </span>
              <span className="history__info-desc">
                {utilities.currency.toString(amount || actual_amount)}
              </span>
            </div>
            <div className="history__info-list">
              <span className="history__info-termin">
                {utilities.translate('Дата и время')}:
              </span>
              <span className="history__info-desc">
                {utilities.date.toString(created)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <button className="history__expand" type="button" onClick={handleClick}>
        {expanded ? 'Свернуть' : 'Развернуть'}
      </button>
    </article>
  );
}
