/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { useState, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { serialize } from 'object-to-formdata';
import routes from '../../routes';
import Button from '../Button/Button';
import Description from '../Description/Description';
import Title from '../Title/Title';
import FormField from '../UI/Form/Field';
import File from '../UI/Form/File';
import Select from '../UI/Form/Select';
import callGtmEvent from '../../utilities/gtm';
import Section from '../Section/Section';
import Phone from '../UI/Form/Phone';
import Checkbox from '../UI/Form/Checkbox';
import Modal from '../Modal';
import utilities from '../../utilities';

const VacancyForm = ({ vacancy, team = false }) => {
  const [state, setState] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: '',
      phone_number: '',
      email: '',
      message: '',
      link: '',
      vacancy_id: '',
      agreement: false,
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting, errors, isDirty, isValid },
  } = methods;

  useEffect(() => {
    const fetchState = async () => {
      const response = await utilities.api.get(routes.api.vacancies());
      if (team) response.vacancies.unshift({ id: 'unset', title: '' });
      setState(response);
    };
    fetchState();
  }, []);

  const onSubmit = async (values) => {
    if (values.vacancy_id === 'unset') {
      // eslint-disable-next-line no-param-reassign
      delete values.vacancy_id;
    }
    const formData = serialize(values);
    const response = await utilities.api.post(
      routes.api.vacancyForm(),
      formData
    );
    if (!response.error) {
      callGtmEvent(
        'generate_lead',
        [
          {
            item_id: values?.vacancy_id,
            item_name: 'team form',
          },
        ],
        {
          value: values?.vacancy_id,
        }
      );
      setModalOpen(true);
      reset();
    } else if (response.validation_error) {
      utilities.errors(setError, response.validation_error);
    }
  };

  return (
    <Section>
      <Modal state={[modalOpen, setModalOpen]}>
        <Title>{utilities.translate('Спасибо за ваше мнение!')}</Title>
        <Description>
          {utilities.translate(
            'Будьте готовы, Вам могут позвонить для уточнения деталей'
          )}
        </Description>
      </Modal>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="form vacancies-form">
          <Title align="center">
            {utilities.translate('Хочу у вас работать')}
          </Title>
          <Description className="collaboration__description" align="center">
            {utilities.translate('Мы рассмотрим ваше обращение и дадим ответ')}
          </Description>
          {state && (
            <FormField
              label={`${utilities.translate('Тема')}:`}
              required={!team}
              error={errors.vacancy_id}
              name="vacancy_id"
              component={
                <Controller
                  name="vacancy_id"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      triggerChangeOnFirstRender
                      controllingValue={vacancy}
                      border={false}
                      name={field.name}
                      onChange={({ value }) => {
                        field.onChange(value);
                      }}
                      options={state.vacancies.map(({ id, title }) => ({
                        value: id,
                        label: title,
                      }))}
                    />
                  )}
                />
              }
            />
          )}
          <FormField
            label={`${utilities.translate('Имя')}:`}
            type="text"
            name="name"
            required
            error={errors.name}
          />
          <FormField
            label={`${utilities.translate('Телефон')}:`}
            error={errors.phone_number}
            component={
              <Controller
                rules={{
                  minLength: {
                    value: 10,
                    message: utilities.translate('Ошибка! Заполните поле'),
                  },
                }}
                name="phone_number"
                control={control}
                render={({ field, fieldState }) => (
                  <Phone {...field} fieldState={fieldState} />
                )}
              />
            }
          />
          <FormField
            label={`${utilities.translate('Email')}:`}
            type="email"
            required
            name="email"
            rules={{
              required: utilities.translate('Поле обязательно для заполнения'),
              validate: (value) =>
                utilities.validation.validateEmail(value) ||
                'Введен невалидный email',
            }}
            error={errors.email}
          />
          <FormField
            label={`${utilities.translate('Сообщение')}:`}
            type="textarea"
            name="message"
            error={errors.message}
          />
          <FormField
            label={`${utilities.translate('Резюме')}:`}
            name="link"
            error={errors.link}
          />
          <File />
          <FormField
            name="agreement"
            required
            component={
              <Controller
                name="agreement"
                control={control}
                render={({ field, fieldState }) => (
                  <Checkbox
                    square
                    error={fieldState.error}
                    holderClassName="form__agreements"
                    name={field.name}
                    onChange={(checked) => field.onChange(checked)}
                    id="agreement"
                    label={utilities.translate(
                      'Согласен(а) на обработку персональных данных'
                    )}
                  />
                )}
              />
            }
          />
          <Button type="submit" disabled={isSubmitting}>
            {utilities.translate('Отправить')}
          </Button>
          {/* <DevTool control={control} /> */}
        </form>
      </FormProvider>
    </Section>
  );
};

export default VacancyForm;
