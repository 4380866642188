import { nanoid } from 'nanoid';
import { useEffect, useMemo, useState } from 'react';
import routes from '../../routes';
import utilities from '../../utilities';
import Advertisements from './Advertisements';
import Tips from './Tips';

export default function Collaboration() {
  const [state, setState] = useState(null);

  useEffect(() => {
    const fetchCollaboration = async () => {
      const response = await utilities.api.get(routes.api.collaboration());
      setState(response);
    };
    fetchCollaboration();
  }, []);

  const fixedAdvertiemenets = useMemo(() => {
    if (state) {
      return state.advertisements.map((item) => ({
        ...item,
        id: nanoid(),
      }));
    }
    return null;
  }, [state]);

  return (
    <>
      <Tips tips={state?.tips} />
      <Advertisements advertisements={fixedAdvertiemenets} />
    </>
  );
}
