/* eslint-disable camelcase */
import cn from 'classnames';
import Description from '../Description/Description';
import Title from '../Title/Title';
import More from '../More/More';

const Slide = (props) => {
  const { data, ...rest } = props;
  const { link, title, description, image, image_mobile } = data;

  const slideClassName = cn('slide', rest.className);

  const generateSlideTitle = () => {
    const titleFirstHalf = title.substr(0, title.indexOf(' '));
    const titleLastHalf = `${title
      .substr(title.indexOf(' ') + 1)
      .slice(0, 50)}...`;
    return (
      <div>
        {titleFirstHalf} <br /> {titleLastHalf}
      </div>
    );
  };

  return (
    <article className={slideClassName}>
      <div className="slide-content-wrapper">
        <div className="slide-content__text">
          {title && (
            <Title className="slide__title" color="white">
              {generateSlideTitle(title)}
            </Title>
          )}
          {description && (
            <Description className="slide__description" color="white">
              {`${description.slice(0, 50)}...`}
            </Description>
          )}
        </div>
        <More className="slide__more" href={link} />
        <img className="slide__image" src={image} alt="" />
        <img
          className="slide__image mobile"
          src={image_mobile || image}
          alt=""
        />
      </div>
    </article>
  );
};

export default Slide;
