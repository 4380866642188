import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import utilities from '../../../../../utilities';
import Select from '../../../../UI/Form/Select';

const GAP_MINUTES = 15;

export default function DeliveryTimeHours({ establishment }) {
  const today = useRef(new Date());
  const { watch, control, setValue } = useFormContext();
  const watcher = watch('helpers_timedays');
  const deliveryTypeWatcher = watch('delivery_type');
  const firstMount = useRef(true);
  const [controlledValue, setControlledValue] = useState(null);
  let formUpdated = false;

  const getDayDependOnWatcher = () => {
    return utilities.date.getDay(new Date(watcher).getDay(), 'en');
  };

  const parseWorkHours = () => {
    const { start, end } = establishment.work_hours[getDayDependOnWatcher()];
    const [startHours, startMinutes] = start.split(':');
    const [endHours, endMinutes] = end.split(':');
    return {
      start: {
        hours: parseInt(startHours, 10),
        minutes: parseInt(startMinutes, 10),
      },
      end: {
        hours: parseInt(endHours, 10),
        minutes: parseInt(endMinutes, 10),
      },
    };
  };

  const [workHours, setWorkHours] = useState({});

  const isTodayDate = () => {
    return (
      new Date(watcher).getDate() === today.current.getDate() &&
      new Date(watcher).getMonth() === today.current.getMonth()
    );
  };

  const generateEstimatedStartDelivering = (isToday) => {
    const getStartingHours = () => {
      if (isToday) {
        return today.current.getHours() < workHours.start.hours
          ? workHours.start.hours
          : today.current.getHours();
      }
      return workHours.start.hours;
    };

    const generateStartingMinutes = () => {
      if (isToday) {
        return today.current.getHours() < workHours.start.hours
          ? 0
          : today.current.getMinutes();
      }
      return workHours.start.minutes;
    };

    const shouldAddDelivery = () => {
      return deliveryTypeWatcher === '0';
    };

    const [
      estimateCookingHours,
      estimateCookingMinutes,
    ] = establishment.estimated_cooking_time.split(':');
    const [estimateDeliveryHours, estimateDeliveryMinutes] = [0, 0];
    const estimatedFirst = {
      hours:
        getStartingHours() +
        parseInt(estimateCookingHours, 10) +
        (shouldAddDelivery() ? parseInt(estimateDeliveryHours, 10) : 0),
      minutes:
        generateStartingMinutes() +
        parseInt(estimateCookingMinutes, 10) +
        (shouldAddDelivery() ? parseInt(estimateDeliveryMinutes, 10) : 0),
    };
    return estimatedFirst;
  };

  const generateOptionsValues = (date) => {
    const array = [];
    const loopValues = { hours: date.getHours(), minutes: date.getMinutes() };
    while (loopValues.hours <= workHours.end.hours) {
      if (loopValues.hours === workHours.end.hours && !workHours.end.minutes) {
        break;
      }
      while (
        workHours.end.minutes
          ? loopValues.minutes < workHours.end.minutes
          : loopValues.minutes < 60
      ) {
        const value = `${loopValues.hours}:${
          loopValues.minutes < 10
            ? `0${loopValues.minutes}`
            : loopValues.minutes
        }`;
        array.push({
          label: value,
          value,
        });
        loopValues.minutes += GAP_MINUTES;
      }
      loopValues.minutes = 0;
      loopValues.hours += 1;
    }
    return array;
  };

  const generateOptions = () => {
    const estimatedFirst = generateEstimatedStartDelivering(isTodayDate());
    const newDate = new Date(watcher);
    newDate.setHours(estimatedFirst.hours);
    newDate.setMinutes(
      Math.ceil(estimatedFirst.minutes / GAP_MINUTES) * GAP_MINUTES
    );
    const optionsValues = generateOptionsValues(newDate);
    return optionsValues;
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (watcher) {
      setWorkHours(parseWorkHours);
    }
  }, [watcher]);

  useEffect(() => {
    if (!firstMount.current) {
      setOptions(generateOptions);
    }
  }, [workHours]);

  useEffect(() => {
    if (!firstMount.current) {
      setOptions(generateOptions());
    }
  }, [deliveryTypeWatcher]);

  useEffect(() => {
    setControlledValue(options[0]);
    if (options.length && !formUpdated) {
      setValue('helpers_timehours', options[0].value);
      formUpdated = true;
    }
    return () => {
      formUpdated = false;
    };
  }, [options]);

  useEffect(() => {
    firstMount.current = false;

    return () => {
      firstMount.current = true;
    };
  }, []);

  return (
    <Controller
      control={control}
      name="helpers_timehours"
      render={({ field }) => (
        <Select
          className="delivery-time-select"
          size="small"
          changeSelected
          controlledValue={controlledValue}
          name="delivery_time"
          options={options}
          onChange={({ value }) => {
            field.onChange(value);
          }}
        />
      )}
    />
  );
}
