/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { ReactComponent as StarIcon } from '../../../icons/small-star.svg';
import Modal from '../../Modal';
import RateForm from './RateForm';
import ReasonForm from './ReasonForm';
import utilities from '../../../utilities';
import routes from '../../../routes';

export default function Rate({ id }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [differentModalOpen, setDifferentModalOpen] = useState(false);
  const [formData, setFormData] = useState(null);

  const onClose = async (values) => {
    const response = await utilities.api.post(
      routes.apiSwagger.rateOrder(id),
      values
    );
    setDifferentModalOpen(false);
  };

  return (
    <>
      <button
        onClick={() => setModalOpen(true)}
        className="history-action history-action-rate"
        type="button"
      >
        <span className="history-rate-icon-holder">
          <StarIcon className="history-rate-icon" />
        </span>
        {utilities.translate('Оценить')}
      </button>
      <Modal
        state={[differentModalOpen, setDifferentModalOpen]}
        onClose={onClose}
        data={formData}
      >
        <ReasonForm
          data={[formData]}
          orderId={id}
          state={[differentModalOpen, setDifferentModalOpen]}
        />
      </Modal>
      <Modal state={[modalOpen, setModalOpen]}>
        <RateForm
          setData={[setFormData]}
          state={[differentModalOpen, setDifferentModalOpen]}
          orderId={id}
        />
      </Modal>
    </>
  );
}
