/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import cn from 'classnames';
import { useContext, useEffect, useState, useRef } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { AccountContext } from '../../context/Account';
import ProfileDefaultLogo from '../../icons/profile-default.svg';
import routes from '../../routes';
import { ReactComponent as MadyarLogoIcon } from '../../icons/madyar-logo.svg';
import utilities from '../../utilities';
import Apps from '../Apps/Apps';
import Auth from '../Auth';
import SearchForm from '../SearchForm/SearchForm';

const Menu = (props) => {
  const location = useLocation();
  const { account } = useContext(AccountContext);
  const lang = utilities.getLang();
  const { menuOpen, hideMenu } = props;
  const [isAnimating, setAnimating] = useState(false);
  const menuRef = useRef();
  const closure = useRef({ frame: null });
  const firstMount = useRef(true);
  const menuCircleRef = useRef();

  const menuClasses = cn('menu', {
    'menu--active': menuOpen,
    'menu--animating': isAnimating,
  });

  useEffect(() => {
    hideMenu();
  }, [location]);

  useEffect(() => {
    if (!firstMount.current) {
      setAnimating(true);
      utilities.animation
        .go(
          800,
          (fraction) => {
            menuRef.current.style.left = `${
              (menuOpen ? 1 - fraction : fraction) * 100
            }%`;
          },
          { bezier: { x1: 0.02, y1: 0.54, x2: 0.14, y2: 0.98 } },
          closure.current
        )
        .then(() => {
          setAnimating(false);
        });
    }
  }, [menuOpen]);

  useEffect(() => {
    if (!firstMount.current) {
      if (menuOpen) {
        window.dispatchEvent(new CustomEvent('openMenu'));
      } else {
        window.dispatchEvent(new CustomEvent('closeMenu'));
      }
    }
  }, [menuOpen]);

  useEffect(() => {
    if (!firstMount.current) {
      document.dispatchEvent(
        new CustomEvent('menu:animating', { detail: isAnimating })
      );
    }
  }, [isAnimating]);

  const onCircleHide = () => {
    if (menuRef.current.classList.contains('menu--active')) {
      menuCircleRef.current.classList.add('menu__circle--hidden');
    }
  };

  const onCircleShow = () => {
    if (menuRef.current.classList.contains('menu--active')) {
      menuCircleRef.current.classList.remove('menu__circle--hidden');
    }
  };

  useEffect(() => {
    firstMount.current = false;
    document.addEventListener('circle:hide', onCircleHide);
    document.addEventListener('circle:show', onCircleShow);

    return () => {
      firstMount.current = true;
      document.removeEventListener('circle:hide', onCircleHide);
      document.removeEventListener('circle:show', onCircleShow);
    };
  }, []);

  return (
    <div ref={menuRef} className={menuClasses}>
      <MadyarLogoIcon ref={menuCircleRef} className="menu__circle" />
      <div className="menu__inner">
        <SearchForm />
        <div className="menu__content">
          <div className="menu__profile-teaser profile-teaser">
            <img
              className="profile-teaser__photo"
              src={account?.avatar || ProfileDefaultLogo}
              alt={account?.first_name}
            />
            <Auth />
          </div>
          <ul className="menu__list">
            <li className="menu__item">
              <NavLink exact className="menu__link" to={routes.page.home()}>
                {utilities.translate('Главная')}
              </NavLink>
            </li>
            <li className="menu__item">
              <NavLink
                exact
                className="menu__link"
                to={routes.page.restaurants()}
              >
                {utilities.translate('Рестораны')}
              </NavLink>
            </li>
            <li className="menu__item">
              <NavLink exact className="menu__link" to={routes.page.events()}>
                {utilities.translate('События / Новости')}
              </NavLink>
            </li>
            <li className="menu__item">
              <NavLink exact className="menu__link" to={routes.page.team()}>
                {utilities.translate('Команда')}
              </NavLink>
            </li>
            {/* <li className="menu__item">
            <NavLink className="menu__link" to={routes.page.home()}>
              {utilities.translate('Программа лояльности')}
            </NavLink>
          </li> */}
            {/* <li className="menu__item">
              <NavLink className="menu__link" to={routes.page.catering()}>
                {utilities.translate('Кейтеринг')}
              </NavLink>
            </li> */}
            <li className="menu__item">
              <NavLink
                exact
                className="menu__link"
                to={routes.page.collaboration()}
              >
                {utilities.translate('Сотрудничество')}
              </NavLink>
            </li>
            <li className="menu__item">
              <NavLink exact className="menu__link" to={routes.page.payment()}>
                {utilities.translate('Оплата и доставка')}
              </NavLink>
            </li>
            <li className="menu__item">
              <NavLink exact className="menu__link" to={routes.page.contacts()}>
                {utilities.translate('Контакты')}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="menu__footer">
          <Apps
            className="menu__apps"
            color="gray"
            hoverColor="black"
            phoneColor="black"
          />
          <a className="menu__contact" href="tel:78612799902">
            +7 (861) 203-11-10
          </a>
          <a className="menu__contact" href="mailto:office@madyar.pro">
            office@madyar.pro
          </a>
          <div className="menu__language-switch language-switch">
            <a
              className={cn('language-switch__link', {
                'language-switch__link--active': lang === 'ru',
              })}
              href="/ru"
            >
              RU
            </a>
            <a
              href="/en"
              className={cn('language-switch__link', {
                'language-switch__link--active': lang === 'en',
              })}
            >
              EN
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
