import useWindowSize from '../../../hooks/useResize';
import BlockSkeleton from '../../Skeleton/Block';

const RestaurantPreloader = () => {
  const { isDesktop } = useWindowSize();

  return (
    <>
      <BlockSkeleton
        width="100%"
        height={isDesktop ? 120 : 40}
        style={{ marginBottom: '10px' }}
        label="Загрузка адресов"
      />
      <BlockSkeleton
        width="80%"
        height={20}
        style={isDesktop ? { marginBottom: '110px' } : { marginBottom: '45px' }}
        label="Загрузка адресов"
      />
      <BlockSkeleton
        width="100%"
        height={isDesktop ? 120 : 40}
        style={{ marginBottom: '10px' }}
        label="Загрузка адресов"
      />
      <BlockSkeleton
        width="80%"
        height={20}
        style={isDesktop ? { marginBottom: '110px' } : { marginBottom: '45px' }}
        label="Загрузка адресов"
      />
      <BlockSkeleton
        width="100%"
        height={isDesktop ? 120 : 40}
        style={{ marginBottom: '10px' }}
        label="Загрузка адресов"
      />
      <BlockSkeleton
        width="80%"
        height={20}
        style={isDesktop ? { marginBottom: '110px' } : { marginBottom: '45px' }}
        label="Загрузка адресов"
      />
      {!isDesktop && (
        <>
          <BlockSkeleton
            width="100%"
            height={isDesktop ? 120 : 40}
            style={{ marginBottom: '10px' }}
            label="Загрузка адресов"
          />
          <BlockSkeleton
            width="80%"
            height={20}
            style={
              isDesktop ? { marginBottom: '110px' } : { marginBottom: '45px' }
            }
            label="Загрузка адресов"
          />
        </>
      )}
    </>
  );
};

export default RestaurantPreloader;
