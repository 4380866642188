/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../../routes';
import utilities from '../../utilities';

export const SearchContext = createContext();
export const SearchFilterContext = createContext();

export const SearchFilterContextProvider = ({ children }) => {
  const { search } = useLocation();
  const query = useRef(utilities.params.toObject(search));
  const [state, setState] = useState(query.current);
  const firstMount = useRef(true);

  useEffect(() => {
    if (!firstMount.current) {
      query.current = utilities.params.toObject(search);
      setState(query.current);
    }
  }, [search]);

  useEffect(() => {
    firstMount.current = false;

    return () => {
      firstMount.current = true;
    };
  }, []);

  return (
    <SearchFilterContext.Provider value={[state, setState]}>
      {children}
    </SearchFilterContext.Provider>
  );
};

export const SearchContextProvider = ({ children }) => {
  const [filter] = useContext(SearchFilterContext);
  const [state, setState] = useState(null);
  const requestId = useRef();

  const fetchMoreDishes = async () => {
    const response = await utilities.api.get(routes.apiSwagger.search(), {
      ...filter,
      offset: state.next,
      limit: 6,
    });
    const { results, ...rest } = response;
    setState((prev) => ({ ...rest, results: [...prev.results, ...results] }));
  };

  const showMore = () => {
    fetchMoreDishes();
  };

  useEffect(() => {
    const fetchDishes = async () => {
      requestId.current = utilities.api.generateRequestId();
      const response = await utilities.api.get(
        routes.apiSwagger.search(),
        { ...filter, limit: 6 },
        {},
        requestId.current
      );
      setState((prev) => ({ ...prev, ...response }));
    };
    fetchDishes();
  }, [filter]);

  useEffect(() => {
    return () => {
      utilities.api.cancelRequestById(requestId.current);
    };
  }, []);
  return (
    <SearchContext.Provider value={{ ...state, showMore }}>
      {children}
    </SearchContext.Provider>
  );
};
