/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import Description from '../components/Description/Description';
import Circle from '../components/RoutesCircle/Circle';
import Section from '../components/Section/Section';
import Title from '../components/Title/Title';
import routes from '../routes';
import utilities from '../utilities';

const Payment = () => {
  const [text, setText] = useState(null);

  useEffect(() => {
    const fetchSlider = async () => {
      const response = await utilities.api.get(routes.api.payment());
      if (response) {
        setText(response.text);
      }
    };
    fetchSlider();
  }, []);
  return (
    <>
      <Circle />
      <Section className="payment__section">
        {text && (
          <div className="content" dangerouslySetInnerHTML={{ __html: text }} />
        )}
        <Title tag="h1" className="payment__section-title" size="large">
          Реквизиты
        </Title>
        <div className="payment__info-block">
          <Description className="payment__info-title">ИП</Description>
          <Description>Ким Оксана Викторовна</Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">Адрес</Description>
          <Description>
            350049, г. Краснодар, ул. Красных Партизан, 515
          </Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">ОГРНИП</Description>
          <Description>321237500462580</Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">ОКВЭД</Description>
          <Description>
            56.10, 46.34.1, 56.10.1, 56.10.3, 56.29, 56.30
          </Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">
            Расчетный счет
          </Description>
          <Description>К40802810300020007721</Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">
            Полное наименование банка
          </Description>
          <Description>ФИЛИАЛ ЮЖНЫЙ ПАО БАНКА «ФК ОТКРЫТИЕ»</Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">БИК</Description>
          <Description>046015061</Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">
            Корреспондентский счет банка
          </Description>
          <Description>
            350049, г. Краснодар, ул. Красных Партизан, 515
          </Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">Офис тел.</Description>
          <Description>8 (861) 203-11-10</Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">
            Эл. адрес общий
          </Description>
          <Description>office@madyar.pro</Description>
        </div>
        <div className="payment__info-block separator">
          <Description className="payment__info-title">
            ООО «Васаби»
          </Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">
            Юридический адрес
          </Description>
          <Description>
            350049, г. Краснодар, ул. Красных партизан, д.515, помещ. 1/6
          </Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">
            Фактический адрес и индекс
          </Description>
          <Description>
            Ресторан «Джимми Чу» Краснодарский край, г. Геленджик, ул. Крымская,
            д.3, корп. 2, цокольный этаж № б/н, нежилое помещение № 23.{' '}
          </Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">КПП ОП</Description>
          <Description>230445001</Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">ОКВЭД</Description>
          <Description>
            56.10 Деятельность ресторанов и услуги по доставке продуктов питания
          </Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">ИНН / КПП</Description>
          <Description>2308283098/230801001</Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">БИК</Description>
          <Description>046015061</Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">
            Корреспондентский счет банка
          </Description>
          <Description>30101810560150000061</Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">
            Полное наименование банка
          </Description>
          <Description>Филиал Южный ПАО банка «ФК ОТКРЫТИЕ»</Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">
            Телефон /факс
          </Description>
          <Description>офис тел. 8 (861) 203-11-10</Description>
        </div>
        <div className="payment__info-block">
          <Description className="payment__info-title">
            Эл. адрес общий
          </Description>
          <Description>admin@madyar.ru</Description>
        </div>
      </Section>
    </>
  );
};

export default Payment;
