import React, { useContext, useRef } from 'react';
import { EstablishmentsContext } from '../../../../context/Establishments';
import utilities from '../../../../utilities';
import Cards from './Cards';
import Cash from './Cash';
import { hasCashPaymentType } from './helpers';

export default function DeliveryPaymentMethods() {
  const establishments = useContext(EstablishmentsContext);
  const paymentsTypes = useRef(
    establishments.find(
      ({ id }) => id === utilities.basket.getEstablishmentId()
    )?.payment_types
  );

  return (
    <>
      {hasCashPaymentType(paymentsTypes.current) && <Cash />}
      <Cards paymentsTypes={paymentsTypes.current} />
    </>
  );
}
