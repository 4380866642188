import { useState, useRef, useLayoutEffect } from 'react';
import cn from 'classnames';
import Description from '../../Description/Description';
import Section from '../../Section/Section';
import BlockSkeleton from '../../Skeleton/Block';
import useObserver from '../../../hooks/useIntersectionObserver';
import Title from '../../Title/Title';

export default function About({ data }) {
  const [visible, setVisible] = useState();
  const text = useRef();

  const onIntersection = (entries) => {
    setVisible(entries[0].isIntersecting);
  };

  const leadHolderClassNames = cn('lead-holder', {
    'lead-holder--visible': visible,
  });

  const { setObservable } = useObserver({
    callback: onIntersection,
    triggerOnce: true,
    options: {
      threshold: 0.25,
    },
  });

  useLayoutEffect(() => {
    if (data) {
      setObservable(text.current);
    }
  }, [data]);

  if (!data) {
    return (
      <BlockSkeleton
        width="100%"
        height={430}
        className="section"
        label="Слайдер"
      />
    );
  }
  return (
    <Section
      className={leadHolderClassNames}
      sectionRef={(element) => {
        text.current = element;
      }}
    >
      <Title tag="h1" align="center">
        Сеть ресторанов Мадьяр
      </Title>
      <Description
        className="lead"
        align="center"
        color="black"
        size="huge"
        dangerHTML={data}
      />
    </Section>
  );
}
