/* eslint-disable import/prefer-default-export */
import utilities from '../../utilities';

export const THEME_OPTIONS = [
  {
    value: 1,
    label: utilities.translate('Заказ блюда'),
  },
  { value: 2, label: utilities.translate('Команда') },
  {
    value: 3,
    label: utilities.translate('Заказ кейтеринга'),
  },
  {
    value: 4,
    label: utilities.translate('Реклама в Мадьяр'),
  },
  { value: 5, label: utilities.translate('Отзыв') },
];

export const THEME_SUCCESS_TEXT = {
  1: utilities.translate('Спасибо, ваша заявка принята.'),
  2: utilities.translate(
    'Спасибо за Ваш отклик, мы свяжемся с вам в ближайшее время'
  ),
  3: utilities.translate('Заявка на звонок принята. Мы скоро вам перезвоним.'),
  4: utilities.translate('Ваша заявка принята. Мы скоро вам перезвоним.'),
  5: utilities.translate(
    'Спасибо за Ваш отклик, мы свяжемся с вам в ближайшее время'
  ),
};
