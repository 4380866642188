/* eslint-disable no-unused-vars */
import { useContext } from 'react';
import useWindowSize from '../../../hooks/useResize';
import HistoryItem from './OrderItem';
import { AccountHistoryContext } from '../../../context/Account/History';
import More from '../../More/More';
import Rate from './Rate';
import utilities from '../../../utilities';
import routes from '../../../routes';

export default function Table() {
  const { isPhone } = useWindowSize();
  const [orders] = useContext(AccountHistoryContext);
  return (
    <>
      <div className="history__table">
        {!isPhone && (
          <div className="history__row history__row--head">
            <div className="history__id">{`${utilities.translate(
              'Заказ'
            )} №`}</div>
            <div className="history__date">{utilities.translate('Дата')}</div>
            <div className="history__cost">
              {utilities.translate('Стоимость')}
            </div>
            <div className="history__status">
              {utilities.translate('Статус')}
            </div>
          </div>
        )}
        {/* <Rate id={2} /> */}
        {orders.map((order) => (
          <HistoryItem isPhone={isPhone} key={order.id} order={order} />
        ))}
      </div>
      <div className="history__more-holder">
        <span>{utilities.translate('Смотреть всю историю')}</span>
        <More className="history__more" href={routes.page.ordersHistory()} />
      </div>
    </>
  );
}
