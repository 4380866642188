import useWindowSize from '../../hooks/useResize';
import GridSkeleton from '../Skeleton/Grid';

const RestaurantPreloader = () => {
  const { isDesktop, isTablet } = useWindowSize();

  if (isDesktop) {
    return (
      <GridSkeleton
        count={6}
        columns={3}
        gap={15}
        proportion={1}
        label="Загрузка ресторанов"
      />
    );
  }
  if (isTablet) {
    return (
      <GridSkeleton
        count={4}
        columns={2}
        gap={10}
        proportion={1}
        label="Загрузка ресторанов"
      />
    );
  }
  return (
    <GridSkeleton
      count={2}
      columns={1}
      gap={10}
      proportion={1}
      label="Загрузка ресторанов"
    />
  );
};

export default RestaurantPreloader;
