/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { useState, useRef } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import routes from '../../routes';
import Button from '../Button/Button';
import Description from '../Description/Description';
import Title from '../Title/Title';
import FormField from '../UI/Form/Field';
import Select from '../UI/Form/Select';
import Section from '../Section/Section';
import Phone from '../UI/Form/Phone';
import callGtmEvent from '../../utilities/gtm';
import Checkbox from '../UI/Form/Checkbox';
import Modal from '../Modal';
import utilities from '../../utilities';
import { THEME_OPTIONS, THEME_SUCCESS_TEXT } from './helpers';

const FeedbackForm = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      theme_code: 1,
      name: '',
      phone_number: '',
      email: '',
      message: '',
      agreement: false,
    },
  });
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting, errors, isDirty, isValid },
  } = methods;

  const themeWatcher = useWatch({
    control,
    name: 'theme_code',
  });

  const onSubmit = async (values) => {
    const response = await utilities.api.post(routes.api.feedback(), values);
    if (!response.error) {
      callGtmEvent(
        'generate_lead',
        [
          {
            item_id: values?.theme_code,
            item_name: 'feedback form',
          },
        ],
        {
          value: values?.theme_code,
        }
      );
      setModalOpen(true);
      reset();
    } else if (response.validation_error) {
      utilities.errors(setError, response.validation_error);
    }
  };

  return (
    <Section>
      <Modal state={[modalOpen, setModalOpen]}>
        <Title>{THEME_SUCCESS_TEXT[themeWatcher]}</Title>
      </Modal>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="form feedback-form">
          <Title align="center">
            {utilities.translate('Связаться с нами')}
          </Title>
          <Description
            className="collaboration__description"
            align="center"
            dangerHTML={utilities.translate(
              'Мы ответим вам в течение часа<br>по любому вопросу'
            )}
          />
          <FormField
            label={`${utilities.translate('Тема')}:`}
            required
            error={errors.theme_code}
            name="theme_code"
            component={
              <Controller
                name="theme_code"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Select
                    border={false}
                    fullWidth
                    name={field.name}
                    onChange={({ value }) => {
                      field.onChange(value);
                    }}
                    options={THEME_OPTIONS}
                  />
                )}
              />
            }
          />
          <FormField
            label={`${utilities.translate('Имя')}:`}
            type="text"
            name="name"
            required
            error={errors.name}
          />
          <FormField
            label={`${utilities.translate('Телефон')}:`}
            error={errors.phone_number}
            required
            component={
              <Controller
                rules={{
                  required: utilities.translate(
                    'Поле обязательно для заполнения'
                  ),
                  minLength: {
                    value: 10,
                    message: utilities.translate('Ошибка! Заполните поле'),
                  },
                }}
                name="phone_number"
                control={control}
                render={({ field, fieldState }) => (
                  <Phone {...field} fieldState={fieldState} />
                )}
              />
            }
          />
          <FormField
            label="Email:"
            type="email"
            name="email"
            required
            rules={{
              required: utilities.translate('Поле обязательно для заполнения'),
              validate: (value) =>
                utilities.validation.validateEmail(value) ||
                utilities.translate('Введен невалидный email'),
            }}
            error={errors.email}
          />
          <FormField
            label={`${utilities.translate('Сообщение')}:`}
            type="textarea"
            name="message"
            error={errors.message}
          />
          <FormField
            component={
              <Controller
                name="agreement"
                rules={{
                  validate: (checked) => checked,
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <Checkbox
                    square
                    error={fieldState.error}
                    holderClassName="form__agreements"
                    name={field.name}
                    onChange={(checked) => field.onChange(checked)}
                    id="agreement"
                    label={utilities.translate(
                      'Согласен(а) на обработку персональных данных'
                    )}
                  />
                )}
              />
            }
          />
          <Button type="submit" disabled={isSubmitting}>
            {utilities.translate('Отправить')}
          </Button>
          {/* <DevTool control={control} /> */}
        </form>
      </FormProvider>
    </Section>
  );
};

export default FeedbackForm;
