import { useState } from 'react';
import InfoForm from './Form';
import Info from './Info';

export default function InfoHolder() {
  const [isFormActive, setFormActive] = useState(false);
  return (
    <div className="account-info__holder">
      {isFormActive ? (
        <InfoForm hideForm={() => setFormActive(false)} />
      ) : (
        <Info state={[isFormActive, setFormActive]} />
      )}
    </div>
  );
}
