/* eslint-disable no-unused-vars */
import { createContext, useEffect, useState } from 'react';
import routes from '../../routes';
import utilities from '../../utilities';

export const AccountHistoryContext = createContext();

export const AccountHistoryProvider = ({ limit = 3, children, url }) => {
  const [state, setState] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const fetchHistory = async () => {
      const response = await utilities.api.get(
        url,
        {
          limit: limit || 0,
        },
        {
          Authorization: `Token ${utilities.storage.get('madyar-auth-token')}`,
        },
        null,
        true
      );
      setState(response.results || response);
    };
    fetchHistory();
  }, []);

  return (
    <AccountHistoryContext.Provider value={[state, setState]}>
      {children}
    </AccountHistoryContext.Provider>
  );
};
