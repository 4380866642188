/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import routes from '../../routes';
import utilities from '../../utilities';
import callGtmEvent from '../../utilities/gtm';
import { EstablishmentsContext } from '../Establishments';

export const DishesContext = createContext();
export const DishesFilterContext = createContext();

export const DishesFilterContextProvider = (props) => {
  const location = useLocation();
  const { children } = props;
  const categorySearchRef = useRef(utilities.params.toObject(location.search));
  const { establishmentId } = useParams();
  const [establishment, setEstablishment] = useState(null);
  const establishments = useContext(EstablishmentsContext);
  const [categories, setCategories] = useState(null);
  const [active, setActive] = useState(null);
  const firstMount = useRef(true);

  const setFilter = useCallback((id) => {
    setActive(categories.filter(({ id: categoryId }) => categoryId === id));
  }, []);

  const data = useMemo(
    () => ({
      categories,
      active,
    }),
    [categories, active]
  );

  useEffect(() => {
    if (establishments) {
      setEstablishment(
        establishments.find(({ slug }) => slug === establishmentId)
      );
    }
  }, [establishments]);

  useEffect(() => {
    if (!firstMount.current) {
      const categorySearch = utilities.params.toObject(location.search);
      if (categorySearch.category) {
        setActive(
          categories.find(
            ({ slug: categorySlug }) =>
              categorySlug.toString() === categorySearch.category
          )
        );
      }
    }
  }, [location]);

  useEffect(() => {
    if (establishment) {
      const fetchCategories = async () => {
        const response = await utilities.api.get(
          routes.apiSwagger.dishCategories(),
          {
            establishment_slug: establishment?.slug,
          }
        );
        setCategories(response);
        if (!categorySearchRef.current.category) {
          setActive(response[0]);
        } else {
          setActive(
            response.find(
              ({ slug: categorySlug }) =>
                categorySlug.toString() === categorySearchRef.current.category
            )
          );
        }
      };
      fetchCategories();
      firstMount.current = false;
    }
  }, [establishment]);

  return (
    <DishesFilterContext.Provider value={{ ...data }}>
      {children}
    </DishesFilterContext.Provider>
  );
};

export const DishesContextProvider = (props) => {
  const { children } = props;
  const { active, categories } = useContext(DishesFilterContext);
  const { establishmentId } = useParams();
  const [dishes, setDishes] = useState(null);
  const [establishment, setEstablishment] = useState(null);
  const establishments = useContext(EstablishmentsContext);

  useEffect(() => {
    if (establishments) {
      setEstablishment(
        establishments.find(({ slug }) => slug === establishmentId)
      );
    }
  }, [establishments]);

  useEffect(() => {
    if (establishment) {
      const fetchDishes = async () => {
        const response = await utilities.api.get(routes.apiSwagger.dishes(), {
          category_id: active.id,
          establishment_id: establishment?.id,
        });
        setDishes(response.results);
      };
      if (active) {
        setDishes(null);
        fetchDishes(active);
      }
    }
  }, [active, establishment]);

  useEffect(() => {
    const items = [];
    dishes?.forEach((dish) => {
      const item = {
        item_name: dish.name,
        item_id: dish.id,
        item_category: active.name,
        price: dish.price,
        quantity: 1,
      };
      items.push(item);
    });
    if (items.length > 0) {
      callGtmEvent('view_item_list', items, {});
    }
  }, [dishes]);

  return (
    <DishesContext.Provider
      value={{ dishes, categories, activeCategory: active, establishment }}
    >
      {children}
    </DishesContext.Provider>
  );
};
