/* eslint-disable no-unused-vars */
import cn from 'classnames';
import { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { GlobalBasketContext } from '../../context/GlobalBasketContext';
import useLocalStorageChange from '../../hooks/useLocalStorageChange';
import routes from '../../routes';
import utilities from '../../utilities';
import { SHOPPING_CARD_KEY } from '../../utilities/basket';
import callGtmEvent from '../../utilities/gtm';

export default function HeaderBasket() {
  const { totalCount, totalPrice } = useContext(GlobalBasketContext);
  const shoppingCard = useLocalStorageChange({ field: SHOPPING_CARD_KEY });

  const svgClasses = cn('basket-link__icon', {
    'basket-link__icon--contains': totalCount > 0,
  });

  const linkClasses = cn('header__basket-link basket-link', {
    'header__basket-link--disabled': !shoppingCard,
  });

  const onClick = (event) => {
    if (!shoppingCard) event.preventDefault();
  };

  return (
    <NavLink
      to={routes.page.basket()}
      className={linkClasses}
      type="button"
      onClick={onClick}
      aria-label="Перейти в корзину"
    >
      <svg
        className={svgClasses}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 53 40"
      >
        <g fill="none" fillRule="evenodd">
          <g id="fork">
            <path d="M2.94 8v4.99" />
            <path d="M1.77 25.63c0-1.6.57-4.76.66-6.36.05-.9 0-3.61 0-3.61a.55.55 0 00-.14-.39l-.87-.9A1.81 1.81 0 011 13.14V8.05" />
            <path d="M4.9 8.05v5.09c0 .48-.17.93-.45 1.21l-.94.9c-.1.11-.16.26-.16.4l-.01 3.62c.08 1.6.68 4.68.68 6.28v6.26a1.12 1.12 0 01-1.13 1.12 1.12 1.12 0 01-1.12-1.12v-6.18" />
          </g>
          <g id="plate_outer">
            <path d="M40.44 33.44A18.87 18.87 0 0127 39c-5.08 0-9.85-1.98-13.43-5.56A18.88 18.88 0 018 20c0-5.08 1.98-9.85 5.57-13.43A18.87 18.87 0 0127 1c5.08 0 9.85 1.98 13.44 5.57A18.87 18.87 0 0146 20c0 5.08-1.98 9.85-5.56 13.44z" />
          </g>
          <g id="plate_inner">
            <path d="M34.78 27.78A10.93 10.93 0 0127 31c-2.94 0-5.7-1.14-7.78-3.22A10.93 10.93 0 0116 20c0-2.94 1.14-5.7 3.22-7.78A10.93 10.93 0 0127 9c2.94 0 5.7 1.14 7.78 3.22A10.93 10.93 0 0138 20c0 2.94-1.14 5.7-3.22 7.78z" />
          </g>
          <g id="knife">
            <path d="M50.79 32.8c-.62 0-1.13-.5-1.13-1.12l.01-6.62c0-.33.21-.63.52-.74.5-.19.82-.63.82-1.13v-.95a1.22 1.22 0 00-1.22-1.22.78.78 0 01-.79-.79v-5.86c0-4.54 1.9-5.94 2.91-6.37v23.67c0 .62-.5 1.13-1.12 1.13z" />
          </g>
        </g>
        <text
          className="basket-link__count"
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {totalCount < 10 ? `0${totalCount}` : totalCount}
        </text>
      </svg>
      <span className="basket-link__price">
        <span className="basket-link__price-value">{totalPrice || 0}</span>{' '}
        {utilities.translate('руб')}.
      </span>
    </NavLink>
  );
}
