import { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import routes from '../../routes';
import { EstablishmentsContext } from '../../context/Establishments';
import BlockSkeleton from '../Skeleton/Block';
import Map from '../Map';
import Title from '../Title/Title';
import utilities from '../../utilities';
import Select from '../UI/Form/Select';

const Restaurant = () => {
  const establishments = useContext(EstablishmentsContext);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);

  useEffect(() => {
    if (establishments) {
      const [firstElement] = establishments;
      setSelectedRestaurant(firstElement);
    }
  }, [establishments]);

  const handleSelectChange = ({ value }) => {
    setSelectedRestaurant(
      establishments.find(({ id }) => id.toString() === value.toString())
    );
  };

  return (
    <>
      <div className="contacts__restaurants">
        <Title className="contacts__title">
          {utilities.translate('Рестораны')}
        </Title>
        {selectedRestaurant ? (
          <Select
            fullWidth
            large
            border
            name="establishment"
            className="contacts-select"
            onChange={handleSelectChange}
            options={establishments.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
          />
        ) : (
          <BlockSkeleton
            width="100%"
            height={60}
            style={{ marginTop: '35px', marginBottom: '20px' }}
          />
        )}
        {selectedRestaurant ? (
          <article className="contacts__article">
            <script
              type="application/ld+json"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `{ "@context": "http://schema.org/",
                "@type": "Organization",
                "telephone": "${`+7${selectedRestaurant.phone}`.toString()}",
                "email": "${selectedRestaurant.email.toString()}"
              }`,
              }}
            />
            <img
              className="contacts__logo"
              src={selectedRestaurant.logo}
              alt=""
            />
            <NavLink
              className="contacts__button button"
              to={routes.page.dishes(selectedRestaurant.id)}
            >
              {utilities.translate('Меню')}
            </NavLink>
            <ul className="contacts__list">
              <li className="contacts__info">
                <Title className="contacts__parameter" size="small">
                  {utilities.translate('Адрес')}:
                </Title>
                <div className="contacts__data">
                  {selectedRestaurant.branches[0]?.address?.street
                    ? selectedRestaurant.branches[0].address.street
                    : '—'}
                </div>
              </li>
              <li className="contacts__info">
                <Title className="contacts__parameter" size="small">
                  {utilities.translate('Телефон')}:
                </Title>
                {selectedRestaurant.phone ? (
                  <p className="contacts__data">
                    {`+7${selectedRestaurant.phone}`}
                  </p>
                ) : (
                  '—'
                )}
              </li>
              <li className="contacts__info">
                <Title className="contacts__parameter" size="small">
                  Email:
                </Title>
                {selectedRestaurant.email ? (
                  <div className="contacts__data">
                    {selectedRestaurant.email}
                  </div>
                ) : (
                  '—'
                )}
              </li>
              <li className="contacts__info">
                <Title className="contacts__parameter" size="small">
                  {utilities.translate('Время доставки')}:
                </Title>
                <div className="contacts__data">
                  {selectedRestaurant.start_delivery_time &&
                  selectedRestaurant.end_delivery_time
                    ? `${utilities.translate('с')} ${
                        selectedRestaurant.start_delivery_time
                      } ${utilities.translate('по')} ${
                        selectedRestaurant.end_delivery_time
                      }`
                    : '—'}
                </div>
              </li>
            </ul>
            <ul className="contacts__socials">
              <li>
                <a
                  className="contacts__social"
                  rel="noreferrer noopener"
                  target="_blank"
                  href="https://t.me/madyarcollection"
                >
                  Telegram
                </a>
              </li>
            </ul>
          </article>
        ) : (
          <BlockSkeleton width="100%" height={435} />
        )}
      </div>
      {selectedRestaurant?.branches && (
        <Map zoom={15} placemarks={[selectedRestaurant]} />
      )}
    </>
  );
};

export default Restaurant;
