/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AccountContext } from '../../../context/Account';
import { ReactComponent as LogoutIcon } from '../../../icons/logout.svg';
import routes from '../../../routes';
import utilities from '../../../utilities';
import Button from '../../Button/Button';

export default function Logout() {
  const { setAccount } = useContext(AccountContext);
  const history = useHistory();

  const onClick = async () => {
    await utilities.api.post(
      routes.apiSwagger.logout(utilities.storage.get('madyar-id'))
    );
    utilities.logout();
    history.push(routes.page.home());
    setAccount(null);
  };

  return (
    <Button className="account--logout" onClick={onClick} modifier="text">
      {utilities.translate('Выход')}
      <LogoutIcon className="profile-teaser__logout-icon" />
    </Button>
  );
}
