/* eslint-disable camelcase */

import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Meta from '../components/Meta';
import RestaurantPreloader from '../components/Restaurant/Preloader';
import RestaurantTeaser from '../components/Restaurant/Teaser';
import Section from '../components/Section/Section';
import Title from '../components/Title/Title';
import { EstablishmentsContext } from '../context/Establishments';
import useObserver from '../hooks/useIntersectionObserver';
import utilities from '../utilities';

const Restaurants = () => {
  const establishments = useContext(EstablishmentsContext);
  const { hash } = useLocation();
  const observable = useRef([]);
  const isMounted = useRef(true);
  const [intersected, setIntersected] = useState({});
  const [renderCondition, setRenderCondition] = useState(false);
  const firstMount = useRef(true);

  const onIntersection = (entries) => {
    const intersectedFiltered = entries.filter((entry) => entry.isIntersecting);
    if (intersectedFiltered.length) {
      const intersectedSorted = intersectedFiltered.reduce(
        (acc, { target }, index) => {
          const nodeIndex = observable.current.findIndex(
            (node) => node === target
          );
          acc[nodeIndex] = { target, test: index };
          return acc;
        },
        []
      );
      intersectedSorted.forEach(({ test }, index) => {
        setTimeout(() => {
          if (isMounted.current) {
            setIntersected((prev) => ({ ...prev, [`${index}`]: 1 }));
          }
        }, 100 * test);
      });
    }
  };

  const { setObservable, resetObserver } = useObserver({
    callback: onIntersection,
    triggerOnce: true,
    options: {
      threshold: 0.25,
    },
  });

  const checkHash = () => {
    const hashParams = utilities.params.toObject(hash);
    return Object.keys(hashParams).includes('delivery');
  };

  const [onlyDelivery, setOnlyDelivery] = useState(checkHash);
  const [showOnlyDelivery, setShowOnlyDelivery] = useState(checkHash);

  useEffect(() => {
    setOnlyDelivery(checkHash());
  }, [hash]);

  useLayoutEffect(() => {
    if (establishments) {
      setObservable(observable.current);
    }
  }, [establishments]);

  const hideCard = (_, index) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setIntersected((prev) => ({ ...prev, [`${index}`]: 0 }));
        resolve();
      }, [50 * index]);
    });
  };

  useEffect(() => {
    if (!firstMount.current) {
      Promise.all(observable.current.map(hideCard)).then(() => {
        if (isMounted.current) {
          setRenderCondition(false);
          setShowOnlyDelivery(onlyDelivery);
        }
      });
    }
  }, [onlyDelivery]);

  const onPageLeave = () => {
    Promise.all(observable.current.map(hideCard)).then(() => {
      document.dispatchEvent(
        new CustomEvent(utilities.ROUTE_CONSTANTS.PAGE_SHOW_NEXT)
      );
    });
  };

  useLayoutEffect(() => {
    if (renderCondition) {
      resetObserver(observable.current.filter((node) => node));
    }
  }, [renderCondition]);

  useEffect(() => {
    if (isMounted.current) {
      setRenderCondition(true);
    }
  }, [showOnlyDelivery]);

  useEffect(() => {
    firstMount.current = false;
    document.addEventListener(
      utilities.ROUTE_CONSTANTS.PAGE_LEAVE,
      onPageLeave
    );

    return () => {
      document.removeEventListener(
        utilities.ROUTE_CONSTANTS.PAGE_LEAVE,
        onPageLeave
      );
      firstMount.current = true;
      observable.current = null;
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      <Meta url="/restaurants" />
      <Title tag="h1" isHidden>
        Рестораны
      </Title>
      <Breadcrumbs />
      <Section observable={false}>
        {establishments ? (
          renderCondition && (
            <div className="restaurant-teasers">
              {establishments.map((data, index) => (
                <RestaurantTeaser
                  ref={(element) => {
                    observable.current[index] = element;
                  }}
                  observable
                  // onClick={onClick}
                  data={data}
                  isVisible={intersected[index]}
                  menuLink={onlyDelivery}
                  key={data.id}
                />
              ))}
            </div>
          )
        ) : (
          <RestaurantPreloader />
        )}
      </Section>
    </>
  );
};

export default Restaurants;
