import { Controller, useFormContext } from 'react-hook-form';
import Counter from '../../UI/Counter';

export default function DeliveryCulteries() {
  const { control } = useFormContext();
  return (
    <Controller
      name="person_count"
      control={control}
      defaultValue="1"
      render={({ field }) => (
        <Counter
          onChange={(count) => field.onChange(count)}
          className="delivery-culteries-counter"
        />
      )}
    />
  );
}
