import React, { useContext, useState } from 'react';
import routes from '../../../../routes';
import utilities from '../../../../utilities';
import Button from '../../../Button/Button';
import ModalComponent from '../../../Modal';
import Title from '../../../Title/Title';
import Description from '../../../Description/Description';
import { AccountHistoryContext } from '../../../../context/Account/History';

export default function CancelOrder({ id }) {
  const [isPending, setPending] = useState();
  const [orders, setOrders] = useContext(AccountHistoryContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const onModalOpen = () => {
    setModalOpen(true);
  };

  const onDecline = () => {
    setModalOpen(false);
  };

  const onConfirm = async () => {
    setPending(true);
    const response = await utilities.api.post(
      routes.apiSwagger.cancelOrder(id),
      {
        confirmed: true,
      }
    );
    if (response.error) {
      setPending(false);
    } else {
      const index = orders.findIndex(({ id: orderIndex }) => orderIndex === id);
      const newOrders = orders.slice();
      newOrders.splice(index, 1, response);
      setOrders(newOrders);
      setModalOpen(false);
    }
  };

  return (
    <>
      <ModalComponent state={[isModalOpen, setModalOpen]}>
        <Title>Вы уверены, что хотите отменить заказ?</Title>
        <Description>
          <Button disabled={isPending} onClick={onConfirm}>
            Да
          </Button>
          <Button onClick={onDecline}>Нет</Button>
        </Description>
      </ModalComponent>
      <Button
        onClick={onModalOpen}
        modifier="text"
        className="history-action__cancel-order"
      >
        Отменить заказ
      </Button>
    </>
  );
}
