/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
import { DevTool } from '@hookform/devtools';
import { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { AccountContext } from '../../../context/Account';
import { AuthContext } from '../../../context/Auth';
import routes from '../../../routes';
import utilities from '../../../utilities';
import Button from '../../Button/Button';
import { callGtmEventNonCommerce } from '../../../utilities/gtm';
import Title from '../../Title/Title';
import FormField from '../../UI/Form/Field';

const CODE_COUNT = 6;

export default function CodeForm({ setModalOpen, redirect }) {
  const {
    data: [data],
  } = useContext(AuthContext);
  const { setAccount } = useContext(AccountContext);
  const methods = useForm();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isDirty },
  } = methods;
  const history = useHistory();
  const inputsRefs = useRef([]);
  const buttonRef = useRef();
  const [error, setError] = useState(false);

  const onSubmit = async (values) => {
    const response = await utilities.api.post(routes.apiSwagger.login(), {
      // ...data,
      phone: data.phone,
      password: Object.values(values).join(''),
    });
    if (!response.error) {
      utilities.storage.set('madyar-auth-token', response.auth_token);
      utilities.storage.set('madyar-id', response.id);
      callGtmEventNonCommerce('user_id', {
        user_id: response.id,
      });
      const userData = await utilities.api.get(
        routes.apiSwagger.account(),
        {},
        {
          Authorization: `Token ${response.auth_token}`,
        }
      );
      setAccount(userData);
      if (redirect) {
        history.push(routes.page.account());
      }
    } else {
      setError(true);
      inputsRefs.current.forEach((_, index) => {
        setValue(`code-${index}`, '');
      });
    }
  };

  const onBeforeInput = (event) => {
    const { data: inputData, target } = event;
    if (Number.isNaN(parseInt(inputData, 10))) {
      event.preventDefault();
      return;
    }
    if (target.value.length > 0) {
      target.value = '';
    }
  };

  const onChange = ({ target }) => {
    const index = inputsRefs.current.indexOf(target);
    if (index >= 0) {
      if (error) setError(false);
      setValue(`code-${index}`, target.value);
      if (
        target.value.length === 1 &&
        index !== inputsRefs.current.length - 1
      ) {
        inputsRefs.current[index + 1].focus();
      } else if (
        index === inputsRefs.current.length - 1 &&
        target.value.length === 1
      ) {
        buttonRef.current.focus();
      }
    }
  };

  const onPaste = (event) => {
    event.preventDefault();
    const numbers = event.clipboardData.getData('Text').split('');
    numbers.forEach((num, index) => {
      if (index <= CODE_COUNT - 1 && !Number.isNaN(parseInt(num, 10))) {
        inputsRefs.current[index].value = num;
        setValue(`code-${index}`, num);
      }
    });
    buttonRef.current.focus();
  };

  const onFocus = ({ target }) => {
    if (target.value.length === 0) {
      const emptyIdnex = inputsRefs.current.findIndex(
        (input) => input.value.length === 0
      );
      if (emptyIdnex > -1) {
        inputsRefs.current[emptyIdnex].focus();
      }
    }
  };

  useEffect(() => {
    return () => {
      setModalOpen(false);
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <form
        onFocus={onFocus}
        onPaste={onPaste}
        // onKeyDown={onKeyDown}
        onChange={onChange}
        onBeforeInput={onBeforeInput}
        onSubmit={handleSubmit(onSubmit)}
        className="auth-form auth-form__code"
      >
        <Title color={error && 'red'} className="auth-form__title">
          {!error
            ? utilities.translate('Введите код')
            : utilities.translate('Неверный код!')}
        </Title>
        <div className="auth-form__code-holder">
          {[...Array(CODE_COUNT)].map((_, index) => (
            <FormField
              shouldRegister
              required
              error={error}
              inputRef={(element) => {
                inputsRefs.current[index] = element;
              }}
              // eslint-disable-next-line react/no-array-index-key
              key={`code-${index}`}
              name={`code-${index}`}
            />
          ))}
        </div>
        <Button
          tabindex="-1"
          ref={buttonRef}
          type="submit"
          disabled={!isDirty || isSubmitting}
        >
          {utilities.translate('Отправить')}
        </Button>
        {/* <DevTool control={control} /> */}
      </form>
    </FormProvider>
  );
}
