import { useEffect, useMemo, useState } from 'react';

export default function useWindowSize() {
  const [direction, setDirection] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  const [prev, setPrev] = useState(window.scrollY);
  const onScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    if (scrollPosition > prev) {
      setDirection(true);
    } else {
      setDirection(false);
    }
    setPrev(scrollPosition);
  }, [scrollPosition]);

  const data = useMemo(
    () => ({
      direction,
      scrollPosition,
    }),
    [scrollPosition]
  );

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  return data;
}
