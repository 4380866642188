const DESKTOP_THRESHOLD = 1200;
const { body } = document;

let animation;

export function showContent() {
  body.classList.remove('body--animated');
}

function findElement() {
  if (window.innerWidth >= DESKTOP_THRESHOLD) {
    animation = body.querySelector('.circle__middle-text');
  } else {
    animation = body.querySelector('.circle__fill');
  }
}

function subscribe() {
  if (animation) {
    animation.addEventListener('animationend', showContent);
  } else {
    showContent();
  }
}

export function init() {
  findElement();
  if (process.env.REACT_APP_CIRCLE_ANIMATION) {
    subscribe();
  } else {
    showContent();
  }
}
