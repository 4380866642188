/* eslint-disable camelcase */
/* eslint-disable import/no-named-as-default-member */

import { useState, useContext } from 'react';
import { OKShareButton, VKShareButton } from 'react-share';
import utilities from '../../../../utilities';
import Description from '../../../Description/Description';
import LazyLoad from '../../../LazyLoadImage';
import Title from '../../../Title/Title';
import DishBasket from './DishBasket';
import Modal from '../../../Modal';
import okSvg from '../../../../icons/ok.svg';
import vkSvg from '../../../../icons/vk.svg';
import favoriteSvg from '../../../../icons/favorite.svg';
import shareSvg from '../../../../icons/share.svg';
import routes from '../../../../routes';
import deleteSvg from '../../../../icons/white-close.svg';
import { AccountContext } from '../../../../context/Account';
// import { FavoriteDishesContext } from '../../../../context/Dishes/Favorite';

const FoodTeaser = (props) => {
  const { account } = useContext(AccountContext);
  const { data } = props;
  // const [updateDishes, setUpdateDishes] = useContext(FavoriteDishesContext);
  const {
    id,
    name,
    description,
    weight,
    price,
    image,
    options,
    is_favorite,
  } = data;
  const [isOpen, setOpen] = useState();
  const [isFavorite, setIsFavorite] = useState(is_favorite);

  async function setFavorite() {
    if (is_favorite) {
      const response = await utilities.api.remove(
        routes.apiSwagger.addFavorite(id)
      );
      if (response.id) {
        setIsFavorite(response.is_favorite);
        // setUpdateDishes(
        //   updateDishes >= 0 ? updateDishes - 1 : updateDishes - 1
        // );
      }
    } else {
      const response = await utilities.api.post(
        routes.apiSwagger.addFavorite(id)
      );
      if (response.id) {
        setIsFavorite(response.is_favorite);
        // setUpdateDishes(
        //   updateDishes >= 0 ? updateDishes - 1 : updateDishes - 1
        // );
      }
    }
  }

  return (
    <article className="food-teaser" key={id}>
      <Title className="food-teaser__title" tag="h3" size="small">
        {name}
      </Title>
      <Description className="food-teaser__description">
        {description}
      </Description>
      <div className="food-teaser__parameters">
        <div className="food-teaser__weight">{weight}</div>
        {options?.length ? (
          <ul className="food-teaser__options">
            {options.map(({ name: optionName, value }) => (
              <li key={optionName} className="food-teaser__option">
                <span className="food-teaser__option-name">{optionName}</span>
                <span className="food-teaser__option-value">{value}</span>
              </li>
            ))}
          </ul>
        ) : null}
        <div className="food-teaser__footer">
          <div className="food-teaser__price">
            {utilities.currency.toString(price)}
          </div>
          <DishBasket dish={data} />
        </div>
      </div>
      <LazyLoad holder className="food-teaser__image" alt={name} src={image} />
      <div className="food-teaser__buttons">
        {account && (
          <button
            type="button"
            className="food-teaser__button"
            onClick={setFavorite}
          >
            {isFavorite ? (
              <img
                src={deleteSvg}
                className="food-teaser__favorite"
                alt="favoriteIcon"
              />
            ) : (
              <img
                src={favoriteSvg}
                className="food-teaser__favorite"
                alt="favoriteIcon"
              />
            )}
          </button>
        )}
        <button
          type="button"
          className="food-teaser__button"
          onClick={() => {
            setOpen(true);
          }}
        >
          <img src={shareSvg} className="food-teaser__share" alt="shareIcon" />
        </button>
      </div>
      <Modal
        state={[isOpen, setOpen]}
        className="modal-sharing__portal"
        contentClassName="modal-sharing"
      >
        <h2>Поделиться</h2>
        <div className="modal-sharing__container">
          <VKShareButton
            url={window.location.href}
            title={name}
            image={image}
            className="modal-sharing__button"
          >
            <img className="modal-sharing__vk" src={vkSvg} alt="vkIcon" />
          </VKShareButton>
          <OKShareButton
            url={window.location.href}
            title={name}
            image={image}
            description={description}
            className="modal-sharing__button"
          >
            <img className="modal-sharing__ok" src={okSvg} alt="okIcon" />
          </OKShareButton>
        </div>
      </Modal>
    </article>
  );
};

export default FoodTeaser;
