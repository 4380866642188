/* eslint-disable no-unused-vars */
import cn from 'classnames';
import {
  NavLink,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ReactComponent as CircleIcon } from '../../icons/circle.svg';
import utilities from '../../utilities';
import routes from '../../routes';
import Apps from '../Apps/Apps';
import FooterMarquee from './FooterMarquee';

const Footer = () => {
  const homeMatch = useRouteMatch(routes.page.home());
  const location = useLocation();
  const [isMobileAppPage, setMobileAppPage] = useState(
    location.pathname === routes.page.mobileApplication()
  );
  const footerClasses = cn('footer', {
    'footer--home': homeMatch?.isExact,
  });
  const copyRightLink = cn('copyright__link', {
    'copyright__link--disabled': isMobileAppPage,
  });
  const [legalLinks, setLegalLinks] = useState([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      const response = await utilities.api.get(routes.api.legalDocuments());
      setLegalLinks(response.documents);
    };
    fetchDocuments();
  }, []);

  useEffect(() => {
    setMobileAppPage(location.pathname === routes.page.mobileApplication());
  }, [routes.page.mobileApplication(), location]);

  return (
    <footer className={footerClasses}>
      <Switch>
        <Route exact path={routes.page.home()}>
          <FooterMarquee />
          <div className="ring">
            <CircleIcon className="ring__svg" />
            <Apps
              phoneColor="white"
              hoverColor="white"
              className="ring__apps"
            />
          </div>
        </Route>
      </Switch>
      <div className="copyright">
        <div className="copyright__text">© Madyar Collection 2022</div>
        <ul className="copyright__menu">
          <li className="copyright__menu-item">
            <NavLink
              className={copyRightLink}
              to={routes.page.mobileApplication()}
            >
              {`${utilities.translate('Мобильное приложение')}`}
            </NavLink>
          </li>
          {legalLinks &&
            legalLinks.map((link) => {
              return (
                <li key={link.id} className="copyright__menu-item">
                  <NavLink
                    className={`copyright__link ${
                      location.pathname.includes(
                        `${routes.page.documents()}/${link.slug || link.id}`
                      )
                        ? 'active-link'
                        : ''
                    }`}
                    to={`${routes.page.documents()}/${link.slug || link.id}`}
                  >
                    {link.title}
                  </NavLink>
                </li>
              );
            })}
        </ul>
        <a className="copyright__link copyright__text" href="http://ruport.ru/">
          RUPORT_
        </a>
      </div>
    </footer>
  );
};

export default Footer;
