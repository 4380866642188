/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { GlobalBasketContext } from '../../../context/GlobalBasketContext';
import useWindowSize from '../../../hooks/useResize';
import routes from '../../../routes';
import utilities from '../../../utilities';
import Button from '../../Button/Button';
import callGtmEvent from '../../../utilities/gtm';
import FormField from '../../UI/Form/Field';

export default function Promo() {
  const { isPhone } = useWindowSize();
  const { setRestInfo, promocode } = useContext(GlobalBasketContext);
  const { getValues } = useFormContext();
  const [shouldAddPromo, setShouldAddPromo] = useState(!promocode);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const addPromoCode = async () => {
    const response = await utilities.api.post(
      routes.apiSwagger.addPromoCode(),
      {
        code: getValues('code'),
      }
    );
    if (!response.error) {
      const {
        items,
        amount,
        establishment_name: establishmentName,
        id,
        promocode: promo,
        total_bonus: totalBonus,
        promocode_message: promocodeMessage,
        ...rest
      } = response;
      utilities.basket.setTotal(response.amount);
      setRestInfo({ ...rest });
      setLoading(false);
      setShouldAddPromo(false);
      callGtmEvent('select_promotion', [
        {
          item_id: id,
          item_name: promocodeMessage,
          affiliation: establishmentName,
          coupon: promo,
          currency: 'RUB',
          discount: totalBonus,
        },
      ]);
    } else {
      setError({ message: response.detail });
      // if (response.error?.)
    }
  };

  const removePromoCode = async () => {
    const response = await utilities.api.post(
      routes.apiSwagger.removePromoCode(),
      {
        code: getValues('code'),
      }
    );
    const { items, ...rest } = response;
    utilities.basket.setTotal(response.amount);
    setRestInfo({ ...rest });
    setLoading(false);
    setShouldAddPromo(true);
  };

  const handlePromoCode = () => {
    if (shouldAddPromo) {
      addPromoCode();
    } else {
      removePromoCode();
    }
  };

  const onFocus = () => {
    if (error) {
      setError(null);
    }
  };

  useEffect(() => {
    const fetchPromoCodes = async () => {
      const response = await utilities.api.get(
        routes.apiSwagger.userPromocodes()
      );
    };
    fetchPromoCodes();
  }, []);
  return (
    <>
      <div className="promo-form">
        <FormField
          direction="column"
          nomargin
          error={error}
          onFocus={onFocus}
          label={`${utilities.translate('Промокод')}:`}
          name="code"
        />
        <Button
          size={isPhone ? 'full' : ''}
          className="promo-form__button"
          onClick={handlePromoCode}
          type="button"
          disabled={loading}
        >
          {utilities.translate(shouldAddPromo ? 'Применить' : 'Удалить')}
        </Button>
      </div>
    </>
  );
}
