import { useState, useEffect } from 'react';
import Title from '../Title/Title';
import GeneralPreloader from './Preloader';
import ContactsLink from './Link';
import routes from '../../routes';
import utilities from '../../utilities';

const General = () => {
  const [contacts, setContacts] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      const response = await utilities.api.get(routes.api.contacts());
      setContacts(response.contacts);
    };
    fetchContacts();
  }, []);

  return (
    <div className="contacts__general">
      <Title tag="h1" className="contacts__title">
        {utilities.translate('Общие контакты')}
      </Title>
      <ul className="contacts__list">
        {contacts ? (
          <>
            {contacts.map(({ items, title, id }) => (
              <>
                <script
                  type="application/ld+json"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: `{ "@context": "http://schema.org/",
                      "@type": "Organization",
                      "telephone": "${items[0].text}",
                      "email": "${items[1].text}"
                    }`,
                  }}
                />
                <li key={id} className="contacts__item">
                  <Title size="medium">{title}</Title>
                  {items.map(({ type, text, id: identify, link }) => (
                    <ContactsLink
                      key={identify}
                      type={type}
                      text={text}
                      link={link}
                    />
                  ))}
                </li>
              </>
            ))}
          </>
        ) : (
          <GeneralPreloader />
        )}
      </ul>
    </div>
  );
};

export default General;
