/* eslint-disable no-unused-vars */
import React, { useContext, useMemo, useState } from 'react';
import Title from '../Title/Title';
import Description from '../Description/Description';
import Select from '../UI/Form/Select';
import Button from '../Button/Button';
import { ModalContext } from '../../context/Modal';
import utilities from '../../utilities';
import { GlobalBasketContext } from '../../context/GlobalBasketContext';
import Checkbox from '../UI/Form/Checkbox';
import callGtmEvent from '../../utilities/gtm';
import routes from '../../routes';

export default function DishPropertySelect({ dish }) {
  const { property } = dish;
  const [, setOpen] = useContext(ModalContext);
  const { update } = useContext(GlobalBasketContext);

  const defaultValue = useMemo(() => {
    const { value, id } = property.values.find(
      ({ default: defaultProperty }) => defaultProperty
    );
    return {
      label: value,
      value: id,
    };
  }, []);

  const [propertyId, setPropertyId] = useState(defaultValue.value);

  const handleAccept = async () => {
    await update({ ...dish, dish_id: dish.id, property_selected: propertyId });
    const category = await utilities.api.get(
      routes.apiSwagger.dishCategoryInfo(dish.category_id)
    );
    callGtmEvent('add_to_cart', [
      {
        item_id: dish.id,
        item_name: dish.name,
        item_category: category.name,
        price: dish.price,
        currency: 'RUB',
        quantity: 1,
      },
    ]);
    setOpen(false);
  };

  return (
    <>
      <Title>{property.name}</Title>
      {/* <Description> */}
      {property.values.map(({ value, id, default: defaultChecked }) => (
        <Checkbox
          holderClassName="dish-modal-property-checkbox"
          type="radio"
          name={`dish-modal-property${dish.id}`}
          value={id}
          defaultChecked={defaultChecked}
          onValueChange={(selectedPropertyId) =>
            setPropertyId(selectedPropertyId)
          }
          label={value}
          key={id}
        />
      ))}
      {/* <Select
        fullWidth
        className="dish-property-select"
        controllingValue={defaultValue}
        name={`modal-dish-property-${dish.id}`}
        options={property.values.map(({ value, id }) => ({
          label: value,
          value: id,
        }))}
        onChange={({ value }) => setPropertyId(value)}
      /> */}
      <div className="dish-basket-holder">
        <Button type="button" size="medium" onClick={() => setOpen(false)}>
          {utilities.translate('Отмена')}
        </Button>
        <Button onClick={handleAccept} type="button" size="medium">
          {utilities.translate('Добавить')}
        </Button>
      </div>
      {/* </Description> */}
    </>
  );
}
