import { useEffect, useState } from 'react';
import HeaderContent from './Content';
import Menu from './Menu';

const { body } = document;

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isButtonHamburger, setButtonHamburger] = useState(true);
  const [isMenuButtonAnimating, setMenuButtonAnimating] = useState(false);
  const [isMenuButtonClose, setMenuButtonClose] = useState(false);

  const toggleBodyStuck = (fixed) => {
    if (fixed) {
      body.classList.add('body--stuck');
    } else {
      body.classList.remove('body--stuck');
    }
  };

  useEffect(() => {
    toggleBodyStuck(menuOpen);
  }, [menuOpen]);

  const onMenuButtonClick = () => {
    setMenuOpen(!menuOpen);
    if (menuOpen) {
      setMenuButtonAnimating(true);
      setButtonHamburger(true);
    } else {
      setMenuButtonClose(false);
    }
  };

  const hideMenu = () => {
    setMenuOpen(false);
    setMenuButtonClose(false);
  };

  useEffect(() => {
    document.dispatchEvent(
      new CustomEvent('menu:status', { detail: menuOpen })
    );
  }, [menuOpen]);

  return (
    <>
      <HeaderContent
        menuOpen={menuOpen}
        onMenuButtonClick={onMenuButtonClick}
        isButtonHamburger={isButtonHamburger}
        setButtonHamburger={setButtonHamburger}
        setMenuButtonAnimating={setMenuButtonAnimating}
        isMenuButtonAnimating={isMenuButtonAnimating}
        isMenuButtonClose={isMenuButtonClose}
        setMenuButtonClose={setMenuButtonClose}
      />
      <Menu menuOpen={menuOpen} hideMenu={hideMenu} />
    </>
  );
};

export default Header;
