import { useCallback, useEffect, useMemo, useState } from 'react';

export default function useObserver({
  callback,
  triggerOnce = false,
  options,
}) {
  const [observable, setObservable] = useState(null);
  const onIntersection = (entries, watcher) => {
    if (triggerOnce) {
      const intersected = entries.filter(
        ({ isIntersecting }) => isIntersecting
      );
      intersected.forEach(({ target }) => {
        watcher.unobserve(target);
      });
    }
    callback(entries, watcher);
  };

  let observer = useMemo(
    () => new IntersectionObserver(onIntersection, options),
    []
  );

  const destroyObservable = useCallback(() => {
    if (observable) {
      observable.forEach((node) => {
        observer.unobserve(node);
      });
    }
  }, [observable]);

  const resetObserver = useCallback((newObservable) => {
    destroyObservable();
    setObservable(newObservable);
  }, []);

  useEffect(() => {
    if (observable) {
      if (Array.isArray(observable)) {
        observable.forEach((item) => {
          observer.observe(item);
        });
      } else observer.observe(observable);
    }
  }, [observable]);

  useEffect(() => {
    return () => {
      observer.disconnect();
      observer = null;
    };
  }, []);

  return {
    setObservable,
    destroyObservable,
    resetObserver,
  };
}
