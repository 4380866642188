import { useContext } from 'react';
import { AccountContext } from '../../../../context/Account';
import useWindowSize from '../../../../hooks/useResize';
import Button from '../../../Button/Button';
import MultipleSkeleton from '../../../Skeleton/Multiple';
import utilities from '../../../../utilities';
import TextSkeleton from '../../../Skeleton/Text';
import Title from '../../../Title/Title';

const INFO_TEXT = {
  // card: '№ карты лояльности:',
  phone: `${utilities.translate('Телефон')}:`,
  email: 'Email:',
};

export default function Info(props) {
  const {
    state: [, setFormActive],
  } = props;
  const { account } = useContext(AccountContext);
  const { isPhone } = useWindowSize();

  if (!account) {
    return (
      <>
        <TextSkeleton
          style={{ marginBottom: '15px' }}
          width={300}
          height={50}
          label={`${utilities.translate('Имя')}:`}
        />
        <MultipleSkeleton
          style={{ marginBottom: '15px' }}
          width={300}
          gap={15}
          count={4}
          height={20}
          size="downgrade"
          downgradeStep={40}
        />
      </>
    );
  }
  return (
    <>
      <Title className="account--head" tag="h1">
        {[account.first_name, account.last_name]
          .filter((name) => name)
          .join(' ')}
      </Title>
      <div className="account--info">
        {account.phone && (
          <div className="account--info-wrapper">
            <span className="account--info-termin">{`${utilities.translate(
              'Телефон'
            )}:`}</span>
            <span className="account--info-description">{account.phone}</span>
          </div>
        )}
        {account.email && (
          <div className="account--info-wrapper">
            <span className="account--info-termin">{INFO_TEXT.email}</span>
            <span className="account--info-description">{account.email}</span>
          </div>
        )}
      </div>
      <Button
        size={!isPhone ? 'medium' : 'full'}
        align="start"
        onClick={() => setFormActive(true)}
      >
        {utilities.translate('Изменить')}
      </Button>
      {account?.discount && (
        <div className="account__discount">
          <div className="account__discount-value">{account.discount}%</div>
          <div className="account__discount-text">
            {utilities.translate('Cкидка для вас с&nbsp;каждого заказа')}
          </div>
        </div>
      )}
    </>
  );
}
