/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import AppPlate from '../components/AppPlate';
import BasketList from '../components/Basket/List';
import OrderForm from '../components/Basket/OrderForm';
import BasketRecommended from '../components/Basket/Recommended';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Section from '../components/Section/Section';
import { AccountContext } from '../context/Account';
import { OrderContextProvider } from '../context/Basket/Order';
import { BasketRecommendedContextProvider } from '../context/Basket/Recommended';
import { GlobalBasketContext } from '../context/GlobalBasketContext';
import useLocalStorageChange from '../hooks/useLocalStorageChange';
import routes from '../routes';
import utilities from '../utilities';
import callGtmEvent from '../utilities/gtm';

export default function Basket() {
  const { account } = useContext(AccountContext);
  const shoppingCard = useLocalStorageChange({
    field: utilities.basket.SHOPPING_CARD_KEY,
  });
  const { id: shoppingCardId, items, basketDishes } = useContext(
    GlobalBasketContext
  );

  const viewCartSent = useRef(false);

  useEffect(() => {
    const sendViewCartEvent = async () => {
      const categories = items.map(({ category_id }) => category_id);
      const categoryNames = await Promise.all(
        categories.map((id) =>
          utilities.api.get(routes.apiSwagger.dishCategoryInfo(id))
        )
      ).then((categoriesInfos) => {
        return categoriesInfos.reduce((acc, { id, name }) => {
          acc[id] = name;
          return acc;
        }, {});
      });
      viewCartSent.current = true;
      callGtmEvent(
        'view_cart',
        items.map((item) => ({
          item_name: item.title,
          item_id: item.item_id,
          price: item.price,
          currency: 'RUB',
          quantity: item.count,
          item_category: categoryNames[item.category_id],
        }))
      );
    };

    if (!viewCartSent.current && items.length > 0) {
      sendViewCartEvent();
    }
  }, [items]);

  return (
    <>
      <Breadcrumbs />
      <OrderContextProvider>
        <Section className="basket">
          <Section.Title>{utilities.translate('Ваш заказ')}</Section.Title>
          <BasketList />
        </Section>
        <BasketRecommendedContextProvider>
          <Section className="basket-recomended">
            <BasketRecommended />
          </Section>
        </BasketRecommendedContextProvider>
        {shoppingCard &&
          (utilities.storage.get('madyar-auth-token') ? (
            account && shoppingCardId && <OrderForm />
          ) : (
            <OrderForm />
          ))}
      </OrderContextProvider>
      <AppPlate />
    </>
  );
}
