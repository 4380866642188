/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { EstablishmentsContext } from '../../../context/Establishments';
import useWindowSize from '../../../hooks/useResize';
import routes from '../../../routes';
import utilities from '../../../utilities';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import Description from '../../Description/Description';
import Section from '../../Section/Section';
import Share from '../../Share/Share';
import BlockSkeleton from '../../Skeleton/Block';
import GridSkeleton from '../../Skeleton/Grid';
import MultipleSkeleton from '../../Skeleton/Multiple';
import TextSkeleton from '../../Skeleton/Text';
import Title from '../../Title/Title';
import Slider from './Slider';
import Button from '../../Button/Button';

const Head = () => {
  const { eventId } = useParams();
  const [eventItem, setEvent] = useState(null);
  const [currentEstablishment, setCurrentEstablishment] = useState();
  const establishments = useContext(EstablishmentsContext);

  const { isDesktop } = useWindowSize();

  useEffect(() => {
    const fetchEvent = async () => {
      const response = await utilities.api.get(routes.api.events(eventId));
      setEvent(response.item);
    };
    fetchEvent();
  }, []);

  useEffect(() => {
    if (establishments && eventItem) {
      setCurrentEstablishment(
        establishments.find(
          ({ id }) => id.toString() === eventItem.establishment_id
        )
      );
    }
  }, [establishments, eventItem]);

  return (
    <>
      <Helmet>
        <title>{eventItem?.title || 'Madyar'}</title>
        <meta name="title" content={eventItem?.title || ''} />
        <meta name="description" content={eventItem?.description || ''} />
      </Helmet>
      <Breadcrumbs
        exact={{
          [`${eventId}`]: eventItem?.title,
        }}
      />
      <Section className="event">
        {currentEstablishment ? (
          <img
            className="event__logo"
            src={currentEstablishment.logo}
            alt={currentEstablishment.name}
          />
        ) : (
          <BlockSkeleton
            width={isDesktop ? 120 : 85}
            height={isDesktop ? 120 : 85}
            style={
              isDesktop ? { margin: '30px auto' } : { margin: '25px auto' }
            }
          />
        )}
        {eventItem ? (
          <Title className="event__title" tag="h1" align="center">
            {eventItem.title}
          </Title>
        ) : (
          <TextSkeleton
            style={
              isDesktop ? { marginBottom: '30px' } : { marginBottom: '20px' }
            }
            width={isDesktop ? 400 : 150}
            height={isDesktop ? 70 : 50}
            label="Имя"
          />
        )}
        <div className="event__address">
          {currentEstablishment?.branches[0]?.address?.street}
        </div>
        <div className="event__info">
          {eventItem ? (
            <>
              <span className="event__feature">
                {utilities.date.toString(eventItem.date, {
                  format: 'YYYY-MM-DD HH:mm:ss',
                })}
              </span>
              <span className="event__divisor" />
              <span className="event__feature">
                {eventItem.price
                  ? utilities.currency.toString(eventItem.price || 0)
                  : utilities.translate('Бесплатно')}
              </span>
            </>
          ) : (
            <GridSkeleton
              count={2}
              columns={2}
              gap={isDesktop ? 20 : 10}
              proportion={isDesktop ? 0.4 : 0.3}
              label="Загрузка истории"
            />
          )}
        </div>
        {currentEstablishment ? (
          <a
            className="event__button button button--medium"
            href={`tel:${currentEstablishment?.phone}`}
          >
            {utilities.translate('Забронировать стол')}
          </a>
        ) : (
          <BlockSkeleton
            width={250}
            height={40}
            style={{ margin: '0 0 25px' }}
          />
        )}
        <Slider images={eventItem} />
        {eventItem ? (
          <Description
            className="event__description"
            align="center"
            color="black"
          >
            {eventItem.description}
          </Description>
        ) : (
          <MultipleSkeleton
            style={{ marginBottom: '15px' }}
            width={isDesktop ? 600 : 250}
            gap={15}
            count={4}
            height={20}
            size="downgrade"
            downgradeStep={40}
          />
        )}
        <Share className="event__socials" />
      </Section>
    </>
  );
};

export default Head;
