import { useContext } from 'react';
import { LocationsContext } from '../../../../context/Bindings/Locations';
import Empty from '../../../Empty/Empty';
import BlockSkeleton from '../../../Skeleton/Block';
import LocationsList from './List';
import AddLocation from './AddLocation';
import utilities from '../../../../utilities';

export default function Locations() {
  const [locations] = useContext(LocationsContext);

  if (!locations) {
    return (
      <BlockSkeleton
        count={2}
        gap={8}
        width="100%"
        height={60}
        label="Загрузка адресов"
      />
    );
  }

  if (locations.count === 0) {
    return (
      <>
        <Empty>{utilities.translate('Нет адресов')}</Empty>
        <AddLocation className="empty--button" />
      </>
    );
  }

  return <LocationsList />;
}
