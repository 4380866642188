/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { useContext } from 'react';
import { GlobalBasketContext } from '../../../context/GlobalBasketContext';
import Button from '../../Button/Button';
import utilities from '../../../utilities';

export default function RecommendedAction({ dish }) {
  const { update, basketDishes } = useContext(GlobalBasketContext);
  const toggleAdded = () => {
    update({ dish_id: dish.id, ...dish });
  };

  return (
    <Button onClick={toggleAdded} className="recommended__add">
      {basketDishes && basketDishes[dish.id]
        ? utilities.translate('Добавить еще')
        : utilities.translate('Добавить')}
    </Button>
  );
}
