/* eslint-disable no-unused-vars */
import { useRef, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EstablishmentsContext } from '../../context/Establishments';
import useWindowSize from '../../hooks/useResize';
import routes from '../../routes';
import utilities from '../../utilities';
import EventTeaser from '../EventTeaser/EventTeaser';
import Section from '../Section/Section';
import BlockSkeleton from '../Skeleton/Block';
import Slider from '../Slider';

export default function EventSlider() {
  const [newsItems, setNewsItems] = useState(null);
  const establishments = useContext(EstablishmentsContext);
  const { isPhone } = useWindowSize();
  const ref = useRef();
  const { newsId } = useParams();
  const [intermediateData, setIntermediateData] = useState(null);

  useEffect(() => {
    const fetchNewsTeasers = async () => {
      const response = await utilities.api.get(routes.api.news());
      setIntermediateData(
        response.news.filter(({ id }) => id.toString() !== newsId).slice(0, 4)
      );
    };
    fetchNewsTeasers();
  }, []);

  useEffect(() => {
    if (establishments && intermediateData) {
      setNewsItems(
        intermediateData.map((item) => {
          if (item.establishment_id) {
            const currentEstablishment = establishments.find(
              ({ id }) => id.toString() === item.establishment_id.toString()
            );
            return {
              ...item,
              logo: currentEstablishment.logo,
            };
          }
          return item;
        })
      );
    }
  }, [establishments, intermediateData]);

  return (
    <Section
      sectionRef={(element) => {
        ref.current = element;
      }}
    >
      <Section.Title>{utilities.translate('Другие новости')}</Section.Title>
      {newsItems?.length > 0 ? (
        <Slider
          slidesPerView={isPhone ? 1 : 2}
          slideClassName="event-teaser__slide"
          elements={newsItems}
          shift
          centeredSlides={false}
          slidesOffsetAfter={ref.current.clientWidth / 2 + 15}
          scrollbarShrinked
          spaceBetween={15}
          scrollbarShift
          slide={EventTeaser}
        />
      ) : (
        <BlockSkeleton
          width="100%"
          className="section"
          height={500}
          style={{ marginTop: '35px', marginBottom: '20px' }}
        />
      )}
    </Section>
  );
}
