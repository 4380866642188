import ContentLoader from 'react-content-loader';

export default function TextSkeleton(props) {
  const { className, style, width, height, label } = props;
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      title={label || 'Загрузка...'}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className={className}
      style={style}
    >
      <rect x="0" y="0" width={width} height={height} />
    </ContentLoader>
  );
}
