import ContentLoader from 'react-content-loader';

export default function MultipleSkeleton(props) {
  const {
    className,
    style,
    downgradeStep,
    size,
    width,
    height,
    gap,
    label,
    count,
  } = props;

  const generateWidth = (index) => {
    if (size === 'downgrade') {
      return width - downgradeStep * index;
    }
    return width;
  };

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={count * height + gap * (count - 1)}
      title={label || 'Загрузка...'}
      viewBox={`0 0 ${width} ${count * height + gap * (count - 1)}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className={className}
      style={style}
    >
      {Array.from(Array(count)).map((_, index) => (
        <rect
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          x="0"
          y={index * (height + gap)}
          width={generateWidth(index)}
          height={height}
        />
      ))}
    </ContentLoader>
  );
}
