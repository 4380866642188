/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect, useCallback } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import utilities from '../../../../../utilities';
import Select from '../../../../UI/Form/Select';

const TOTAL_DAYS = 30;

export default function DeliveryTimeDays({ establishment }) {
  const { control, watch, setValue } = useFormContext();
  const today = useRef(new Date());
  const deliveryTypeWatcher = watch('delivery_type');
  const firstMount = useRef(true);

  const generateLabel = (index, date) => {
    if (index === 0) return utilities.translate('Сегодня');
    if (index === 1) return utilities.translate('Завтра');
    return utilities.date.format(date, 'DD.MM');
  };

  const generateDayObject = (index, date) => {
    const day = utilities.date.getDay(date.getDay(), 'en');
    if (establishment.work_hours[day]) {
      return {
        label: generateLabel(index, date),
        value: date.toISOString(),
      };
    }
    return null;
  };

  const shouldAddDelivery = () => {
    return deliveryTypeWatcher === '0';
  };

  const generateDay = useCallback((index, date) => {
    if (index === 0) {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const [
        estimateCookingHours,
        estimateCookingMinutes,
      ] = establishment.estimated_cooking_time.split(':');
      const [estimateDeliveryHours, estimateDeliveryMinutes] = [0, 0];

      const newDate = new Date(date);
      newDate.setHours(
        hours +
          parseInt(estimateCookingHours, 10) +
          (shouldAddDelivery() ? parseInt(estimateDeliveryHours, 10) : 0)
      );
      newDate.setMinutes(
        minutes +
          parseInt(estimateCookingMinutes, 10) +
          (shouldAddDelivery() ? parseInt(estimateDeliveryMinutes, 10) : 0)
      );
      const lastDelivery = new Date();
      lastDelivery.setHours(establishment.end_delivery_time.split(':')[0]);
      lastDelivery.setMinutes(establishment.end_delivery_time.split(':')[1]);
      if (lastDelivery < newDate) {
        return null;
      }
      return generateDayObject(index, date);
    }
    return generateDayObject(index, date);
  }, []);

  const generateOptions = () => {
    const date = today.current;
    return [...Array(TOTAL_DAYS)]
      .map((_, index) => {
        const day = generateDay(index, date);
        date.setDate(today.current.getDate() + 1);
        return day;
      })
      .filter((item) => item);
  };

  const [options, setOptions] = useState(generateOptions);

  useEffect(() => {
    if (!firstMount.current) {
      today.current = new Date();
      setOptions(generateOptions());
    }
  }, [deliveryTypeWatcher]);

  useEffect(() => {
    firstMount.current = false;
    setValue('helpers_timedays', options[0].value);

    return () => {
      firstMount.current = true;
    };
  }, []);

  return (
    <Controller
      control={control}
      name="helpers_timedays"
      render={({ field }) => (
        <Select
          className="delivery-day-select"
          name="delivery_day"
          options={options}
          onChange={({ value }) => {
            field.onChange(value);
          }}
        />
      )}
    />
  );
}
