import AccountCard from '../components/Account/Card';
import Orders from '../components/Account/Orders';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Section from '../components/Section/Section';
import AccountBindings from '../components/Account/Bindings';
import AppPlate from '../components/AppPlate';
import utilities from '../utilities';
import { AccountHistoryProvider } from '../context/Account/History';
import routes from '../routes';

export default function Account() {
  return (
    <>
      <Breadcrumbs />
      <Section className="account">
        <AccountCard />
      </Section>
      <Section className="history">
        <Section.Title>{utilities.translate('История заказа')}</Section.Title>
        <AccountHistoryProvider url={routes.apiSwagger.orders()} limit={3}>
          <Orders />
        </AccountHistoryProvider>
      </Section>
      <Section className="bindings">
        <AccountBindings />
      </Section>
      <AppPlate />
    </>
  );
}
