/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { DevTool } from '@hookform/devtools';
import axios from 'axios';
import { useContext, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthContext } from '../../../context/Auth';
import routes from '../../../routes';
import utilities from '../../../utilities';
import Button from '../../Button/Button';
import Title from '../../Title/Title';
import FormField from '../../UI/Form/Field';
import Phone from '../../UI/Form/Phone';

export default function PhoneForm() {
  const [captchaValue, setCaptchaValue] = useState(null);
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const {
    step: [, setStep],
    data: [, setData],
  } = useContext(AuthContext);
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = async (values) => {
    const formValues = { ...values, recaptcha: captchaValue };
    const response = await utilities.api.postMobile(
      routes.apiSwagger.authCode(),
      formValues
    );
    if (!response.error) {
      setData({
        phone: values.phone,
        code: response.code,
      });
      setStep((prev) => prev + 1);
    } else if (response.validation_error) {
      utilities.errors(setError, response.validation_error);
    }
  };

  function onChange(value) {
    setCaptchaValue(value);
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="auth-form">
        <Title
          dangerHTML={utilities.translate('Введите номер&nbsp;телефона')}
        />
        <FormField
          control={methods.control}
          name="phone"
          error={errors.phone}
          required
          component={
            <Controller
              control={control}
              name="phone"
              rules={{
                required: utilities.translate(
                  'Поле обязательно для заполнения'
                ),
                validate: (value) => {
                  return (
                    value.replace(/\D/g, '').length === 10 ||
                    utilities.translate('Ошибка! Заполните поле')
                  );
                },
                minLength: {
                  value: 10,
                  message: utilities.translate('Ошибка! Заполните поле'),
                },
              }}
              render={({ field, fieldState }) => (
                <Phone {...field} fieldState={fieldState} />
              )}
            />
          }
        />
        <ReCAPTCHA
          sitekey="6LeZxlcfAAAAAOaMa2cMKgnLpJSDeIeybEkT21cF"
          onChange={onChange}
        />
        <Button captcha type="submit" disabled={isSubmitting || !captchaValue}>
          {utilities.translate('Получить код')}
        </Button>
        <DevTool control={control} />
      </form>
    </FormProvider>
  );
}
