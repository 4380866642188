import utilities from '../../../utilities';
import LazyLoad from '../../LazyLoadImage';
import RecommendedAction from './RecommendedAction';

export default function RecommendedSlider({ data }) {
  return (
    <>
      <h2 className="recommended__title">{data.name}</h2>
      <LazyLoad
        className="recommended__image"
        holder
        src={data.image}
        alt={data.name}
      />
      {/* <div className="recommended__image-holder">
        <img className="recommended__image" src={data.image} alt={data.name} />
      </div> */}
      <div className="recommended__desc">{data.description}</div>
      <div className="recommended__weight">{data.weight}</div>
      <div className="recommended__price">
        {utilities.currency.toString(data.price)}
      </div>
      <RecommendedAction dish={data} />
    </>
  );
}
