/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Mousewheel,
  Controller,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import cn from 'classnames';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useIntersection from '../../hooks/useIntersectionObserver';

SwiperCore.use([
  Controller,
  Mousewheel,
  Scrollbar,
  Pagination,
  Navigation,
  Autoplay,
]);

const Slider = ({
  onSlideChange,
  slideClassName,
  elements,
  breakpoints = {},
  slide: SlideComponent,
  gap = false,
  slidesPerView = 'auto',
  className,
  onSwiper,
  scrollbar = { draggable: true },
  allowTouchMove = true,
  spaceBetween = 0,
  nested = false,
  navigation = false,
  autoplay = {
    delay: 5000,
  },
  slidesOffsetAfter = 0,
  direction = 'horizontal',
  nogap,
  shift = false,
  reduced = false,
  scrollbarShrinked = false,
  scrollbarShift = false,
  initialSlide = 0,
  centeredSlides = false,
  pagination = false,
  slideProps = {},
  observable,
  slideInlineStyles = null,
  preloadImages = false,
  virtualTranslate = false,
  sliderRef,
  scrollbarReduced = false,
  mousewheel = false,
  loop = false,
  freeMode = false,
  ...rest
}) => {
  const [isVisible, setVisible] = useState(!observable);
  const [observableFinished, setObservableFinished] = useState(false);
  const swiperRef = useRef();
  const classes = cn('slider', className, {
    'slider--gap-section': gap,
    'slider--gapless': nogap,
    'slider--observable': observable,
    'slider--visible': observable ? isVisible : false,
    'slider--observable-finished': observableFinished,
    'slider--shift': shift,
    'slider--reduced': reduced,
    'slider--scrollbar-shrinked': scrollbarShrinked,
    'slider--scrollbar-reduced': scrollbarReduced,
    'slider--scrollbar-shift': scrollbarShift,
  });
  const [controlledSwiper, setControlledSwiper] = useState(null);
  let onSwiperTriggered = false;

  const onIntersection = ([entry]) => {
    if (entry.isIntersecting) setVisible(true);
  };

  const { setObservable, destroyObservable } = useIntersection({
    callback: onIntersection,
    triggerOnce: true,
  });

  const onTransitionEnd = (event) => {
    if (event.target === swiperRef.current) {
      event.target.removeEventListener('transitionend', onTransitionEnd);
      setObservableFinished(true);
    }
  };

  const onPageLeave = () => {
    setVisible(false);
  };

  useEffect(() => {
    destroyObservable();
  }, []);

  useLayoutEffect(() => {
    if (sliderRef) sliderRef(swiperRef.current);
    if (observable) {
      swiperRef.current.addEventListener('transitionend', onTransitionEnd);
      setObservable(swiperRef.current);
    }
  }, []);

  useEffect(() => {
    if (observable) {
      document.addEventListener('page:leave', onPageLeave);
    }
    return () => {
      if (observable) {
        document.removeEventListener('page:leave', onPageLeave);
        document.removeEventListener('transitionend', onTransitionEnd);
      }
      swiperRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (controlledSwiper) {
      if (onSwiper && !onSwiperTriggered) {
        onSwiper(controlledSwiper);
        onSwiperTriggered = true;
      }
    }

    return () => {
      onSwiperTriggered = false;
    };
  }, [controlledSwiper]);

  return (
    <Swiper
      ref={swiperRef}
      className={classes}
      nested={nested}
      navigation={navigation}
      loop={loop}
      slideToClickedSlide
      initialSlide={initialSlide}
      loopedSlides={2}
      threshold={5}
      speed={3000}
      virtualTranslate={virtualTranslate}
      observer
      controller={{ control: controlledSwiper }}
      onSwiper={setControlledSwiper}
      watchOverflow
      observeParents
      freeMode={freeMode}
      allowTouchMove={allowTouchMove}
      mousewheel={mousewheel}
      noSwipingSelector="button"
      resistance={direction !== 'vertical'}
      resistanceRatio={direction === 'horizontal' ? 0 : 1}
      direction={direction}
      preloadImages={preloadImages}
      grabCursor
      autoplay={autoplay}
      breakpoints={breakpoints}
      spaceBetween={spaceBetween}
      onSlideChange={onSlideChange || (() => null)}
      scrollbar={scrollbar}
      slidesPerView={slidesPerView}
      centeredSlides={centeredSlides}
      slidesOffsetAfter={slidesOffsetAfter}
      // centeredSlidesBounds
      pagination={pagination ? { clickable: true, ...pagination } : false}
      {...rest}
    >
      {elements.map((element, index) => (
        <SwiperSlide
          // style={slideInlineStyles ? slideInlineStyles({ index }) : null}
          className={slideClassName}
          key={element.id || Math.random().toString(16)}
        >
          {({ isActive }) => (
            <SlideComponent
              isActive={isActive}
              {...slideProps}
              index={index}
              data={element}
            />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
