import React from 'react';

export default function ArrowMobile({ className }) {
  return (
    <svg
      className={className}
      width="63"
      height="10"
      viewBox="0 0 63 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 5H61.5546" stroke="#101820" />
      <path d="M56.316 1L61.9992 5L56.316 9" stroke="#101820" />
    </svg>
  );
}
