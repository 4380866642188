import React from 'react';
import ActionButtons from './ActionButtons';
import CancelOrder from './CancelOrder';
import Rate from '../Rate';

export default function OrderActions(props) {
  const { status, id, rate, is_repeatable: isRepeatable } = props;

  if ((status === 8 || status === 9) && isRepeatable) {
    return (
      <>
        <ActionButtons {...props} />
        {!rate && <Rate id={id} />}
      </>
    );
  }

  if (status === 0) {
    return <CancelOrder id={id} />;
  }
  return null;
}
