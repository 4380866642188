import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const WithHolder = ({ children, className }) => {
  return <div className={`${className}-holder`}>{children}</div>;
};

export default function Lazyload({
  width = 300,
  height = 300,
  src,
  className,
  holder = false,
  alt = '',
}) {
  if (holder) {
    return (
      <WithHolder className={className}>
        <LazyLoadImage
          className={className}
          width={width}
          alt={alt}
          title={alt}
          delayTime={5000}
          threshold={-50}
          height={height}
          src={src}
        />
      </WithHolder>
    );
  }
  return (
    <LazyLoadImage
      className={className}
      width={width}
      alt={alt}
      title={alt}
      delayTime={5000}
      threshold={-50}
      height={height}
      src={src}
    />
  );
}
