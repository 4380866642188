// @ts-check
import cn from 'classnames';
import utilities from '../../utilities';

const Share = (props) => {
  const { className } = props;
  const url = window.location.href;
  const socialsClassName = cn('socials', className);

  return (
    <ul
      className={socialsClassName}
      data-label={`${utilities.translate('Поделиться')}:`}
    >
      <li className="socials__item">
        <a
          className="socials__link"
          href={`https://facebook.com/sharer/sharer.php?u=${url}`}
          target="_blank"
          rel="noreferrer"
        >
          Facebook
        </a>
      </li>
      <li className="socials__item">
        <a
          className="socials__link"
          href={`http://vk.com/share.php?url=${url}`}
          target="_blank"
          rel="noreferrer"
        >
          {utilities.translate('ВКонтакте')}
        </a>
      </li>
    </ul>
  );
};

export default Share;
