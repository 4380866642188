import InfoHolder from './Info';
import Logout from '../Logout/Logout';

export default function Card() {
  return (
    <>
      <InfoHolder />
      <Logout />
    </>
  );
}
