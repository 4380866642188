/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Fragment, useRef, useEffect, useState } from 'react';
import cn from 'classnames';

export default function Select({
  size = 'normal',
  options,
  large,
  className,
  multiple = false,
  onChange,
  border = true,
  name,
  changeSelected,
  triggerOnControllingChange = true,
  // controlledValue,
  controlledSelect = false,
  controllingValue, // controllingValue has to be momoized if used as defaultValue
  triggerChangeOnFirstRender = false,
  fullWidth = false,
  keyAsKey = false,
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(controllingValue || options[0]);
  const controllingChanged = useRef(false);
  const inputRefs = useRef([]);
  const firstMount = useRef(true);
  const selectClasses = cn('select-interactive', className, {
    'select-interactive--large': large,
    'select-interactive--open': open,
    'select-interactive--border': border,
    'select-interactive--fullWidth': fullWidth,
    'select-interactive--filled': value?.value,
    [`select-interactive--${size}`]: size !== 'none' && size,
  });

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const onDocumentClick = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    if (!firstMount.current) {
      const selected = options.find(
        ({ value: selectedValue }) =>
          selectedValue.toString() === event.target.value.toString()
      );
      if (triggerChangeOnFirstRender && !controlledSelect) {
        setValue(selected);
      } else if (controlledSelect) {
        if (onChange) onChange(selected);
        if (!multiple) setOpen(false);
      } else {
        setValue(selected);
      }
    }
  };

  useEffect(() => {
    if (!open) {
      inputRefs.current = [];
    }
  }, [open]);

  useEffect(() => {
    if (!firstMount.current && typeof controllingValue !== 'undefined') {
      controllingChanged.current = true;
      setValue(controllingValue);
    }
  }, [controllingValue]);

  useEffect(() => {
    if (changeSelected && !firstMount.current && inputRefs.current[0]) {
      inputRefs.current[0].checked = true;
    }
    if (
      !firstMount.current &&
      inputRefs.current[0] &&
      !controlledSelect &&
      options?.length &&
      !options?.some(({ value: optionValue }) => optionValue === value?.value)
    ) {
      inputRefs.current[0].checked = true;
      setValue(options[0]);
    }
  }, [options]);

  useEffect(() => {
    if (controlledSelect) {
      setValue(controllingValue);
    }
  }, [controllingValue]);

  useEffect(() => {
    if (
      !firstMount.current ||
      (triggerChangeOnFirstRender && !controlledSelect)
    ) {
      if (onChange) {
        if (controllingChanged.current) {
          if (triggerOnControllingChange) {
            onChange(value);
          }
          controllingChanged.current = false;
        } else {
          onChange(value);
        }
      }
      if (!multiple) setOpen(false);
    }
  }, [value]);

  useEffect(() => {
    document.addEventListener('click', onDocumentClick);
    firstMount.current = false;
    return () => {
      document.removeEventListener('click', onDocumentClick);
      firstMount.current = true;
      inputRefs.current = [];
    };
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={(event) => {
        event.stopPropagation();
        if (event.target.value === value.value.toString()) {
          setOpen(false);
        }
      }}
      onChange={handleChange}
      className={selectClasses}
    >
      <button onClick={toggleOpen} className="select-toggle" type="button">
        {value?.label}
      </button>
      <div className="select-dropdown">
        {options?.length > 0
          ? options.map((option, index) => (
              <Fragment key={keyAsKey ? option.key : option.value}>
                <input
                  type={multiple ? 'checkbox' : 'radio'}
                  value={option.value}
                  hidden
                  ref={(element) => {
                    inputRefs.current[index] = element;
                  }}
                  checked={value?.value === option?.value}
                  name={name}
                  onChange={() => null}
                  id={`${name}-${index}`}
                />
                <label
                  className="select-dropdown__item"
                  htmlFor={`${name}-${index}`}
                >
                  {option.label}
                </label>
              </Fragment>
            ))
          : null}
      </div>
    </div>
  );
}
