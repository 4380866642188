import React, { useLayoutEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

export default function Test({
  path,
  render,
  component: Component,
  match,
  firstMount,
}) {
  const ref = useRef();
  const currentPath = useRef();

  useLayoutEffect(() => {
    if (match && !currentPath.current && !firstMount) {
      currentPath.current = path;
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 500);
    } else if (!match) {
      currentPath.current = null;
    }
  }, [match]);
  return (
    <CSSTransition
      classNames="main"
      in={Boolean(match)}
      nodeRef={ref}
      timeout={500}
      mountOnEnter
      unmountOnExit
    >
      <main ref={ref} className="main">
        {render ? render() : <Component />}
      </main>
    </CSSTransition>
  );
}
