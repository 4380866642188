import React, { useCallback, useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { EstablishmentsContext } from '../../context/Establishments';
import useWindowSize from '../../hooks/useResize';
import routes from '../../routes';
import utilities from '../../utilities';
import Button from '../Button/Button';

export default function HeaderNavigation() {
  const [establishmentId, setEstablishmentId] = useState(false);
  const establishments = useContext(EstablishmentsContext);
  const [currentEstablishment, setCurrentEstablishment] = useState(null);
  const { isDesktop } = useWindowSize();

  const onEstablishmentHeaderShow = useCallback(
    (event) => {
      setEstablishmentId(event.detail);
    },
    [establishments]
  );

  const onEstablishmentHeaderHide = () => {
    setEstablishmentId(null);
  };

  useEffect(() => {
    if (establishments && establishmentId) {
      setCurrentEstablishment(
        establishments.find(({ id }) => id.toString() === establishmentId)
      );
    }
  }, [establishmentId]);

  useEffect(() => {
    window.addEventListener(
      'showEstablishmentHeader',
      onEstablishmentHeaderShow
    );
    window.addEventListener(
      'hideEstablishmentHeader',
      onEstablishmentHeaderHide
    );

    return () => {
      window.removeEventListener(
        'showEstablishmentHeader',
        onEstablishmentHeaderShow
      );
      window.removeEventListener(
        'hideEstablishmentHeader',
        onEstablishmentHeaderHide
      );
    };
  }, []);
  return establishmentId ? (
    <>
      <Button
        className="header__button header__restaurant-button"
        size="medium"
        to={routes.page.dishes(establishmentId)}
      >
        {utilities.translate('Меню')}
      </Button>
      {currentEstablishment && (
        <img
          src={currentEstablishment.logo}
          alt={currentEstablishment.name}
          className="header__establishment-logo"
        />
      )}
      <Button
        className="header__button header__restaurant-button"
        size="medium"
        to={routes.page.restaurants()}
        linkProps={{
          style: { margin: '0 auto 0 0' },
        }}
      >
        {utilities.translate('Заказать доставку')}
      </Button>
    </>
  ) : (
    <>
      {isDesktop ? (
        <Button
          className="header__button"
          size="medium"
          linkProps={{
            activeClassName: 'header__button--active',
            isActive: (match, location) => {
              return location?.hash !== '#delivery';
            },
          }}
          to={routes.page.deliveringRestaurants()}
        >
          {utilities.translate('Доставка еды')}
        </Button>
      ) : (
        <NavLink
          className="header__delivery-button delivery-button"
          to={routes.page.deliveringRestaurants()}
        >
          {utilities.translate('Доставка еды')}
        </NavLink>
      )}
    </>
  );
}
