/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { useFormContext } from 'react-hook-form';
import useLocalStorageChange from '../../../hooks/useLocalStorageChange';
import utilities from '../../../utilities';
import Button from '../../Button/Button';
import FinalPrice from './FinalPrice';
import Promo from './Promo';

export default function BasketRegister() {
  const { watch } = useFormContext();
  const shoppingCard = useLocalStorageChange({
    field: utilities.basket.SHOPPING_CARD_KEY,
  });
  const deliveryWatcher = watch('delivery_type');

  const pointExist = watch('point');

  return (
    <>
      <div className="basket-register__holder">
        {shoppingCard && (
          <>
            {deliveryWatcher === '0' && <Promo />}
            <FinalPrice />
            <Button size="large" type="submit" disabled={!pointExist}>
              {utilities.translate('Оформить заказ')}
            </Button>
          </>
        )}
      </div>
    </>
  );
}
