import { useRef, useLayoutEffect, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as MadyarLogoIcon } from '../../icons/madyar-logo2.svg';
import useScroll from '../../hooks/useScrollDirection';

const Circle = () => {
  const location = useLocation();
  const { scrollPosition } = useScroll();
  const circle = useRef();
  const rotatingParts = useRef();
  const firstMount = useRef(true);

  const setRotate = (element, scrollValue) => {
    element.setAttribute('style', `transform: rotate(${scrollValue}deg)`);
  };

  const rotateCircle = (elements, scroll) => {
    const scrollValue = Math.round(scroll / 3);
    elements.forEach((element) => setRotate(element, scrollValue));
  };

  const onMenuClose = () => {
    rotateCircle(rotatingParts.current, window.scrollY);
  };

  const onMenuOpen = () => {
    rotatingParts.current.forEach((element) => setRotate(element, 0));
  };

  const onCircleHide = () => {
    circle.current.classList.add('circle-is-hidden');
  };

  const onCircleShow = () => {
    if (circle.current) {
      circle.current.classList.remove('circle-is-hidden');
    }
  };

  useEffect(() => {
    if (!firstMount.current) onCircleShow();
  }, [location]);

  useLayoutEffect(() => {
    firstMount.current = false;
    rotatingParts.current = [...document.querySelectorAll('.circle__rotate')];
    window.addEventListener('openMenu', onMenuOpen);
    window.addEventListener('closeMenu', onMenuClose);
    document.addEventListener('circle:hide', onCircleHide);
    document.addEventListener('circle:show', onCircleShow);

    return () => {
      firstMount.current = true;
      document.addEventListener('circle:show', onCircleShow);
      document.removeEventListener('circle:hide', onCircleHide);
      window.removeEventListener('openMenu', onMenuOpen);
      window.removeEventListener('closeMenu', onMenuClose);
    };
  }, []);

  useEffect(() => {
    rotateCircle(rotatingParts.current, scrollPosition);
  }, [scrollPosition, circle]);

  return <MadyarLogoIcon className="circle" ref={circle} />;
};

export default Circle;
