/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import getLang from './lang';
import { translate } from './dictionary';

dayjs.extend(customParseFormat);

const FORMATS = {
  ru: 'DD MMMM, HH:mm',
  en: 'MMM D, YYYY HH:mm',
};

const DAYS = {
  0: 'Воскресенье',
  1: 'Понедельник',
  2: 'Вторник',
  3: 'Среда',
  4: 'Четверг',
  5: 'Пятница',
  6: 'Суббота',
};

const MONTHS = {
  0: 'Январь',
  1: 'Февраль',
  2: 'Март',
  3: 'Апрель',
  4: 'Май',
  5: 'Июнь',
  6: 'Июль',
  7: 'Август',
  8: 'Сентябрь',
  9: 'Октябрь',
  10: 'Ноябрь',
  11: 'Декабрь',
};

const DICTIONARY = {
  Воскресенье: {
    en: 'Sunday',
  },
  Понедельник: {
    en: 'Monday',
  },
  Вторник: {
    en: 'Tuesday',
  },
  Среда: {
    en: 'Wednesday',
  },
  Четверг: {
    en: 'Thursday',
  },
  Пятница: {
    en: 'Friday',
  },
  Суббота: {
    en: 'Saturday',
  },
};

export function toString(date, options = { format: 'DD.MM.YYYY HH:mm:ss' }) {
  return dayjs(date, options.format, getLang()).format(FORMATS[getLang()]);
}

export function getDaysInMonth(month, year) {
  return new Date(year || new Date().getFullYear(), month, 0).getDate();
}

export function getMonthName(monthIndex, lang, options = { capitalize: true }) {
  const date = new Date(new Date().getFullYear(), monthIndex);
  let monthName = date.toLocaleString(lang || getLang(), { month: 'long' });
  if (options.capitalize) {
    monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
  }
  return monthName;
}

export function getMonthNameNative(monthIndex, lang) {
  return new Date(2021, monthIndex).toLocaleString(lang || getLang(), {
    month: 'long',
  });
}

export function getDay(index, lang) {
  const weekDay = DAYS[index];
  if (!weekDay) return translate(DAYS[0], lang || getLang(), DICTIONARY);
  return translate(weekDay, lang || getLang(), DICTIONARY);
}

export function formatToValidDate(date, invalidDateFormat) {
  return dayjs(date, invalidDateFormat).toDate();
}

export function format(date, formatterString) {
  return dayjs(date).format(formatterString);
}
