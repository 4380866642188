/* eslint-disable import/prefer-default-export */
import dictionary from '../configs/dictionary.json';

function hasTranslation(key, language, dictionaryInUse) {
  return dictionaryInUse[key] && dictionaryInUse[key][language];
}

export function translate(key, language, dictionaryInUse = dictionary) {
  if (hasTranslation(key, language, dictionaryInUse)) {
    return dictionaryInUse[key][language];
  }
  return key;
}
