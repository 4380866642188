/* eslint-disable no-unused-vars */
import cn from 'classnames';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { DishesContext } from '../../../context/Dishes';
import useObserver from '../../../hooks/useIntersectionObserver';
import useWindowSize from '../../../hooks/useResize';
import useScrollDirection from '../../../hooks/useScrollDirection';
import GridSkeleton from '../../Skeleton/Grid';
import Checkbox from '../../UI/Form/Checkbox';

export default function Filter() {
  const { dishes, categories, activeCategory, establishment } = useContext(
    DishesContext
  );
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const firstMount = useRef();
  const { direction } = useScrollDirection();
  const filterHolder = useRef();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [isFilterPinned, setFilterPinned] = useState(true);
  const { isDesktop, isPhone } = useWindowSize();

  const onIntersection = ([entry]) => {
    if (entry?.intersectionRatio === 1) {
      setShouldAnimate(false);
    } else if (!shouldAnimate && entry?.intersectionRatio < 0.97) {
      setFilterPinned(false);
      setShouldAnimate(true);
    }
  };

  // const { setObservable, destroyObservable } = useObserver({
  //   callback: onIntersection,
  //   options: {
  //     rootMargin: '-120px 0px 0px 0px',
  //     threshold: 1,
  //   },
  // });

  const filterClassName = cn('filter', {
    'filter--sticky': !isDesktop,
    'filter--pinned': shouldAnimate && isFilterPinned && isDesktop,
    'filter--sticked': shouldAnimate && isDesktop,
  });

  const checkboxRefs = useRef([]);

  useEffect(() => {
    if (shouldAnimate) {
      setFilterPinned(!direction);
    }
  }, [direction]);

  const changeCategory = useCallback(({ slug, id }) => {
    history.push({ ...location, search: `?category=${slug || id}` });
  }, []);

  useEffect(() => {
    if (categories) {
      const category = categories.find(
        (item) => item.id === Number(location.search.replace('?category=', ''))
      );
      if (category) {
        history.push({ ...location, search: `?category=${category.slug}` });
      }
    }
  }, [categories]);

  // useLayoutEffect(() => {
  //   if (!isPhone) {
  //     setObservable(filterHolder.current);
  //   } else {
  //     destroyObservable();
  //   }
  // }, []);

  const shouldTranslateForm = () => {
    return dishes && categories && activeCategory && isPhone;
  };

  useEffect(() => {
    if (!firstMount.current) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [search]);

  useLayoutEffect(() => {
    if (shouldTranslateForm()) {
      const categoryId = activeCategory.id;
      const index = checkboxRefs.current.findIndex(
        (item) => item.input.dataset.target === categoryId.toString()
      );
      filterHolder.current.scrollLeft =
        checkboxRefs.current[index].holder.offsetLeft;
    }
  }, [categories, dishes]);

  useEffect(() => {
    firstMount.current = false;

    return () => {
      firstMount.current = true;
    };
  }, []);

  return (
    <div className={filterClassName} ref={filterHolder}>
      {categories && activeCategory ? (
        <>
          {establishment && (
            <Helmet>
              <title>{`Меню ${establishment.name}: меню ресторана в Краснодаре`}</title>
              <meta
                name="title"
                content={`${activeCategory.name} с доставкой заказать в ${establishment.name}`}
              />
              <meta
                name="description"
                content={`${activeCategory.name} в ${establishment.name} по доступной цене. Великолепный вкус и быстрая доставка.`}
              />
            </Helmet>
          )}
          {categories.map((category, index) => (
            <Checkbox
              type="radio"
              dataset={{
                target: category.id,
              }}
              ref={(element) => {
                checkboxRefs.current[index] = element;
              }}
              name="category"
              onChange={() => changeCategory(category)}
              key={category.id}
              holderClassName="dish-filter__checkbox"
              hidden
              defaultChecked={category.id === activeCategory.id}
              labelClassName="dish-filter__checkbox-label"
              aside={false}
              id={`dish-category-${category.id}`}
              label={category.name}
            />
          ))}
        </>
      ) : (
        <GridSkeleton
          count={8}
          columns={isDesktop ? 4 : 2}
          gap={isDesktop ? 20 : 10}
          proportion={isDesktop ? 0.2 : 0.3}
          label="Загрузка истории"
        />
      )}
    </div>
  );
}
