import Title from '../Title/Title';
import Description from '../Description/Description';
import useWindowSize from '../../hooks/useResize';
import BlockSkeleton from '../Skeleton/Block';
import MultipleSkeleton from '../Skeleton/Multiple';
import Section from '../Section/Section';

export default function Info({ restaurant, branches }) {
  const { isPhone } = useWindowSize();

  return (
    <Section className="main-head">
      {restaurant ? (
        <div className="main-head__title-holder">
          <Title className="main-head__title" tag="h1" size="large">
            {restaurant.name}
          </Title>
          <div className="main-head__branches">
            {branches?.map((branch) => {
              return (
                <ul key={branch.id} className="main-head__list">
                  <li className="main-head__point">{branch.address?.street}</li>
                  <li className="main-head__point">
                    <a href={`mailto:${branch.email}`}>{branch.email}</a>
                    <br />{' '}
                    <a href={`tel:+7${branch.phone}`}>{`+7${branch.phone}`}</a>
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
      ) : (
        <BlockSkeleton
          width={isPhone ? '100%' : 550}
          height={80}
          className="main-head__title"
          label="Заголовок"
        />
      )}
      {restaurant ? (
        <>
          <Description
            className="main-head__description"
            color="black"
            size="large"
            dangerHTML={restaurant.description}
          />
        </>
      ) : (
        <MultipleSkeleton
          width={isPhone ? 250 : 450}
          gap={20}
          count={4}
          height={20}
          size="downgrade"
          downgradeStep={60}
        />
      )}
    </Section>
  );
}
