import { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import utilities from '../../../utilities';

export default function Counter({
  step = 1,
  className,
  onChange,
  initialCount = 1,
}) {
  const [count, setCount] = useState(initialCount);
  const counterClasses = cn('counter', className);
  const changeType = useRef(1);
  const firstMount = useRef(true);

  const decreaseCount = () => {
    setCount((prev) => prev - 1);
    changeType.current = step * -1;
  };

  const increaseCount = () => {
    setCount((prev) => prev + 1);
    changeType.current = step;
  };

  useEffect(() => {
    if (!firstMount.current) {
      if (onChange) onChange(count, changeType.current);
    }
  }, [count]);

  useEffect(() => {
    firstMount.current = false;

    return () => {
      firstMount.current = true;
    };
  }, []);

  return (
    <div className={counterClasses}>
      <div className="counter__holder">
        <button
          onClick={decreaseCount}
          className="counter__decrease"
          disabled={count === 0}
          type="button"
        >
          {utilities.translate('Убавить')}
        </button>
        <span className="counter__count">{count}</span>
        <button
          onClick={increaseCount}
          className="counter__increase"
          type="button"
        >
          {utilities.translate('Добавить')}
        </button>
      </div>
    </div>
  );
}
