/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import routes from '../routes';
import BlockSkeleton from '../components/Skeleton/Block';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Content from '../components/content/Content';
import ContentPreloader from '../components/LegalDocuments/ContentPreloader';
import Section from '../components/Section/Section';
import Select from '../components/UI/Form/Select';
import Title from '../components/Title/Title';
import useWindowSize from '../hooks/useResize';
import utilities from '../utilities';
import PolicyFiles from '../components/PolicyFiles';

const LegalDocuments = () => {
  const location = useLocation();
  const { pathname } = location;
  const slug = useMemo(() => {
    return pathname.split('/')[3];
  }, [pathname]);
  const history = useHistory();
  const [dataValue, setDataValue] = useState(null);
  const [legalDocuments, setLegalDocuments] = useState([]);
  const firstMount = useRef(true);
  const { isDesktop } = useWindowSize();
  const options = useRef();
  const [currentSlug, setCurrentSlug] = useState(slug || null);

  const handleSelectChange = (selected) => {
    history.push(
      `${pathname.substring(0, pathname.lastIndexOf('/') + 1)}${selected.value}`
    );
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      const response = await utilities.api.get(routes.api.legalDocuments());
      if (!slug) {
        history.push(
          `${pathname}/${
            response.documents[0].slug || response.documents[0].id
          }`
        );
      }
      options.current = response.documents.map((doc) => ({
        value: doc.slug || doc.id,
        label: doc.title,
      }));
      setLegalDocuments(response.documents);
      setDataValue({
        value: slug ?? 0,
        label: response.documents[0].title,
      });
    };
    fetchDocuments();
  }, []);

  useEffect(() => {
    if (options.current) {
      setDataValue(options.current.find((option) => option.value === slug));
    }
    setCurrentSlug(slug);
  }, [pathname, legalDocuments]);

  useEffect(() => {
    firstMount.current = false;

    return () => {
      firstMount.current = true;
    };
  }, []);

  return (
    <>
      <Breadcrumbs
        exact={{
          [`${slug}`]: legalDocuments.find(
            (doc) => doc.slug === currentSlug || doc.id === currentSlug
          )?.title,
        }}
      />
      <Section>
        {legalDocuments.length > 0 && (
          <Helmet>
            <title>
              {legalDocuments.find(
                (doc) => doc.slug === currentSlug || doc.id === currentSlug
              )?.meta_title || 'Madyar'}
            </title>
            <meta
              name="title"
              content={
                legalDocuments.find(
                  (doc) => doc.slug === currentSlug || doc.id === currentSlug
                )?.meta_title || ''
              }
            />
            <meta
              name="description"
              content={
                legalDocuments.find(
                  (doc) => doc.slug === currentSlug || doc.id === currentSlug
                )?.meta_description || ''
              }
            />
          </Helmet>
        )}
        <Title tag="h1">
          {utilities.translate('Пользовательское соглашение')}
        </Title>
        {legalDocuments.length > 0 && dataValue ? (
          <Select
            border
            large
            fullWidth
            controllingValue={dataValue}
            controlledSelect
            name="documents"
            changeSelected
            onChange={handleSelectChange}
            options={options.current}
          />
        ) : (
          <BlockSkeleton
            width={isDesktop ? 630 : '100%'}
            height={60}
            style={{ margin: '35px 0 20px' }}
            label="Cелект"
          />
        )}
        {legalDocuments.length > 0 ? (
          <>
            <Content
              dangerHTML={
                legalDocuments.find(
                  (doc) => doc.slug === currentSlug || doc.id === currentSlug
                ).text
              }
            />
            {legalDocuments.find(
              (doc) => doc.slug === currentSlug || doc.id === currentSlug
            ).files && (
              <PolicyFiles
                files={
                  legalDocuments.find(
                    (doc) => doc.slug === currentSlug || doc.id === currentSlug
                  ).files
                }
              />
            )}
          </>
        ) : (
          <ContentPreloader />
        )}
      </Section>
    </>
  );
};

export default LegalDocuments;
