import utilities from '../utilities';

/* eslint-disable no-unused-vars */
const CRUMBS = {
  en: () => utilities.translate('Главная'),
  ru: () => utilities.translate('Главная'),
  restaurants: () => utilities.translate('Рестораны'),
  catering: () => utilities.translate('Кейтеринг'),
  dishes: () => utilities.translate('Меню'),
  events: () => utilities.translate('События'),
  news: () => utilities.translate('Новости'),
  vacancies: () => utilities.translate('Вакансии'),
  team: () => utilities.translate('Команда'),
  collaboration: () => utilities.translate('Сотрудничество'),
  legal_documents: () => utilities.translate('Правовые документы'),
  contacts: () => utilities.translate('Контакты'),
  basket: () => utilities.translate('Корзина'),
  account: () => utilities.translate('Личный кабинет'),
  mobile_application: () => utilities.translate('Мобильное приложение'),
  search: () => utilities.translate('Поиск'),
  history: () => utilities.translate('История заказов'),
  favorite: () => utilities.translate('Избранное'),
  documents: () => utilities.translate('Документы'),
};

export function getCrumbText(value) {
  if (!CRUMBS[value]) return '';
  return CRUMBS[value]();
}

export function getCrumbLink(paths, index) {
  return `/${paths.slice(0, index + 1).join('/')}`;
}
