/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
import cn from 'classnames';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useWindowSize from '../../../hooks/useResize';
import { ReactComponent as ChefSliderIcon } from '../../../icons/chef-slider-icon.svg';
import Section from '../../Section/Section';

export default function ChefSlider({ data }) {
  const [active, setActive] = useState(0);
  const prevActive = useRef(0);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const { isDesktop } = useWindowSize();

  const [zIndexes, setZindexes] = useState(
    data.map((_, index) => data.length - (data.length + index))
  );
  const animationFinished = useRef(false);
  const [order, setOrder] = useState(1);
  const [direction, setDirection] = useState('next');
  const firstRender = useRef(true);
  const timeout = useRef();

  const onAnimationStart = (event) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setOrder((prev) => prev + 1);
    }, 310);
  };

  const generateRotate = useCallback((min = -15, max = 15) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }, []);

  const randomRotations = useMemo(() => {
    return data.map((_, index) => (index === 0 ? 0 : generateRotate()));
  }, [data]);

  useEffect(() => {
    clearTimeout(timeout.current);
    if (!firstRender.current) {
      if (direction === 'next') {
        const newOrder = [...zIndexes];
        newOrder.splice(active, 1, zIndexes[active] + active);
        setZindexes(newOrder);
      } else {
        const newOrder = [...zIndexes];
        newOrder.splice(active + 1, 1, zIndexes[active + 1] - (active + 1));
        setZindexes(newOrder);
      }
    }
  }, [order]);

  useEffect(() => {
    animationFinished.current = false;
  }, [zIndexes]);

  useEffect(() => {
    if (active === 0) {
      setPrevDisabled(true);
      if (nextDisabled) {
        setNextDisabled(false);
      }
    } else if (active === data.length - 1) {
      if (prevDisabled) {
        setPrevDisabled(false);
      }
      setNextDisabled(true);
    } else {
      if (prevDisabled) setPrevDisabled(false);
      if (nextDisabled) setNextDisabled(false);
    }
    if (prevActive.current > active) setDirection('prev');
    else setDirection('next');
    prevActive.current = active;
  }, [active]);

  useLayoutEffect(() => {
    firstRender.current = false;

    return () => {
      firstRender.current = true;
    };
  }, []);
  return (
    <Section className="restaurant-chef">
      <div
        onAnimationStart={onAnimationStart}
        className={cn('restaurant-chef-slider', {
          [`restaurant-chef-slider-${direction}`]: direction,
          'restaurant-chef-slider-initial': active === 0,
        })}
      >
        {data.map((item, index) => (
          <article
            key={item.id}
            style={{
              '--rotate': `${randomRotations[index]}deg`,
              zIndex: zIndexes[index],
            }}
            className={cn('restaurant-chef-slide', {
              'restaurant-chef-slide--active': active === index,
              'restaurant-chef-slide--animation-next':
                active === index &&
                direction === 'next' &&
                !firstRender.current,
              'restaurant-chef-slide--animation-prev':
                active + 1 === index && direction === 'prev',
              'restaurant-chef-slide--prev': index === active - 1,
              'restaurant-chef-slide--next': index === active + 1,
            })}
          >
            <div className="restaurant-chef-slide__image-holder">
              <img
                className="restaurant-chef-slide__image"
                src={item.image}
                alt={item.header}
              />
            </div>
            {index !== 0 && (
              <div className="restaurant-chef-slide__data">
                <span
                  className="restaurant-chef-slide__data-title"
                  dangerouslySetInnerHTML={{ __html: data[index].top_title }}
                />
                <span
                  className="restaurant-chef-slide__data-header"
                  dangerouslySetInnerHTML={{ __html: data[index].header }}
                />
                <span
                  className="restaurant-chef-slide__data-description"
                  dangerouslySetInnerHTML={{ __html: data[index].description }}
                />
                {data[index].link && (
                  <a
                    className="restaurant-chef-slide__data-link"
                    href={data[index].link}
                  >
                    {data[index].link}
                  </a>
                )}
              </div>
            )}
          </article>
        ))}
        <button
          className="restaurant-chef-prev"
          type="button"
          onClick={() => setActive((prev) => prev - 1)}
          aria-label="Предыдущий"
          disabled={prevDisabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            className="restaurant-chef-icon restaurant-chef-icon-arrow"
          >
            <g fill="none" fillRule="evenodd">
              <line x1="30" y1="50" x2="77" y2="49.55" stroke="#4F525A" />
              <path stroke="#4F525A" d="M48.5 40.3l9.2 9.2-9.2 9.2" />
            </g>
          </svg>
        </button>
        <button
          className="restaurant-chef-next"
          type="button"
          onClick={() => setActive((prev) => prev + 1)}
          aria-label="Следующий"
          disabled={nextDisabled}
        >
          <ChefSliderIcon className="restaurant-chef-icon" />
        </button>
      </div>
      <div
        className={cn('restaurant-chef-info', {
          'restaurant-chef-info--visible': active === 0,
        })}
      >
        <span
          className="restaurant-chef-info-title"
          dangerouslySetInnerHTML={{ __html: data[0].top_title }}
        />
        <span
          className="restaurant-chef-info-header"
          dangerouslySetInnerHTML={{ __html: data[0].header }}
        />
        <span
          className="restaurant-chef-info-description"
          dangerouslySetInnerHTML={{ __html: data[0].description }}
        />
      </div>
    </Section>
  );
}
