/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import Section from '../../../../Section/Section';
import FormField from '../../../../UI/Form/Field';
import NewLocationAddress from './LocationAddress';
import Button from '../../../../Button/Button';
import utilities from '../../../../../utilities';
import routes from '../../../../../routes';
import { LocationsContext } from '../../../../../context/Bindings/Locations';
import { ModalContext } from '../../../../../context/Modal';

export default function LocationForm({ location }) {
  const [, setOpen] = useContext(ModalContext);
  const [locations, setLocations] = useContext(LocationsContext);
  const methods = useForm({
    defaultValues: { ...location },
    shouldUnregister: true,
  });
  const { handleSubmit, control, formState } = methods;

  const updateLocation = async (values) => {
    const response = await utilities.api.patch(
      routes.apiSwagger.modifyUserLocation(location.id),
      {
        ...values,
        city: 'Краснодар',
      }
    );
    const index = locations.results.findIndex(
      ({ id: locationId }) => locationId === location.id
    );
    const newLocations = [...locations.results];
    newLocations.splice(index, 1, response);
    setLocations((prev) => ({
      ...prev,
      results: [...newLocations],
    }));
    setOpen(false);
  };

  const createLocation = async (values) => {
    const response = await utilities.api.post(routes.apiSwagger.locations(), {
      ...values,
      city: 'Краснодар',
    });
    // utilities.api.invalidateCacheByMethod(routes.apiSwagger.locations());
    setLocations((prev) => ({
      ...prev,
      results: [...prev.results, response],
    }));
    setOpen(false);
  };

  const onSubmit = (values) => {
    if (location) {
      updateLocation(values);
    } else {
      createLocation(values);
    }
  };

  return (
    <FormProvider {...methods}>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Section.Title>{`${utilities.translate('Адрес')}:`}</Section.Title>
        <FormField
          required
          name="name"
          label={`${utilities.translate('Название')}:`}
        />
        <FormField
          columnComponent
          required
          label={`${utilities.translate('Адрес')}:`}
          component={<NewLocationAddress />}
        />
        <Button type="submit">{utilities.translate('Сохранить')}</Button>
        {/* <DevTool control={control} /> */}
      </form>
    </FormProvider>
  );
}
