import cn from 'classnames';
import { useLayoutEffect, useState, useRef, useEffect } from 'react';
import useObserver from '../../hooks/useIntersectionObserver';
import utilities from '../../utilities';
import Description from '../Description/Description';
import Title from '../Title/Title';

const baseClassName = 'section';

const Section = (props) => {
  const [intersected, setIntersected] = useState(false);
  const onIntersect = ([entry]) => {
    if (entry.isIntersecting) setIntersected(true);
  };

  const { setObservable, destroyObservable } = useObserver({
    callback: onIntersect,
    triggerOnce: true,
  });
  const ref = useRef();
  const {
    children,
    className,
    observable = true,
    sectionRef,
    column = false,
    isNarrow,
    largeMargin,
    style,
  } = props;
  const sectionClassName = cn(className, baseClassName, {
    [`${baseClassName}--column`]: column,
    [`${baseClassName}--large-margin`]: largeMargin,
    [`${baseClassName}--narrow`]: isNarrow,
    [`${baseClassName}--intersected`]: observable && intersected,
    [`${baseClassName}--observable`]: observable,
  });

  const onPageLeave = () => {
    setIntersected(false);
    setTimeout(() => {
      document.dispatchEvent(
        new CustomEvent(utilities.ROUTE_CONSTANTS.PAGE_SHOW_NEXT)
      );
    }, 1000);
  };

  const setRef = (element) => {
    if (sectionRef) {
      sectionRef(element);
    }
    ref.current = element;
  };

  useLayoutEffect(() => {
    if (observable) {
      setObservable(ref.current);
    } else {
      destroyObservable();
    }
  }, []);

  useEffect(() => {
    if (observable) {
      document.addEventListener(
        utilities.ROUTE_CONSTANTS.PAGE_LEAVE,
        onPageLeave
      );
    }

    return () => {
      ref.current = null;
      if (observable) {
        document.removeEventListener(
          utilities.ROUTE_CONSTANTS.PAGE_LEAVE,
          onPageLeave
        );
      }
    };
  }, []);

  return (
    <section style={style} ref={setRef} className={sectionClassName}>
      {children}
    </section>
  );
};

Section.Title = (props) => {
  const { children, className, size, align, dangerHTML } = props;
  const titleClassName = cn(`${baseClassName}__title`, className);
  return (
    <Title
      className={titleClassName}
      size={size}
      align={align}
      dangerHTML={dangerHTML}
    >
      {children}
    </Title>
  );
};

Section.Title.Link = (props) => {
  const { children, className, href } = props;
  const titleLinkClassName = cn(`${baseClassName}__title-link`, className);
  return (
    <Title.Link className={titleLinkClassName} href={href}>
      {children}
    </Title.Link>
  );
};

Section.Description = (props) => {
  const { children, className } = props;
  const descriptionLinkClassName = cn(
    `${baseClassName}__description`,
    className
  );
  return (
    <Description className={descriptionLinkClassName}>{children}</Description>
  );
};

export default Section;
