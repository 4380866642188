import { useFormContext } from 'react-hook-form';
import DeliveryOption from '../Option';
import DeliveryTime from './Time';
import utilities from '../../../../utilities';

export default function Time() {
  const { watch } = useFormContext();
  const watcher = watch('delivery_type');

  // if (!watcher || watcher === '1') {
  //   return null;
  // }

  return (
    <DeliveryOption
      style={{ zIndex: 1 }}
      label={
        watcher === '1'
          ? utilities.translate('Когда вас ждать')
          : utilities.translate('Дата и время доставки')
      }
    >
      <DeliveryTime />
    </DeliveryOption>
  );
}
