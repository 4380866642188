/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { AccountContext } from '../../../context/Account';
import { ReactComponent as LogoutIcon } from '../../../icons/logout.svg';
import routes from '../../../routes';
import utilities from '../../../utilities';

export default function Logout() {
  const { account, setAccount } = useContext(AccountContext);
  const history = useHistory();
  const onLogoutClick = async () => {
    await utilities.api.post(
      routes.apiSwagger.logout(utilities.storage.get('madyar-id')),
      {},
      {
        headers: {
          Authorization: `Token ${utilities.storage.get('madyar-auth-token')}`,
        },
      }
    );
    utilities.logout();
    history.push(routes.page.home());
    setAccount(null);
  };

  return (
    <>
      <NavLink
        className="profile-teaser__greeting"
        to={routes.page.account()}
        data-text={`${utilities.translate('Привет')}, ${account.first_name}!`}
      >
        {`${utilities.translate('Привет')}, ${account.first_name}!`}
      </NavLink>
      <button
        onClick={onLogoutClick}
        className="profile-teaser__logout"
        type="button"
      >
        {utilities.translate('Выход')}
        <LogoutIcon className="profile-teaser__logout-icon" />
      </button>
    </>
  );
}
