import ContentLoader from 'react-content-loader';

export default function BlockSkeleton(props) {
  const { className, style, width, height, label, count, gap } = props;

  const generateHeight = () => {
    if (count) {
      return count * height + gap * (count - 1);
    }
    return height;
  };

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={generateHeight()}
      title={label || 'Загрузка...'}
      viewBox={`0 0 ${parseInt(width, 10)} ${generateHeight()}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className={className}
      style={style}
      preserveAspectRatio="none"
    >
      {count ? (
        Array.from(Array(count)).map((_, index) => (
          <rect
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            x="0"
            y={index * (height + gap)}
            width={width}
            height={height}
          />
        ))
      ) : (
        <rect x="0" y="0" width={width} height={height} />
      )}
    </ContentLoader>
  );
}
