import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import FormField from '../../../../UI/Form/Field';
import AddressAutocomplete from './AutoComplete';
import utilities from '../../../../../utilities';

export default function CustomAddress({ onSuccess }) {
  const { control } = useFormContext();
  return (
    <div className="delivery-custom-address">
      <AddressAutocomplete onSuccess={onSuccess} />
      <FormField
        nomargin
        componentLabel={utilities.translate('Подъезд')}
        direction="column"
        customId="porch-number-input"
        component={
          <Controller
            control={control}
            name="porch_number"
            render={({ field }) => (
              <NumberFormat
                className="form__input"
                onValueChange={(value) => {
                  field.onChange(value.value);
                }}
                {...field}
              />
            )}
          />
        }
      />
      <FormField
        nomargin
        componentLabel={utilities.translate('Квартира')}
        customId="flat-number-input"
        component={
          <Controller
            control={control}
            name="flat_number"
            render={({ field }) => (
              <NumberFormat
                className="form__input"
                onValueChange={(value) => {
                  field.onChange(value.value);
                }}
                {...field}
              />
            )}
          />
        }
      />
      <FormField
        nomargin
        componentLabel={utilities.translate('Этаж')}
        customId="floor-number-input"
        component={
          <Controller
            control={control}
            name="floor_number"
            render={({ field }) => (
              <NumberFormat
                className="form__input"
                onValueChange={(value) => {
                  field.onChange(value.value);
                }}
                {...field}
              />
            )}
          />
        }
      />
    </div>
  );
}
