/* eslint-disable no-unused-vars */
import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { EstablishmentsContext } from '../../../context/Establishments';
import useObserver from '../../../hooks/useIntersectionObserver';
import useWindowSize from '../../../hooks/useResize';
import Section from '../../Section/Section';
import Slider from '../../Slider';
import RestaurantPreview from './Preview';
import Video from './Video';

export default function RestaurantsSection() {
  const { isPhone } = useWindowSize();
  const establishments = useContext(EstablishmentsContext);
  const [sectionWidth, setSectionWidth] = useState();
  const [activeEstablishment, setAcitiveEstablishment] = useState(null);

  const onIntersect = ([entry]) => {
    if (entry.isIntersecting) {
      document.dispatchEvent(new CustomEvent('circle:hide'));
    } else {
      document.dispatchEvent(new CustomEvent('circle:show'));
    }
  };

  const { setObservable } = useObserver({
    callback: onIntersect,
    options: {
      rootMargin: '100px 0px 0px 0px',
      threshold: 0.4,
    },
  });
  const ref = useRef();

  useLayoutEffect(() => {
    setObservable(ref.current);
    setSectionWidth(ref.current.clientWidth);
  }, []);

  return (
    <Section
      sectionRef={(element) => {
        ref.current = element;
      }}
      style={{
        '--section-width': `${sectionWidth}px`,
      }}
      className="home-restaurants"
    >
      <Video activeEstablishment={activeEstablishment} />
      <Slider
        loop
        navigation
        autoplay={{ delay: 5000 }}
        speed={3000}
        onSlideChange={({ realIndex }) =>
          setAcitiveEstablishment(establishments[realIndex])
        }
        centeredSlides
        scrollbar={false}
        direction="vertical"
        className="home-restaurants-slider"
        pagination={
          !isPhone
            ? {
                type: 'custom',
                renderCustom: (_swiper, current, total) =>
                  `${current} ${total}`,
              }
            : null
        }
        elements={establishments}
        slideProps={{
          type: 'slide',
          className: 'home-restaurants-slider-slide',
        }}
        slide={RestaurantPreview}
      />
    </Section>
  );
}
