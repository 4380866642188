import Head from '../components/NewsItem/Head';
import Slider from '../components/NewsItem/Slider';

export default function NewsItem() {
  return (
    <>
      <Head />
      <Slider />
    </>
  );
}
