import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import FeedbackForm from '../components/Feedback';
import CollaborationContent from '../components/Collaboration/Index';
import Section from '../components/Section/Section';
import Meta from '../components/Meta';

const Collaboration = () => {
  return (
    <>
      <Meta url="/collaboration" />
      <Breadcrumbs />
      <Section observable={false} className="collaboration">
        <CollaborationContent />
      </Section>
      <FeedbackForm />
    </>
  );
};

export default Collaboration;
