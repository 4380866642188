/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import * as api from './api';

const API_KEY = '9645b33918d937521c5467d3970b70b0acecf5d9';
const URL =
  'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';

export async function get(values) {
  const response = await api.postThirdParty(URL, values, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Token ${API_KEY}`,
    },
  });
  return response.suggestions;
  // return response;
}
