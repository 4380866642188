/* eslint-disable react/no-array-index-key */
import { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import * as crumbs from '../../crumbs';

const Breadcrumbs = ({ exact = {} }) => {
  const location = useLocation();
  const firstMount = useRef(true);
  const [path, setPath] = useState(location.pathname.split('/').slice(1));

  useEffect(() => {
    if (!firstMount.current) {
      setPath(location.pathname.split('/').slice(1));
    }
  }, [location]);

  useEffect(() => {
    firstMount.current = false;

    return () => {
      firstMount.current = true;
    };
  }, []);

  return (
    <ul
      className="breadcrumbs"
      itemScope
      itemType="http://schema.org/BreadcrumbList"
      id="breadcrumbs"
    >
      {path.map((item, index) => (
        <>
          <li
            key={index}
            className="breadcrumbs__item"
            itemScope
            itemProp="itemListElement"
            itemType="http://schema.org/ListItem"
          >
            {index !== path.length - 1 && (
              <>
                <NavLink
                  className="breadcrumbs__link"
                  rel="nofollow"
                  itemProp="item"
                  title={exact[item] || crumbs.getCrumbText(item)}
                  to={crumbs.getCrumbLink(path, index)}
                >
                  <span itemProp="name">
                    {exact[item] || crumbs.getCrumbText(item)}
                  </span>
                </NavLink>
                <meta itemProp="position" content={(index + 1).toString()} />
              </>
            )}
            {index === path.length - 1 && (
              <p itemProp="item" className="breadcrumbs__text">
                <span itemProp="name">
                  {exact[item] || crumbs.getCrumbText(item)}
                </span>
                <meta itemProp="position" content={(index + 1).toString()} />
              </p>
            )}
          </li>
        </>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
