import BlockSkeleton from '../../Skeleton/Block';
import Advertisement from './Item';

export default function Advertisements({ advertisements }) {
  if (!advertisements) {
    return <BlockSkeleton width="100%" height={770} label="Загрузка истории" />;
  }
  return advertisements.map((data) => (
    <Advertisement key={data.id} item={data} />
  ));
}
