/* eslint-disable camelcase */

const Hero = (props) => {
  const { image } = props;

  return (
    <div className="hero page">
      <img className="hero__image" src={image} alt="" />
    </div>
  );
};

export default Hero;
