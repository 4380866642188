import General from './General';
import Restaurant from './Restaurant';

export default function Contacts() {
  return (
    <>
      <General />
      <Restaurant />
    </>
  );
}
