import { useEffect } from 'react';
import { Parallax, useController } from 'react-scroll-parallax';

const ParallaxUpdate = () => {
  const { parallaxController } = useController();

  useEffect(() => {
    requestAnimationFrame(() => {
      parallaxController.update();
    });
  });

  return null;
};

export default function ParallaxComponent({
  children,
  className,
  disabled = false,
  x,
  y = ['-50%', '50%'],
}) {
  return (
    <Parallax disabled={disabled} x={x} y={y} className={className}>
      <ParallaxUpdate />
      {children}
    </Parallax>
  );
}
