/* eslint-disable no-unused-vars */
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '../../../UI/Form/Checkbox';
import {
  hasApplePayPaymentType,
  hasOnlineCardPaymentType,
  hasGooglePayPaymentType,
  hasCourierPaymentType,
  ONLINE_CARD_PAYMENT_TYPE,
  APPLE_PAY_PAYMENT_TYPE,
  GOOGLE_PAY_PAYMENT_TYPE,
  COURIER_PAYMENT_TYPE,
} from './helpers';
import utilities from '../../../../utilities';

export default function Cards({ paymentsTypes }) {
  const { control } = useFormContext();
  return (
    <div className="payment-method-wrapper">
      <Controller
        control={control}
        name="payment_type"
        render={({ field }) => (
          <>
            {hasOnlineCardPaymentType(paymentsTypes) && (
              <Checkbox
                type="radio"
                value={ONLINE_CARD_PAYMENT_TYPE}
                label={utilities.translate('Картой на сайте')}
                onValueChange={field.onChange}
                name={field.name}
                id={`pay_by_card${ONLINE_CARD_PAYMENT_TYPE}`}
              />
            )}
            {hasCourierPaymentType(paymentsTypes) && (
              <Checkbox
                type="radio"
                value={COURIER_PAYMENT_TYPE}
                label={utilities.translate('По QR коду курьеру')}
                onValueChange={field.onChange}
                name={field.name}
                id={`pay_by_card${COURIER_PAYMENT_TYPE}`}
              />
            )}
            {/* {hasApplePayPaymentType(paymentsTypes) && (
              <Checkbox
                type="radio"
                value={APPLE_PAY_PAYMENT_TYPE}
                label="Apple Pay"
                onValueChange={field.onChange}
                name={field.name}
                id={`pay_by_card${APPLE_PAY_PAYMENT_TYPE}`}
              />
            )}
            {hasGooglePayPaymentType(paymentsTypes) && (
              <Checkbox
                type="radio"
                value={GOOGLE_PAY_PAYMENT_TYPE}
                label="Google pay"
                onValueChange={field.onChange}
                name={field.name}
                id={`pay_by_card${GOOGLE_PAY_PAYMENT_TYPE}`}
              />
            )} */}
          </>
        )}
      />
    </div>
  );
}
