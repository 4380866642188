/* eslint-disable no-unused-vars */
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Favorite from '../components/Favorite';
import FavoriteFilter from '../components/Dishes/Filter/FavoriteFilter';
import {
  FavoriteDishesContextProvider,
  FavoriteDishesFilterContextProvider,
} from '../context/Dishes/Favorite';

const FavoriteDishes = () => {
  return (
    <>
      <Breadcrumbs exact="Избранное" />
      <FavoriteDishesFilterContextProvider>
        <FavoriteDishesContextProvider>
          <FavoriteFilter />
          <Favorite />
        </FavoriteDishesContextProvider>
      </FavoriteDishesFilterContextProvider>
    </>
  );
};

export default FavoriteDishes;
