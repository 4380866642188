/* eslint-disable no-unused-vars */

import utilities from './utilities';

const page = {
  home: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}`,
  catering: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/catering`,
  favorite: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/favorite`,
  payment: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/payment`,
  collaboration: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/collaboration`,
  contacts: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/contacts`,
  events: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/events`,
  event: (id = ':eventId', link = true) =>
    `/${link ? utilities.getLang() : ':lang'}/events/${link ? id : ':eventId'}`,
  documents: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/documents`,
  document: (id = ':documentSlug', link = true) =>
    `/${link ? utilities.getLang() : ':lang'}/documents/${
      link ? id : ':documentSlug'
    }`,
  mobileApplication: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/mobile_application`,
  news: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/news`,
  newsItem: (id = ':newsId', link = true) =>
    `/${link ? utilities.getLang() : ':lang'}/news/${link ? id : ':newsId'}`,
  search: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/search`,
  restaurants: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/restaurants`,
  deliveringRestaurants: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/restaurants#delivery`,
  restaurant: (id = ':id', link = true) =>
    `/${link ? utilities.getLang() : ':lang'}/restaurants/${link ? id : ':id'}`,
  dishes: (id = ':establishmentId', link = true) =>
    `/${link ? utilities.getLang() : ':lang'}/restaurants/${
      link ? id : ':establishmentId'
    }/dishes`,
  vacancies: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/vacancies`,
  team: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/team`,
  vacancy: (id = ':vacancyId', link = true) =>
    `/${link ? utilities.getLang() : ':lang'}/vacancies/${
      link ? id : ':vacancyId'
    }`,
  account: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/account`,
  basket: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/basket`,
  ordersHistory: (options = { link: true }) =>
    `/${options.link ? utilities.getLang() : ':lang'}/account/history`,
};

const SWAGGER_URL = process.env.REACT_APP_SWAGGER_API;

const apiSwagger = {
  establishments: (id = '') =>
    `${SWAGGER_URL}/establishments/${id}${id ? '/' : ''}`,
  authCode: () => `${SWAGGER_URL}/sms/obtain-code/`,
  login: () => `${SWAGGER_URL}/users/login/`,
  account: () =>
    `${SWAGGER_URL}/users/profile/${utilities.storage.get(
      'madyar-id'
    )}/?token=${utilities.storage.get('madyar-auth-token')}`,
  logout: () =>
    `${SWAGGER_URL}/users/profile/${utilities.storage.get(
      'madyar-id'
    )}/logout/`,
  updateAccount: () =>
    `${SWAGGER_URL}/users/profile/${utilities.storage.get('madyar-id')}/`,
  userPromocodes: () =>
    `${SWAGGER_URL}/users/profile/${utilities.storage.get(
      'madyar-id'
    )}/promocodes/`,
  addPromoCode: () =>
    `${SWAGGER_URL}/shopping-cards/${utilities.basket.getCardKeyId()}/add-promocode/`,
  removePromoCode: () =>
    `${SWAGGER_URL}/shopping-cards/${utilities.basket.getCardKeyId()}/remove-promocode/`,
  addShoppingCardAddress: () =>
    `${SWAGGER_URL}/shopping-cards/${utilities.basket.getCardKeyId()}/add-address/`,
  ordersRecommended: () => `${SWAGGER_URL}/bundle/`,
  updateBasketBranch: () =>
    `${SWAGGER_URL}/shopping-cards/${utilities.basket.getCardKeyId()}/add-address/`,
  locations: () =>
    `${SWAGGER_URL}/users/profile/${utilities.storage.get(
      'madyar-id'
    )}/locations/`,
  modifyUserLocation: (id) =>
    `${SWAGGER_URL}/users/profile/${utilities.storage.get(
      'madyar-id'
    )}/locations/${id}/`,
  dishes: (id = '') => `${SWAGGER_URL}/dishes/${id ? `${id}/` : ''}`,
  dishCategories: () => `${SWAGGER_URL}/dish-categories/`,
  dishCategoryInfo: (id) => `${SWAGGER_URL}/dish-categories/${id}/`,
  createShoppingCard: () => `${SWAGGER_URL}/shopping-cards/`,
  getShoppingCardInfo: (id) => `${SWAGGER_URL}/shopping-cards/${id}/`,
  updateOrder: () =>
    `${SWAGGER_URL}/shopping-cards/${utilities.basket.getCardKeyId()}/items/`,
  orders: () => `${SWAGGER_URL}/orders/`,
  allOrders: () => `${SWAGGER_URL}/orders/all/`,
  rateOrder: (id) => `${SWAGGER_URL}/orders/${id}/rate/`,
  rateOrderReason: () => `${SWAGGER_URL}/orders-rate-reasons/`,
  cancelOrder: (id) => `${SWAGGER_URL}/orders/${id}/cancel/`,
  deliveryPrice: () => `${SWAGGER_URL}/orders/delivery-price/`,
  search: () => `${SWAGGER_URL}/dishes/`,
  pay: (id) => `${SWAGGER_URL}/orders/${id}/pay/`,
  addFavorite: (id) => `${SWAGGER_URL}/dishes/${id}/favorite/`,
};

const api = {
  main: () => '/api/main',
  events: (id = '') => `/api/events${id ? `/${id}` : ''}`,
  eventsAndNews: (id = '') => `/api/poster_items${id ? `/${id}` : ''}`,
  news: (id = '') => `/api/news${id ? `/${id}` : ''}`,
  collaboration: () => `/api/advertisement`,
  catering: () => '/api/catering',
  contacts: () => '/api/contacts',
  payment: () => '/api/payment_info',
  vacancies: (id = '') => `/api/vacancies${id ? `/${id}` : ''}`,
  vacancyForm: () => '/api/requests/vacancy',
  feedback: () => '/api/requests/feedback',
  callback: () => '/api/requests/callback',
  cateringOrder: () => '/api/requests/catering',
  legalDocuments: () => `/api/legal`,
  establishmentChef: (id) => `/api/establishments/slider/${id}`,
  socials: () => `/api/social_links`,
  team: () => `/api/team`,
  meta: () => '/api/meta_tags',
};

export default { page, api, apiSwagger };
