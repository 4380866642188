import useWindowSize from '../../../hooks/useResize';
import GridSkeleton from '../../Skeleton/Grid';
import TextSkeleton from '../../Skeleton/Text';

const RestaurantPreloader = () => {
  const { isDesktop, isTablet } = useWindowSize();

  if (isDesktop) {
    return (
      <>
        <TextSkeleton
          width={400}
          height={50}
          label="Имя"
          style={{ marginBottom: '20px' }}
        />
        <GridSkeleton
          count={6}
          columns={isDesktop ? 3 : 2}
          gap={isDesktop ? 20 : 10}
          proportion={1.5}
          label="Загрузка категорий"
        />
      </>
    );
  }
  if (isTablet) {
    return (
      <>
        <TextSkeleton
          width={245}
          height={60}
          label="Имя"
          style={{ margin: '25px auto' }}
        />
        <GridSkeleton
          count={4}
          columns={2}
          gap={10}
          proportion={1.5}
          label="Загрузка категорий"
        />
      </>
    );
  }
  return (
    <>
      <TextSkeleton
        width={245}
        height={60}
        label="Имя"
        style={{ margin: '25px auto' }}
      />
      <GridSkeleton
        count={2}
        columns={1}
        gap={isDesktop ? 20 : 10}
        proportion={1.5}
        label="Загрузка категорий"
      />
    </>
  );
};

export default RestaurantPreloader;
