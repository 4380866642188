import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import cn from 'classnames';

const PhoneField = ({ onChange, name, fieldState, ...rest }, ref) => {
  const classNames = cn('form__input', {
    'form__input--untouched': !fieldState.isDirty && !rest.value,
  });
  return (
    <>
      <NumberFormat
        allowEmptyFormatting
        format="+7 (###) ### ## ##"
        mask="_"
        type="tel"
        onValueChange={(value) => {
          onChange(value.value);
        }}
        getInputRef={ref}
        name={name}
        className={classNames}
        {...rest}
      />
    </>
  );
};

export default forwardRef(PhoneField);
