function isDataLayer() {
  return window.dataLayer;
}

function createDataLayer() {
  window.dataLayer = [];
}

export default function callGtmEvent(eventName, items, other = {}) {
  if (!isDataLayer()) createDataLayer();
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: eventName,
    ecommerce: {
      items: items.map((item, index) => ({
        ...item,
        index,
      })),
      ...other,
    },
  });
}

export function callGtmEventNonCommerce(name = '', options = {}) {
  if (!isDataLayer()) createDataLayer();
  window.dataLayer.push({
    event: name,
    ...options,
  });
}
