import React from 'react';
import Title from '../Title/Title';
import utilities from '../../utilities';
import Arrow from './Arrow';
import useWindowSize from '../../hooks/useResize';
import ArrowMobile from './ArrowMobile';

export default function PolicyFiles({ files }) {
  const { isDesktop } = useWindowSize();

  return (
    <div className="policy-files">
      {files.map(({ title, file, id }) => (
        <a
          key={id}
          className="policy-files__item"
          href={file}
          target="_blank"
          rel="noreferrer"
        >
          {isDesktop && (
            <div className="policy-files__image-holder">
              <img alt="" src="/images/pdf-preview.jpg" />
            </div>
          )}

          <Title tag="h2" className="policy-files__item-name">
            {utilities.translate(title)}
          </Title>
          {isDesktop ? (
            <Arrow className="policy-files__arrow" />
          ) : (
            <ArrowMobile className="policy-files__arrow" />
          )}
        </a>
      ))}
    </div>
  );
}
