import Slider from '../../Slider';
import HeadSlide from './Slide';
import BlockSkeleton from '../../Skeleton/Block';

export default function HeadSlider(props) {
  const { images: data } = props;
  if (!data) {
    return (
      <BlockSkeleton
        width="100%"
        height={430}
        className="section"
        label="Слайдер"
      />
    );
  }

  if (data?.slider?.length === 0) {
    return null;
  }

  if (data?.slider?.length === 1) {
    return (
      <div className="event__image-holder">
        <img className="event__image" alt="" src={data.slider[0]} />
      </div>
    );
  }

  if (data?.slider?.length > 1) {
    return (
      <Slider
        slideClassName="event__slide"
        elements={data.slider}
        spaceBetween={12}
        // slidesPerView={1}
        scrollbarShift
        scrollbarShrinked
        slide={HeadSlide}
      />
    );
  }

  return null;
}
