import * as localStorage from './localStorage';
import * as currency from './currency';
import * as api from './api';
import * as animation from './animation';
import * as date from './date';
import * as params from './params';
import * as start from './start';
import * as validation from './validation';
import * as basket from './basket';
import * as address from './address';
import errors from './validationErrors';
import getLang from './lang';
import { translate } from './dictionary';
import * as phone from './phone';
import * as cookie from './cookie';
import ROUTE_CONSTANTS from './routeConstants';

export default {
  ROUTE_CONSTANTS,
  storage: localStorage,
  currency,
  animation,
  date,
  api,
  start,
  params,
  basket,
  phone,
  cookie,
  address,
  errors,
  getLang,
  translate: (value) => translate(value, document.documentElement.lang),
  logout: () => {
    localStorage.remove('madyar-id');
    localStorage.remove('madyar-auth-token');
  },
  isLogged: () => {
    return localStorage.get('madyar-id');
  },
  validation,
};
