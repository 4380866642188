/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { DevTool } from '@hookform/devtools';
import { Fragment, useContext, useEffect, useRef } from 'react';
import { FormProvider, Controller, useForm, useWatch } from 'react-hook-form';
import { ModalContext } from '../../../context/Modal';
import useWindowSize from '../../../hooks/useResize';
import { ReactComponent as StarIcon } from '../../../icons/big-star.svg';
import routes from '../../../routes';
import utilities from '../../../utilities';
import Button from '../../Button/Button';
import Title from '../../Title/Title';
import FormField from '../../UI/Form/Field';

export default function RateForm({ orderId, state, setData }) {
  const methods = useForm();
  const [differentModalOpen, setDifferentModalOpen] = state;
  const [setFormData] = setData;
  const [, setModalOpen] = useContext(ModalContext);
  const { watch, register, control, handleSubmit } = methods;
  const watcher = watch('rate');
  const { isPhone } = useWindowSize();
  const labelRefs = useRef([]);

  const onSubmit = async ({ rate, rate_comment }) => {
    if (rate <= 2) {
      setFormData({ rate_comment, rate: rate + 1 });
      setDifferentModalOpen(true);
      setModalOpen(false);
    } else {
      const response = await utilities.api.post(
        routes.apiSwagger.rateOrder(orderId),
        {
          rate: rate + 1,
          rate_comment,
        }
      );
      setModalOpen(false);
    }
  };

  useEffect(() => {
    labelRefs.current.forEach((label) => {
      label.classList.remove(`${label.classList[0]}--active`);
    });
    labelRefs.current.slice(0, +watcher + 1).forEach((label) => {
      label.classList.add(`${label.classList[0]}--active`);
    });
  }, [watcher]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="rate-form">
        <Title>{`${utilities.translate('Оцените заказ')}:`}</Title>
        <FormField
          label={`${utilities.translate('Ваша оценка')}:`}
          component={
            <Controller
              control={control}
              name="rate"
              render={({ field }) => (
                <div className="rates-holder">
                  {Array.from(Array(5)).map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={index}>
                      <input
                        id={`rate-${index}`}
                        hidden
                        type="radio"
                        value={index}
                        name={field.name}
                        onChange={() => field.onChange(index)}
                        ref={field.ref}
                      />
                      <label
                        ref={(element) => {
                          labelRefs.current[index] = element;
                        }}
                        className="rate-label"
                        htmlFor={`rate-${index}`}
                      >
                        <StarIcon className="rate-icon" />
                      </label>
                    </Fragment>
                  ))}
                </div>
              )}
            />
          }
        />
        <FormField
          label={`${utilities.translate('Отзыв')}: `}
          name="rate_comment"
        />
        <Button size={isPhone && 'full'} type="submit">
          {utilities.translate('Отправить')}
        </Button>
        {/* <DevTool control={control} /> */}
      </form>
    </FormProvider>
  );
}
