/* eslint-disable react/no-danger */
import { useState } from 'react';
import useWindowSize from '../../hooks/useResize';
import utilities from '../../utilities';
import Apps from '../Apps/Apps';
import Section from '../Section/Section';

const COOKIE_NAME = 'madyar_plate_apps_cancelled';

export default function AppPlate() {
  const { isDesktop } = useWindowSize();

  const [isCancelled, setCancelled] = useState(
    utilities.cookie.get(COOKIE_NAME)
  );
  const onClick = () => {
    utilities.cookie.set(COOKIE_NAME, 'true', { months: 1 });
    setCancelled(true);
  };

  return (
    !isCancelled && (
      <Section>
        <div className="app-plate">
          <Apps
            star={false}
            className="app-plate__apps"
            color={isDesktop ? 'gray' : 'black'}
            hoverColor="black"
            borderColor="white"
          />
          <span
            className="app-pate__text"
            dangerouslySetInnerHTML={{
              __html: `${utilities.translate(
                'Заказывайте вкусную еду через наше мобильное приложение&nbspи получайте скидки&nbspи бонусы!'
              )}`,
            }}
          />
          <button type="button" onClick={onClick} className="app-plate__close">
            закрыть
          </button>
        </div>
      </Section>
    )
  );
}
