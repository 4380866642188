import cn from 'classnames';
import { useLayoutEffect, useRef, useState } from 'react';
import useObserver from '../../hooks/useIntersectionObserver';
import routes from '../../routes';
import utilities from '../../utilities';
import Button from '../Button/Button';
import BlockSkeleton from '../Skeleton/Block';

export default function Hero({ restaurant, pictures }) {
  const [intersected, setIntersected] = useState(false);
  const onIntersect = ([entry]) => {
    if (entry.isIntersecting) {
      setIntersected(true);
    }
  };
  const heroRef = useRef();
  const { setObservable } = useObserver({
    callback: onIntersect,
    triggerOnce: true,
  });

  const heroClasses = cn('hero', {
    'hero--visible': intersected,
  });

  useLayoutEffect(() => {
    if (restaurant) {
      setObservable(heroRef.current);
    }
  }, [restaurant]);

  if (!restaurant) {
    return (
      <BlockSkeleton
        width="100%"
        style={{ marginBottom: '50px' }}
        height={440}
        className="section"
        label="Hero"
      />
    );
  }

  return (
    <div ref={heroRef} className={heroClasses}>
      <div className="hero__wrapper">
        {pictures && (
          <img
            className="hero__image"
            alt="Картинка ресторана"
            src={pictures.restaurantCover}
          />
        )}
        <img
          className="hero__logo"
          src={restaurant?.logo_black || restaurant.logo}
          alt={restaurant.name}
        />
        <Button
          className="hero__button"
          size="medium"
          color="white"
          to={routes.page.dishes(restaurant.slug)}
        >
          {utilities.translate('Меню ресторана')}
        </Button>
      </div>
    </div>
  );
}
