import CountUp from 'react-countup';
import Preloader from './Advantages/Preloader';

export default function Tips({ tips }) {
  return (
    <ul className="collaboration__list">
      {tips?.length > 0 ? (
        <>
          {tips.map((data) => (
            <li
              className="collaboration__item"
              key={Math.random().toString(16)}
            >
              <h1 className="collaboration__count">
                <CountUp end={parseInt(data.value, 10)} duration={10} />
              </h1>
              <div className="collaboration__text">{data.description}</div>
            </li>
          ))}
        </>
      ) : (
        <Preloader />
      )}
    </ul>
  );
}
