import React, { useContext } from 'react';
import { FavoriteDishesContext } from '../../context/Dishes/Favorite';
import Section from '../Section/Section';
import FavoriteDishCategories from '../Dishes/Dishes/FavoriteCategories';
import Preloader from '../Dishes/Dishes/Preloader';

export default function Favorite() {
  const { categories, dishes } = useContext(FavoriteDishesContext);

  return (
    <Section>
      {dishes && categories && dishes.length > 0 && categories.length > 0 ? (
        <FavoriteDishCategories
          categories={categories}
          allowedCategories={categories}
          teasers={dishes}
        />
      ) : (
        <Preloader />
      )}
    </Section>
  );
}
