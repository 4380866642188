import Head from '../components/Event/Head';
import Slider from '../components/Event/Slider';

export default function Event() {
  return (
    <>
      <Head />
      <Slider />
    </>
  );
}
