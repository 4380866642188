/* eslint-disable no-unused-vars */
import { useContext, useEffect, useRef } from 'react';
import { GlobalBasketContext } from '../../context/GlobalBasketContext';
import { ModalContext } from '../../context/Modal';
import Button from '../Button/Button';
import Description from '../Description/Description';
import Title from '../Title/Title';
import callGtmEvent from '../../utilities/gtm';
import utilities from '../../utilities';
import routes from '../../routes';

export default function DishBasket({
  dish,
  dishPropertyCallback,
  shouldUpdateDish = true,
}) {
  const { update, reset } = useContext(GlobalBasketContext);
  const [, setOpen] = useContext(ModalContext);
  const shouldRunPropertyCallback = useRef();

  const handleReplace = async () => {
    shouldRunPropertyCallback.current = true;
    reset();
    if (!dish?.property && shouldUpdateDish) {
      await update({ ...dish, dish_id: dish.id });
      const category = await utilities.api.get(
        routes.apiSwagger.dishCategoryInfo(dish.category_id)
      );
      callGtmEvent('add_to_cart', [
        {
          item_id: dish.id,
          item_name: dish.name,
          affiliation: `${dish.establishment_id} - id ресторана`,
          item_category: category.name,
          price: dish.price,
          quantity: 1,
        },
      ]);
    }
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      if (
        (shouldRunPropertyCallback.current && dish?.property) ||
        (shouldRunPropertyCallback.current && !shouldUpdateDish)
      ) {
        shouldRunPropertyCallback.current = false;
        dishPropertyCallback();
      }
    };
  }, []);

  return (
    <>
      <Title className="dish-basket-title">
        {utilities.translate('Очистить корзину?')}
      </Title>
      <Description className="dish-basket-description">
        {!shouldUpdateDish
          ? utilities.translate(
              'Очистить корзину и повторить заказ из истории?'
            )
          : utilities.translate(
              'Оформить заказ можно только из одного ресторана, у вас уже добавлены блюда из другого.'
            )}
      </Description>
      <div className="dish-basket-holder">
        <Button type="button" size="medium" onClick={handleReplace}>
          {utilities.translate('Да')}
        </Button>
        <Button onClick={() => setOpen(false)} type="button" size="medium">
          {utilities.translate('Нет')}
        </Button>
      </div>
    </>
  );
}
