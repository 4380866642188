/* eslint-disable camelcase */
import { createContext, useContext, useEffect, useState } from 'react';
import useLocalStorageChange from '../../hooks/useLocalStorageChange';
import routes from '../../routes';
import utilities from '../../utilities';
import { GlobalBasketContext } from '../GlobalBasketContext';

export const BasketRecommendedContext = createContext();

export const BasketRecommendedContextProvider = (props) => {
  const { children } = props;
  const [state, setState] = useState(null);
  const { items, id: shoppingCardId } = useContext(GlobalBasketContext);
  const shoppingCard = useLocalStorageChange({
    field: utilities.basket.SHOPPING_CARD_KEY,
  });

  useEffect(() => {
    const fetchBasketRecommended = async () => {
      const response = await utilities.api.get(
        routes.apiSwagger.ordersRecommended(),
        {
          category_ids: items.map(({ category_id }) => category_id),
        }
      );
      setState(response[0]?.dishes || null);
    };
    if (items?.length) {
      fetchBasketRecommended();
    }
  }, [items]);

  return (
    <BasketRecommendedContext.Provider value={state}>
      {shoppingCard && shoppingCardId && state?.length > 0 && children}
    </BasketRecommendedContext.Provider>
  );
};
