import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import FeedbackForm from '../components/Feedback';
import ContactsContent from '../components/Contacts';
import Section from '../components/Section/Section';
import Meta from '../components/Meta';

const Contacts = () => {
  return (
    <>
      <Meta url="/contacts" />
      <Breadcrumbs />
      <Section className="contacts">
        <ContactsContent />
      </Section>
      <FeedbackForm />
    </>
  );
};

export default Contacts;
