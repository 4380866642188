/* eslint-disable no-unused-vars */
import { useContext } from 'react';
import { GlobalBasketContext } from '../../../context/GlobalBasketContext';
import utilities from '../../../utilities';
import Counter from '../../UI/Counter';

export default function BasketOrderCounter({ order }) {
  const { update, remove } = useContext(GlobalBasketContext);

  const handleCounterChange = (count, direction) => {
    if (count === 0) {
      remove({ ...order, dish_id: order.item_id });
    } else {
      update({ ...order, dish_id: order.item_id }, { count, direction });
    }
  };

  return (
    <div className="order-counter">
      <span className="order-termin">
        {`${utilities.translate('Количество')}: `}
      </span>
      <Counter initialCount={order.count} onChange={handleCounterChange} />
    </div>
  );
}
