/* eslint-disable no-unused-vars */
import { useEffect, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { YMaps } from 'react-yandex-maps';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useWindowSize from './hooks/useResize';
import Footer from './components/Footer/Footer';
import Footnote from './components/Footnote/Footnote';
import Header from './components/Header';
import Main from './components/Main/Main';
import Circle from './components/RoutesCircle';
import { AccountContextProvider } from './context/Account';
import { EstablishmentsContextProvider } from './context/Establishments';
import { GlobalBasketProvider } from './context/GlobalBasketContext';
import utilities from './utilities';
import Cookies from './components/Cookies';
import SertificatePopup from './components/SertificatePopup';

const MAPS_LANGUAGE = {
  ru: 'ru_RU',
  en: 'en_US',
};

const html = document.documentElement;

const App = () => {
  const { windowWidth, isDesktop } = useWindowSize();

  useEffect(() => {
    [, document.documentElement.lang] = window.location.pathname.split('/');
  }, []);

  useEffect(() => {
    if (isDesktop) {
      html.style.setProperty(
        '--max-width',
        `${Math.round(windowWidth * 0.8)}px`
      );
    } else {
      html.style.setProperty('--max-width', `${Math.round(windowWidth)}px`);
    }
  }, [windowWidth]);

  useEffect(() => {
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isYandex = navigator.userAgent.search(/YaBrowser/) > 0;
    if (utilities.cookie.get('sertificate')) {
      return null;
    }
    if (isYandex) {
      return utilities.cookie.set('sertificate', false);
    }
    if (isSafari || isChrome) {
      return utilities.cookie.set('sertificate', true);
    }
    return utilities.cookie.set('sertificate', false);
  });

  useLayoutEffect(() => {
    utilities.start.init();
  }, []);

  return (
    <Router>
      <Switch>
        <HelmetProvider>
          <Helmet>
            <title>Мадьяр Collection - сеть ресторанов в Краснодаре</title>
            <meta
              name="description"
              content="Список заведений, мероприятия, контактная информация."
            />
          </Helmet>
          <EstablishmentsContextProvider>
            <AccountContextProvider>
              <GlobalBasketProvider>
                <YMaps query={{ lang: MAPS_LANGUAGE[utilities.getLang()] }}>
                  <ParallaxProvider>
                    <Header />
                    <Main />
                    <Footer />
                    <Circle />
                    <Footnote />
                    <Cookies />
                    <SertificatePopup />
                  </ParallaxProvider>
                </YMaps>
              </GlobalBasketProvider>
            </AccountContextProvider>
          </EstablishmentsContextProvider>
        </HelmetProvider>
      </Switch>
    </Router>
  );
};

export default App;
