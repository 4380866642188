/* eslint-disable import/prefer-default-export */
import { serialize } from 'object-to-formdata';
import queryString from 'query-string';

function isRestricted(keys, restrictedName) {
  return keys.includes(restrictedName);
}

function parseFields(fields, single = false) {
  if (!fields?.length) return '';
  return `${single ? '?' : '&'}fields=${fields.join(',')}`;
}

function parseSort(sort, single = false) {
  if (!sort) return '';
  return `${single ? '?' : '&'}sort=${sort.join(',')}`;
}

function parseCount(count, single = false) {
  if (typeof count === 'undefined') return '';
  return `${single ? '?' : '&'}count=${count}`;
}

function parseWith(withField, single = false) {
  if (!withField?.length) return '';
  return `${single ? '?' : '&'}with=${withField.join(',')}`;
}

function parseModification(param) {
  if (!param) return '';
  return `/${param.join('/')}`;
}

function parseSimple(params) {
  if (Object.keys(params).length > 0) {
    return `?${Object.entries(params)
      .filter(([, value]) => {
        if (value) {
          if (Array.isArray(value)) {
            return value.length;
          }
          return value;
        }
        return value;
      })
      .reduce((acc, [key, value]) => {
        let item;
        if (value) {
          if (Array.isArray(value)) {
            item = value
              .map((valueItem) => {
                return `${key}[]=${valueItem}`;
              })
              .join('&');
          } else item = `${key}=${value}`;
          acc.push(item);
        }
        return acc;
      }, [])
      .join('&')}`;
  }
  return '';
}

export function parse(params) {
  if (!params) return '';
  const keys = Object.keys(params);
  if (!keys || !keys.length) return '';
  if (keys.length === 1) {
    if (isRestricted(keys, 'modification'))
      return parseModification(params.modification);
    if (isRestricted(keys, 'fields')) return parseFields(params.fields, true);
    if (isRestricted(keys, 'sort')) return parseSort(params.sort, true);
    if (isRestricted(keys, 'count')) return parseCount(params.count, true);
    if (isRestricted(keys, 'with')) return parseWith(params.with, true);
    return parseSimple(params, true);
  }
  const {
    fields,
    with: withField,
    sort,
    modification,
    count,
    ...rest
  } = params;
  const { length: restLength } = Object.keys(rest);
  return `${parseModification(modification)}${parseSimple(rest)}${parseFields(
    fields,
    !restLength
  )}${parseSort(sort, !(restLength || fields?.length))}${parseCount(
    count,
    !(restLength || fields?.length || sort)
  )}${parseWith(withField, !(restLength || fields?.length || sort || count))}`;
}

export function toFormData(object) {
  return JSON.stringify(object);
}

export function toObject(search) {
  return queryString.parse(search, {
    arrayFormat: 'bracket',
    parseNumbers: false,
    parseBooleans: true,
  });
}

function removeFalsy(object) {
  return Object.keys(object).reduce((acc, key) => {
    if (object[key]) {
      if (Array.isArray(object[key]) && object[key].length > 0) {
        acc[key] = object[key];
      } else {
        acc[key] = object[key];
      }
    }
    return acc;
  }, {});
}

export function toString(object) {
  return queryString.stringify(removeFalsy(object), {
    arrayFormat: 'comma',
    skipNull: true,
    skipEmptyString: true,
  });
}

export function serializeParams(object) {
  return serialize(object);
}
