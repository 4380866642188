import React from 'react';
import ReactDOM from 'react-dom';
import 'swiper/swiper.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './fonts/fonts.css';
import './styles/styles.scss';
import App from './App';
import utilities from './utilities';

const { body } = document;

utilities.api.init();

body.classList.add('body--animated');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
