/* eslint-disable no-unused-vars */
import cn from 'classnames';
import { forwardRef, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import routes from '../../routes';
import utilities from '../../utilities';
import Description from '../Description/Description';
import Title from '../Title/Title';

const isSlide = (type) => type === 'slide';

const RestaurantTeaser = (props, ref) => {
  const {
    data,
    observable,
    onClick,
    isVisible,
    type = false,
    isActive,
    menuLink = false,
  } = props;
  const [pictures, setPictures] = useState(null);

  useEffect(() => {
    if (data?.images?.length > 0) {
      const { images } = data;
      setPictures(images.filter(({ file_type: fileType }) => fileType === 4));
      setPictures(
        images.reduce((acc, item) => {
          const { file_type: fileType, file } = item;
          if (fileType === 4) {
            acc.coverImage = file;
          }
          return acc;
        }, {})
      );
    }
  }, []);

  const teaserClassnames = cn('restaurant-teaser', {
    'restaurant-teaser--observable': observable,
    'restaurant-teaser--visible': observable ? isVisible : false,
    [`restaurant-teaser--${type}`]: type,
    [`restaurant-teaser--active`]: isActive,
  });

  return (
    <article ref={ref} className={teaserClassnames}>
      {pictures && (
        <img
          className="restaurant-teaser__image"
          src={pictures.coverImage}
          alt=""
        />
      )}
      <div className="restaurant-teaser__inner">
        {isSlide() && (
          <Title
            className="restaurant-teaser__title"
            color={isSlide(type) ? 'stroke' : 'white'}
            size="large"
            isHidden={!isSlide(type)}
          >
            {data.name}
          </Title>
        )}
        <NavLink
          onClick={(event) => {
            if (onClick) {
              event.preventDefault();
              onClick({ redirect: routes.page.restaurant(data.slug, 'ru') });
            }
          }}
          className="restaurant-teaser__more"
          to={
            menuLink
              ? routes.page.dishes(data.slug)
              : routes.page.restaurant(data.slug, 'ru')
          }
        >
          Подробнее
        </NavLink>
        {!isSlide(type) && (
          <img
            className="restaurant-teaser__logo"
            src={data?.logo_black || data.logo}
            alt=""
          />
        )}
        <Description className="restaurant-teaser__description" color="white">
          {`${data.description.slice(0, 100)}...`}
        </Description>
        {!isSlide(type) && data.address && (
          <div className="restaurant-teaser__address">
            {data.address?.street}
          </div>
        )}
      </div>
    </article>
  );
};

export default forwardRef(RestaurantTeaser);
