import cn from 'classnames';

const baseClassName = 'description';

const Description = (props) => {
  const { children, className, align, color, size, dangerHTML = false } = props;

  const descriptionClassName = cn(className, baseClassName, {
    [`${baseClassName}--${align}`]: align,
    [`${baseClassName}--${color}`]: color,
    [`${baseClassName}--${size}`]: size,
  });

  return (
    <div
      className={descriptionClassName}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={dangerHTML ? { __html: dangerHTML } : null}
    >
      {dangerHTML ? null : children}
    </div>
  );
};

export default Description;
