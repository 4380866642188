/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import Dish from './Dish';
import Title from '../../Title/Title';

export default function FavoriteDishCategory({ categories, teasers }) {
  const sluggedTeasers = teasers.map((teaser) => {
    return {
      ...teaser,
      slug: categories?.find((category) => category.id === teaser.category_id)
        .slug,
    };
  });

  const filteredCategories = categories.filter((category) =>
    sluggedTeasers.some(({ slug }) => slug === category.slug)
  );

  const data = filteredCategories.map((category) => {
    const filteredTeasers = sluggedTeasers.filter(
      (teaser) => category.slug === teaser.slug
    );
    return {
      id: category.id,
      slug: category.slug,
      title: category.name,
      cards: filteredTeasers,
    };
  });

  const dataUnique = [
    ...new Map(data.map((item) => [item.slug, item])).values(),
  ];

  return dataUnique.map(({ id, title, cards }) => (
    <div id={`dish-${id}`} className="food-teasers" key={id}>
      <Title className="food-teasers__title">{title}</Title>
      {cards.map((card) => (
        <Dish data={card} key={card.id} />
      ))}
    </div>
  ));
}
