import { createContext, useEffect, useState } from 'react';
import routes from '../../routes';
import utilities from '../../utilities';

export const AccountContext = createContext();

export const AccountContextProvider = (props) => {
  const { children } = props;
  const [state, setState] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const id = utilities.storage.get('madyar-id');
    const token = utilities.storage.get('madyar-auth-token');
    const fetchAccount = async () => {
      const account = await utilities.api.get(
        routes.apiSwagger.account(),
        {},
        {
          Authorization: `Token ${token}`,
        }
      );
      setState(account);
    };
    if (id && token) {
      fetchAccount();
    }
  }, []);

  return (
    <AccountContext.Provider value={{ account: state, setAccount: setState }}>
      {children}
    </AccountContext.Provider>
  );
};
