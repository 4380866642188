/* eslint-disable no-unused-vars */
import { useLayoutEffect, useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Account from '../../pages/Account';
import Activities from '../../pages/Activities';
import Basket from '../../pages/Basket';
import Catering from '../../pages/Catering';
import Collaboration from '../../pages/Collaboration';
import Contacts from '../../pages/Contacts';
import Dishes from '../../pages/Dishes';
import Event from '../../pages/Event';
import Home from '../../pages/Home';
import NotFound from '../../pages/404';
import LegalDocuments from '../../pages/LegalDocuments';
import MobileApplication from '../../pages/MobileApplication';
import NewsItem from '../../pages/NewsItem';
import OrdersHistory from '../../pages/OrdersHistory';
import Restaurant from '../../pages/Restaurant';
import Restaurants from '../../pages/Restaurants';
import Search from '../../pages/Search';
import Vacancies from '../../pages/Vacancies';
import Team from '../../pages/Team';
import Vacancy from '../../pages/Vacancy';
import routes from '../../routes';
import utilities from '../../utilities';
import CSSTransitionRoute from './CSSTransitionRoute';
import Payment from '../../pages/Payment';
import FavoriteDishes from '../../pages/Favorite';

const BASKET_STORAGE_NAMES = [
  'madyar-basket-dishes',
  'madyar-basket-total',
  'madyar-shopping-card',
  'madyar-shopping-establishment',
];

const ROUTES = [
  {
    path: routes.page.home({ link: false }),
    Component: Home,
  },
  // {
  //   path: routes.page.catering({ link: false }),
  //   Component: Catering,
  // },
  {
    path: routes.page.payment({ link: false }),
    Component: Payment,
  },
  {
    path: [
      routes.page.news({ link: false }),
      routes.page.events({ link: false }),
    ],
    Component: Activities,
  },
  {
    path: routes.page.event(null, false),
    Component: Event,
  },
  {
    path: routes.page.newsItem(null, false),
    Component: NewsItem,
  },
  {
    path: routes.page.restaurants({ link: false }),
    Component: Restaurants,
  },
  {
    path: routes.page.restaurant(null, false),
    Component: Restaurant,
  },
  // {
  //   path: routes.page.vacancies({ link: false }),
  //   Component: Vacancies,
  // },
  {
    path: routes.page.team({ link: false }),
    Component: Team,
  },
  // {
  //   path: routes.page.vacancy(null, false),
  //   Component: Vacancy,
  // },
  {
    path: routes.page.contacts({ link: false }),
    Component: Contacts,
  },
  {
    path: routes.page.documents({ link: false }),
    Component: LegalDocuments,
  },
  {
    path: routes.page.document(null, false),
    Component: LegalDocuments,
  },
  {
    path: routes.page.search({ link: false }),
    Component: Search,
  },
  {
    path: routes.page.dishes(null, false),
    Component: Dishes,
  },
  {
    path: routes.page.mobileApplication({ link: false }),
    Component: MobileApplication,
  },
  {
    path: routes.page.basket({ link: false }),
    Component: Basket,
  },
  {
    path: routes.page.collaboration({ link: false }),
    Component: Collaboration,
  },
  {
    path: routes.page.ordersHistory({ link: false }),
    render: () => {
      if (utilities.isLogged()) return <OrdersHistory />;
      return <Redirect to={routes.page.home()} />;
    },
  },
  {
    path: routes.page.account({ link: false }),
    render: () => {
      if (utilities.isLogged()) return <Account />;
      return <Redirect to={routes.page.home()} />;
    },
  },
  {
    path: routes.page.favorite({ link: false }),
    render: () => {
      if (utilities.isLogged()) return <FavoriteDishes />;
      return <Redirect to={routes.page.home()} />;
    },
  },
];

const Main = () => {
  const firstMount = useRef(true);

  useLayoutEffect(() => {
    BASKET_STORAGE_NAMES.forEach((name) => {
      if (utilities.storage.get(name) === 'undefined')
        utilities.storage.remove(name);
    });
    firstMount.current = false;
  }, []);

  return (
    <Switch>
      {ROUTES.map(({ path, render, Component }) => (
        <Route
          key={path}
          exact
          path={path}
          // eslint-disable-next-line react/no-children-prop
          children={({ match }) => (
            <CSSTransitionRoute
              match={match}
              firstMount={firstMount.current}
              path={path}
              render={render}
              component={Component}
            />
          )}
        />
      ))}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Main;
