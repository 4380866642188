import { useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import routes from '../../routes';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';

const baseClassName = 'search-form';

const SearchForm = (props) => {
  const {
    className,
    parentclassname,
    canExpand,
    defaultInputValue = '',
    placeHolder,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [inputValue, setInputValue] = useState(defaultInputValue);
  const formEl = useRef(null);
  const inputEl = useRef(null);
  const history = useHistory();
  const restaurantPageMatch = useRouteMatch({
    path: routes.page.restaurant(null, false),
    exact: true,
  });
  const searchFormClassName = cn(
    className,
    parentclassname && `${parentclassname}__${baseClassName}`,
    baseClassName,
    {
      [`${baseClassName}--expanded`]: expanded,
      [`${baseClassName}--can-expand`]: canExpand,
    }
  );

  useEffect(() => {
    setInputValue(defaultInputValue);
  }, [defaultInputValue]);

  const onDocumentClick = (event) => {
    if (!formEl.current.contains(event.target)) {
      setExpanded(false);
      // eslint-disable-next-line no-use-before-define
      unsubscribeDocument();
    }
  };

  const unsubscribeDocument = () => {
    document.removeEventListener('click', onDocumentClick);
  };

  const subscribeDocument = () => {
    document.addEventListener('click', onDocumentClick);
  };

  const handleExpandClick = () => {
    setExpanded(true);
    subscribeDocument();
    inputEl.current.focus();
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setExpanded(false);
    if (restaurantPageMatch) {
      history.push(
        `${routes.page.search()}?establishment_id=${
          restaurantPageMatch.params.id
        }&search=${inputValue}`
      );
    } else {
      history.push(`${routes.page.search()}?search=${inputValue}`);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <form
      className={searchFormClassName}
      ref={formEl}
      onSubmit={handleFormSubmit}
    >
      <input
        className="search-form__input"
        placeholder={placeHolder}
        onChange={handleInputChange}
        value={inputValue}
        ref={inputEl}
      />
      {canExpand && (
        <button
          className="search-form__expand search-form__button"
          type="button"
          aria-label="Раскрыть"
          onClick={handleExpandClick}
        >
          <SearchIcon className="search-form__button-icon" />
        </button>
      )}
      <button
        className="search-form__submit search-form__button"
        type="submit"
        aria-label="Найти"
      >
        <SearchIcon className="search-form__button-icon" />
      </button>
    </form>
  );
};

export default SearchForm;
