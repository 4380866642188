/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useRouteMatch } from 'react-router-dom';
import EventTeaser from '../components/EventTeaser/EventTeaser';
import EventsPreloader from '../components/Events/Preloader';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Filter from '../components/Activities/Filter';
import Title from '../components/Title/Title';
import {
  ActivitiesContext,
  ActivitiesContextProvider,
  ActivitiesFilterContextProvider,
} from '../context/Activities';
import routes from '../routes';
import utilities from '../utilities';
import Section from '../components/Section/Section';
import Empty from '../components/Empty/Empty';
import Meta from '../components/Meta';

export default function Activities() {
  const newsMatch = useRouteMatch(routes.page.news());

  const generateTitle = () => {
    if (newsMatch) {
      return (
        <>
          {utilities.translate('Новости')}
          <Title.Link href={routes.page.events()} isReduced>
            {' '}
            / {utilities.translate('События')}
          </Title.Link>
        </>
      );
    }
    return (
      <>
        {utilities.translate('События')}
        <Title.Link href={routes.page.news()} isReduced>
          {' '}
          / {utilities.translate('Новости')}
        </Title.Link>
      </>
    );
  };
  return (
    <>
      <Meta url="/events" />
      <ActivitiesFilterContextProvider>
        <ActivitiesContextProvider entity={newsMatch ? 'news' : 'events'}>
          <Breadcrumbs />
          <Section
            observable={false}
            className="main-head main-head-activities"
          >
            <Title className="main-head__title" tag="h1" size="large">
              {generateTitle()}
            </Title>
            <Filter entity={newsMatch ? 'news' : 'events'} />
          </Section>
          <Section>
            <ActivitiesContext.Consumer>
              {(entities) =>
                entities?.items ? (
                  entities.items.length > 0 ? (
                    <div className="event-teasers">
                      {entities.items.map((teaser) => (
                        <EventTeaser key={teaser.id} data={teaser} />
                      ))}
                    </div>
                  ) : (
                    <Empty>Нет активностей, удовлетворяющих фильтру</Empty>
                  )
                ) : (
                  <EventsPreloader />
                )
              }
            </ActivitiesContext.Consumer>
          </Section>
        </ActivitiesContextProvider>
      </ActivitiesFilterContextProvider>
    </>
  );
}
