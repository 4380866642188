import { useRef, useEffect, useState, useMemo } from 'react';
import ContentLoader from 'react-content-loader';

export default function GridSkeleton(props) {
  const [parentWidth, setParentWidth] = useState(null);
  const mutationObserver = useRef(null);

  const {
    className,
    style,
    proportion,
    label,
    count,
    gap,
    width,
    columns,
  } = props;

  const holderRef = useRef(null);
  const gridRows = useRef(Math.ceil(count / columns));

  useEffect(() => {
    mutationObserver.current = new ResizeObserver(([{ contentRect }]) =>
      setParentWidth(contentRect?.width)
    );
  }, []);

  useEffect(() => {
    if (mutationObserver.current) {
      mutationObserver.current.observe(holderRef.current);
    }
  }, [mutationObserver]);

  const rectWidth = useMemo(() => {
    return width
      ? ((parentWidth - gap * (columns - 1)) * width) / 100
      : (parentWidth - (gap * columns - gap)) / columns;
  }, [parentWidth]);

  const rectHeight = useMemo(() => {
    return rectWidth * proportion;
  }, [rectWidth]);

  const svgHeight = useMemo(() => {
    return gridRows.current * (rectHeight + gap) - gap;
  }, [rectHeight]);

  let xCount = 0;
  let yCount = 0;

  const generatePositionX = () => {
    const result = xCount * (rectWidth + gap);
    if (xCount === columns - 1) {
      xCount = 0;
    } else {
      xCount += 1;
    }
    return result;
  };

  const generatePositionY = (index) => {
    const result = yCount * (rectHeight + gap);
    if (columns !== 1 && columns !== 2) {
      if ((index + 1) % columns === 0 && index !== 1) {
        yCount += 1;
      }
    } else if ((index + 1) % columns === 0) {
      yCount += 1;
    }
    return result;
  };

  return (
    <>
      <div style={{ width: '100%' }} ref={holderRef}>
        {Boolean(parentWidth) && (
          <ContentLoader
            speed={2}
            width={parentWidth}
            height={svgHeight}
            title={label || 'Загрузка...'}
            viewBox={`0 0 ${parentWidth} ${svgHeight}`}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            className={className}
            style={style}
          >
            {Array.from(Array(count)).map((_, index) => (
              <rect
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                x={generatePositionX()}
                y={generatePositionY(index)}
                width={rectWidth}
                height={rectHeight}
              />
            ))}
          </ContentLoader>
        )}
      </div>
    </>
  );
}
