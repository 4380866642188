import { useLayoutEffect, useRef } from 'react';
import Marquee from 'react-fast-marquee';
import useWindowSize from '../../hooks/useResize';

export default function FooterMarquee() {
  const marqueeRef = useRef();
  const { windowWidth } = useWindowSize();
  useLayoutEffect(() => {
    marqueeRef.current.style.setProperty(
      '--font-size',
      `${Math.round(marqueeRef.current.clientHeight / 3)}px`
    );
  }, [windowWidth]);
  return (
    <div ref={marqueeRef} className="footer-marquee-holder">
      <Marquee style={{ position: 'absolute' }} className="footer-marquee">
        MADYAR MADYAR
      </Marquee>
      <Marquee
        direction="right"
        style={{ position: 'absolute', top: '33%' }}
        className="footer-marquee"
      >
        MADYAR MADYAR
      </Marquee>
      <Marquee
        style={{ position: 'absolute', top: '66%' }}
        className="footer-marquee"
      >
        MADYAR MADYAR
      </Marquee>
    </div>
  );
}
