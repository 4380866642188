/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { AccountHistoryContext } from '../../../context/Account/History';
import routes from '../../../routes';
import utilities from '../../../utilities';
import Empty from '../../Empty/Empty';
import BlockSkeleton from '../../Skeleton/Block';
import OrdersList from './List';

export default function Orders() {
  const [orders] = useContext(AccountHistoryContext);

  if (!orders) {
    return (
      <BlockSkeleton
        width="100%"
        height={60}
        count={2}
        gap={10}
        label="Загрузка истории"
      />
    );
  }

  if (orders.count === 0) {
    return <Empty>Нет заказов</Empty>;
  }

  return <OrdersList />;
}
