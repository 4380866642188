/* eslint-disable no-unused-vars */
import cn from 'classnames';
import React from 'react';
import routes from '../../../routes';
import Description from '../../Description/Description';
import Title from '../../Title/Title';

export default function RestaurantPreview({ data, className }) {
  const { name, description, slug } = data;
  const classes = cn(
    'restaurant-teaser',
    'restaurant-teaser--slide',
    className
  );
  return (
    <article className={classes}>
      <div className="restaurant-teaser__inner">
        <Title
          // href={routes.page.restaurant(id)}
          className="restaurant-teaser__title"
          // isReduced
          color="stroke"
          size="large"
        >
          <Title.Link href={routes.page.restaurant(slug)}>{name}</Title.Link>
        </Title>
        <Description className="restaurant-teaser__description" color="white">
          {`${description.split(/[\\.?]/)[0]}.`}
        </Description>
      </div>
    </article>
  );
}
