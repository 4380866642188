import { useState, useEffect } from 'react';
// import useWindowSize from '../../hooks/useResize';
import Modal from '../Modal';
import Title from '../Title/Title';
import { ReactComponent as AppleIcon } from '../../icons/apple.svg';
import { ReactComponent as GoogleIcon } from '../../icons/google.svg';
import { ReactComponent as YandexIcon } from '../../icons/yandex.svg';
import utilities from '../../utilities';

export default function SertificatePopup() {
  const [isModalOpen, setModalOpen] = useState();
  // const { isPhone } = useWindowSize();

  useEffect(() => {
    setModalOpen(
      utilities.cookie.get('sertificate')
        ? JSON.parse(utilities.cookie.get('sertificate'))
        : true
    );
  }, [utilities.cookie.get('sertificate')]);

  useEffect(() => {
    if (utilities.cookie.get('sertificate')) {
      utilities.cookie.set('sertificate', false);
    }
  }, [isModalOpen]);

  return (
    <Modal state={[isModalOpen, setModalOpen]} contentClassName="sertificate">
      <Title className="sertificate__title" tag="h2">
        Ваше устройство <br /> не поддерживает <br /> российские сертификаты
      </Title>
      <p className="sertificate__text">
        Для безопасного доступа к сайту madyar.ru оформляйте покупки через{' '}
        <a className="sertificate__link" href="https://browser.yandex.ru">
          Яндекс.Браузер
        </a>{' '}
        или в{' '}
        <a
          className="sertificate__link"
          href="https://madyar.ru/ru/mobile_application"
        >
          Мобильном приложении Madyar
        </a>
      </p>
      <div className="sertificate-links__holder">
        <div className="sertificate__item">
          <p className="sertificate__description">Скачивайте наше приложение</p>
          <div>
            <a
              className="sertificate__button"
              href="https://apps.apple.com/us/app/madyar-доставка-еды/id1197249284"
            >
              <AppleIcon />
              AppStore
            </a>
            <a
              className="sertificate__button"
              href="https://play.google.com/store/apps/details?id=ru.spider.madyar.order&hl=en_US&gl=US"
            >
              <GoogleIcon />
              GooglePlay
            </a>
          </div>
        </div>
        <div className="sertificate__item">
          <p className="sertificate__description">Скачать</p>
          <a className="sertificate__button" href="https://browser.yandex.ru/">
            <YandexIcon />
            Яндекс.Браузер
          </a>
        </div>
      </div>
      <p className="sertificate__description">
        Или установите{' '}
        <a className="sertificate__link" href="https://www.gosuslugi.ru/tls">
          сертификаты минцифр
        </a>{' '}
        к себе на устройство и покупайте любимые блюда как обычно
      </p>
    </Modal>
  );
}
