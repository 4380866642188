/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from 'react';
import EventTeasers from '../components/Events/EventTeasers';
import About from '../components/Home/About';
import Delivery from '../components/Home/Delivery';
import Preview from '../components/Home/Preview';
import Restaraunts from '../components/Home/Restaraunts';
import Meta from '../components/Meta';
import routes from '../routes';
import utilities from '../utilities';
import Title from '../components/Title/Title';
import Section from '../components/Section/Section';

const Home = () => {
  const [state, setState] = useState(null);
  const requestId = useRef();
  const mounted = useRef(true);

  useEffect(() => {
    const fetchMain = async () => {
      requestId.current = utilities.api.generateRequestId();
      const response = await utilities.api.get(
        routes.api.main(),
        null,
        null,
        requestId.current
      );
      setState(response);
    };
    fetchMain();

    return () => {
      mounted.current = false;
      utilities.api.cancelRequestById(requestId.current);
    };
  }, []);

  return (
    <>
      <Meta url="/" />
      <Preview data={state?.slider} />
      <Restaraunts />
      <About data={state?.about} />
      <EventTeasers data={state?.events} />
      <Delivery data={state?.delivery} />
    </>
  );
};

export default Home;
