// @ts-check
import cn from 'classnames';
import { useMemo } from 'react';
import routes from '../../routes';
import utilities from '../../utilities';
import Description from '../Description/Description';
import More from '../More/More';
import Title from '../Title/Title';

const EventTeaser = (props) => {
  const { data, size } = props;
  const {
    id,
    title,
    announcement,
    text,
    date,
    price,
    type,
    name,
    logo,
    image,
    entity,
  } = data;

  const descriptionClassname = cn({
    'event-teaser__description': type,
  });

  const teaserClassName = cn('event-teaser', {
    [`event-teaser--${size}`]: size,
  });

  const descriptionText = useMemo(() => {
    if (announcement) {
      if (announcement.length > 200) {
        return `${announcement.slice(0, 200)}...`;
      }
      return announcement;
    }
    if (text) {
      if (text.length > 200) {
        return `${text.slice(0, 200)}...`;
      }
      return '';
    }
    return '';
  }, []);

  return (
    <article className={teaserClassName}>
      <div className="event-teaser-wrapper">
        {logo && <img className="event-teaser__logo" src={logo} alt="" />}
        <div className="event-teaser__image-holder">
          <img className="event-teaser__image" src={image} alt="" />
        </div>
        {name && <div className="event-teaser__author">{name}</div>}
        <Title className="event-teaser__title">
          {title.length > 68 ? `${title.slice(0, 68)}...` : title}
        </Title>
        <Description className={descriptionClassname}>
          {descriptionText}
        </Description>
        <div className="event-teaser__footer">
          {price && (
            <div className="event-teaser__price">
              {price
                ? `${utilities.translate(
                    'Вход на мероприятие'
                  )}: ${price} ${utilities.translate('руб')}.`
                : `${utilities.translate('Вход свободный')}:`}
            </div>
          )}
          {entity && (
            <More
              className="event-teaser__more"
              href={routes.page[
                `${entity === 'events' ? 'event' : 'newsItem'}`
              ](id)}
            />
          )}
          {date && (
            <time className="event-teaser__date">
              {utilities.date.toString(date, { format: 'YYYY-MM-DD HH:mm:ss' })}
            </time>
          )}
        </div>
      </div>
    </article>
  );
};

export default EventTeaser;
