/* eslint-disable no-unused-vars */
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import SearchFilter from '../components/Search/Filter';
import SearchResults from '../components/Search/Results';
import Section from '../components/Section/Section';
import {
  SearchContextProvider,
  SearchContext,
  SearchFilterContextProvider,
} from '../context/Search';
import { ReactComponent as MoreIcon } from '../icons/load-more-search.svg';

const Search = () => {
  return (
    <>
      <Breadcrumbs />
      <SearchFilterContextProvider>
        <SearchContextProvider>
          <Section column className="search-section">
            <SearchFilter />
            <SearchResults />
            <SearchContext.Consumer>
              {({ showMore, results, next }) => {
                if (!results) {
                  return null;
                }
                return next ? (
                  <button
                    onClick={showMore}
                    className="search-more-button"
                    type="button"
                  >
                    <MoreIcon className="search-more-icon" />
                  </button>
                ) : null;
              }}
            </SearchContext.Consumer>
          </Section>
        </SearchContextProvider>
      </SearchFilterContextProvider>
    </>
  );
};

export default Search;
