import React, {
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { EstablishmentsContext } from '../../../context/Establishments';
import utilities from '../../../utilities';
import useWindowSize from '../../../hooks/useResize';
import Checkbox from '../../UI/Form/Checkbox';

import useObserver from '../../../hooks/useIntersectionObserver';
import useScrollDirection from '../../../hooks/useScrollDirection';

export default function EstablishmentFilter({ onChange, initialSearch }) {
  const establishments = useContext(EstablishmentsContext);
  const ref = useRef();
  const elementsRefs = useRef([]);
  const location = useLocation();
  const { search } = location;
  const firstMount = useRef();
  const { direction } = useScrollDirection();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [isFilterPinned, setFilterPinned] = useState(true);
  const { isDesktop, isPhone } = useWindowSize();
  const defaultChecked = useMemo(() => {
    if (initialSearch.current.establishment_id) {
      return parseInt(initialSearch.current.establishment_id, 10);
    }
    return '';
  }, []);

  const onIntersection = ([entry]) => {
    if (entry?.intersectionRatio === 1) {
      setShouldAnimate(false);
    } else if (!shouldAnimate && entry?.intersectionRatio < 0.97) {
      setFilterPinned(false);
      setShouldAnimate(true);
    }
  };

  const { setObservable, destroyObservable } = useObserver({
    callback: onIntersection,
    options: {
      rootMargin: '-120px 0px 0px 0px',
      threshold: 1,
    },
  });

  const filterClassName = cn('filter', 'filter--sticky', {
    'filter--pinned': shouldAnimate && isFilterPinned && isDesktop,
    'filter--sticked': shouldAnimate && isDesktop,
  });

  useEffect(() => {
    if (shouldAnimate) {
      setFilterPinned(!direction);
    }
  }, [direction]);

  useLayoutEffect(() => {
    if (!isPhone) {
      setObservable(ref.current);
    } else {
      destroyObservable();
    }
  }, []);

  useEffect(() => {
    if (!firstMount.current) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [search]);

  useEffect(() => {
    firstMount.current = false;

    return () => {
      firstMount.current = true;
    };
  }, []);

  useLayoutEffect(() => {
    if (isPhone && initialSearch.current.establishment_id) {
      const establishmentId = initialSearch.current.establishment_id;
      const index = elementsRefs.current.findIndex(
        (item) => item.input.dataset.target === establishmentId.toString()
      );
      ref.current.scrollLeft = elementsRefs.current[index].holder.offsetLeft;
    }

    return () => {
      elementsRefs.current = [];
    };
  }, []);

  return (
    <div ref={ref} className={filterClassName}>
      {[
        { name: `${utilities.translate('Все рестораны')}`, id: '' },
        ...establishments,
      ].map((item, index) => (
        <Checkbox
          key={item.id || 0}
          aside={false}
          value={item.id}
          onValueChange={onChange}
          dataset={{
            target: item.id,
          }}
          ref={(element) => {
            elementsRefs.current[index] = element;
          }}
          defaultChecked={item.id === defaultChecked}
          type="radio"
          holderClassName="search-filter__checkbox"
          name="search-establishment"
          labelClassName="search-filter__checkbox-label"
          label={item.name}
        />
      ))}
    </div>
  );
}
