import { useEffect, useLayoutEffect, useRef } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';
import { ModalContextProvider } from '../../context/Modal';
import { ReactComponent as MadyarLogoIcon } from '../../icons/madyar-logo.svg';

Modal.setAppElement('body');

export default function ModalComponent(props) {
  const { state, children, className, contentClassName, onClose, data } = props;
  const [isOpen, setOpen] = state;
  const firstMount = useRef(true);
  const menuNode = useRef();

  useEffect(() => {
    if (!firstMount.current) {
      if (isOpen) {
        document.dispatchEvent(new CustomEvent('circle:hide'));
      } else {
        document.dispatchEvent(new CustomEvent('circle:show'));
      }
    }
  }, [isOpen]);

  useEffect(() => {
    firstMount.current = false;
    return () => {
      firstMount.current = true;
    };
  }, []);

  useLayoutEffect(() => {
    menuNode.current = document.querySelector('.menu');
  }, []);

  useEffect(() => {
    return () => {
      menuNode.current = false;
    };
  }, []);

  if (!isOpen) {
    return null;
  }

  const onAfterClose = () => {
    if (menuNode.current.classList.contains('menu--active')) {
      document.body.classList.add('body--stuck');
    }
  };

  const modalClassName = cn(className, 'portal__content');
  const contentClassNames = cn('portal__inner-content', contentClassName);

  return (
    <Modal
      bodyOpenClassName="body--stuck"
      portalClassName="portal"
      onAfterClose={onAfterClose}
      className={modalClassName}
      overlayClassName="portal__overlay"
      onRequestClose={() => {
        setOpen(false);
      }}
      isOpen={isOpen}
    >
      <MadyarLogoIcon className="portal__logo" />
      <div className={contentClassNames}>
        <ModalContextProvider state={state}>{children}</ModalContextProvider>
      </div>
      <button
        onClick={
          onClose
            ? () => {
                onClose(data);
              }
            : () => {
                setOpen(false);
              }
        }
        className="portal__close"
        type="button"
      >
        Закрыть
      </button>
    </Modal>
  );
}
