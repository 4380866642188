import cn from 'classnames';
import { useEffect, useState, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import HeaderBasket from './Basket';
import HeaderNavigation from './Navigation';
import favoriteBlackSvg from '../../icons/favorite-black.svg';
import favoriteSvg from '../../icons/favorite.svg';
import routes from '../../routes';
import { AccountContext } from '../../context/Account';

const Header = (props) => {
  const {
    menuOpen,
    onMenuButtonClick,
    isButtonHamburger,
    setButtonHamburger,
    setMenuButtonAnimating,
    isMenuButtonAnimating,
    isMenuButtonClose,
    setMenuButtonClose,
  } = props;
  const menuOpenButton = useRef();
  const [isMenuButtonDisabled, setMenuButtonDisabled] = useState();
  const menuOpenButtonClassnames = cn('header__menu-button', 'menu-button', {
    'menu-button--hamburger-animating': isButtonHamburger,
    'menu-button--hamburger': !menuOpen && !isButtonHamburger,
    'menu-button--hamburger-hide': menuOpen,
    'menu-button--close-animating': isMenuButtonAnimating,
    'menu-button--close': isMenuButtonClose && menuOpen,
    'menu-button--hide': !menuOpen && isMenuButtonClose,
  });
  const { account } = useContext(AccountContext);

  const onMenuAnimating = ({ detail }) => {
    setMenuButtonDisabled(detail);
  };

  const onAnimationStart = (event) => {
    if (event.animationName === 'menuButtonHamburgerHide') {
      setMenuButtonAnimating(true);
    } else if (event.animationName === 'menuButtonHamburgerShow') {
      setMenuButtonAnimating(false);
    }
  };

  const onAnimationEnd = (event) => {
    if (event.animationName === 'menuButtonHamburgerHide') {
      setMenuButtonClose(true);
      setMenuButtonAnimating(false);
    }
    if (!menuOpen) {
      setButtonHamburger(false);
    }
  };

  useEffect(() => {
    document.addEventListener('menu:animating', onMenuAnimating);
    menuOpenButton.current.addEventListener('animationend', onAnimationEnd);
    menuOpenButton.current.addEventListener('animationstart', onAnimationStart);
  }, []);

  return (
    <header className="header">
      <div className="header__inner">
        <button
          className={menuOpenButtonClassnames}
          type="button"
          aria-label="Открыть меню"
          disabled={isMenuButtonDisabled}
          onClick={onMenuButtonClick}
          ref={menuOpenButton}
        />
        <HeaderNavigation />
        {account && (
          <NavLink to={routes.page.favorite()} className="header__favorite">
            <img
              src={favoriteSvg}
              className="header__favorite-icon"
              alt="favoriteIcon"
            />
            <img
              src={favoriteBlackSvg}
              className="header__favorite-icon_black"
              alt="favoriteIcon"
            />
          </NavLink>
        )}
        <HeaderBasket />
      </div>
    </header>
  );
};

export default Header;
