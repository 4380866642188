// /* eslint-disable import/prefer-default-export */
import * as storage from './localStorage';

export const BASKET_DISHES_KEY = 'madyar-basket-dishes';
export const BASKET_TOTAL_KEY = 'madyar-basket-total';
export const SHOPPING_CARD_KEY = 'madyar-shopping-card';
export const SHOPPING_ESTABLISHMENT_KEY = 'madyar-shopping-establishment';

export function setDishes(dishes) {
  if (dishes) storage.set(BASKET_DISHES_KEY, dishes);
}

export function getDishes() {
  return storage.get(BASKET_DISHES_KEY);
}

export function removeDishes() {
  storage.remove(BASKET_DISHES_KEY);
}

export function setCardKeyId(id) {
  if (id) storage.set(SHOPPING_CARD_KEY, id);
}

export function getCardKeyId() {
  return storage.get(SHOPPING_CARD_KEY);
}

export function removeCardKeyId() {
  storage.remove(SHOPPING_CARD_KEY);
}

export function setTotal(total) {
  if (total) storage.set(BASKET_TOTAL_KEY, total);
}

export function getTotal() {
  return storage.get(BASKET_TOTAL_KEY);
}

export function removeTotal() {
  storage.remove(BASKET_TOTAL_KEY);
}

export function setEstablishmentId(id) {
  if (id) storage.set(SHOPPING_ESTABLISHMENT_KEY, id);
}

export function getEstablishmentId() {
  return storage.get(SHOPPING_ESTABLISHMENT_KEY);
}

export function removeEstablishmentId() {
  storage.remove(SHOPPING_ESTABLISHMENT_KEY);
}

export function removeShoppingCard() {
  storage.remove(SHOPPING_CARD_KEY);
}

export function reset() {
  removeDishes();
  removeTotal();
  removeCardKeyId();
  removeShoppingCard();
  removeEstablishmentId();
}

export function removeDish(id, propertyId) {
  const items = storage.get(BASKET_DISHES_KEY);
  if (propertyId) {
    if (Object.keys(items[id]).length > 1) {
      delete items[id][propertyId];
    } else {
      delete items[id];
    }
  } else {
    delete items[id];
  }
  storage.set(BASKET_DISHES_KEY, items);
}
