import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '../../../UI/Form/Checkbox';
import Change from './Change';
import { CASH_PAYMENT_TYPE } from './helpers';
import utilities from '../../../../utilities';

export default function Cash() {
  const { control, watch } = useFormContext();
  const watcher = watch('payment_type');
  const deliveryTypeWatcher = watch('delivery_type');
  return (
    <div className="payment-method-wrapper">
      <Controller
        control={control}
        name="payment_type"
        render={({ field }) => (
          <Checkbox
            type="radio"
            defaultChecked
            value={CASH_PAYMENT_TYPE}
            name={field.name}
            onValueChange={field.onChange}
            label={
              deliveryTypeWatcher === '0'
                ? utilities.translate('Наличными курьеру')
                : utilities.translate('На месте')
            }
            id="cash"
          />
        )}
      />
      {watcher === CASH_PAYMENT_TYPE && deliveryTypeWatcher === '0' && (
        <Change />
      )}
    </div>
  );
}
