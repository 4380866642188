/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import routes from '../../routes';
import utilities from '../../utilities';
import Feasts from './Feasts';
import Head from './Head';
import Orders from './Orders';

const Catering = () => {
  const [state, setState] = useState();
  useEffect(() => {
    const fetchCateringData = async () => {
      const response = await utilities.api.get(routes.api.catering());
      setState(response);
    };
    fetchCateringData();
  }, []);
  return (
    <>
      <Head info={state?.block_1} />
      <Orders info={state?.block_2} />
      <Feasts info={state} />
    </>
  );
};

export default Catering;
