import Locations from './Locations';
import Section from '../../Section/Section';
import { LocationsContextProvider } from '../../../context/Bindings/Locations';
import utilities from '../../../utilities';

export default function Bindings() {
  return (
    <>
      <LocationsContextProvider>
        <article className="account-addresses">
          <Section.Title>{utilities.translate('Мои адреса')}</Section.Title>
          <Locations />
        </article>
      </LocationsContextProvider>
    </>
  );
}
