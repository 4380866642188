import { createContext } from 'react';
import useBasket from '../../hooks/useBasket';

export const GlobalBasketContext = createContext();

export const GlobalBasketProvider = ({ children }) => {
  const basketData = useBasket();
  return (
    <GlobalBasketContext.Provider value={basketData}>
      {children}
    </GlobalBasketContext.Provider>
  );
};
