import { useState } from 'react';
import routes from '../../routes';
import utilities from '../../utilities';
import Button from '../Button/Button';
import More from '../More/More';

export default function Cookies() {
  const [isCookieExist, setIsCookieExist] = useState(
    utilities.cookie.get('madyar-cookies')
  );

  if (isCookieExist) {
    return null;
  }

  const onClick = () => {
    utilities.cookie.set('madyar-cookies', true, { months: 1 });
    setIsCookieExist(true);
  };

  return (
    <div className="cookies">
      <div className="cookies__title">Cookies</div>
      <div className="cookies__text">
        {utilities.translate(
          'Мы используем Cookies, чтобы делать сайт лучше. Оставаясь на сайте, вы даете согласие на использование нами файлов Cookies.'
        )}
      </div>
      <Button phoneWidth="auto" phoneSize="small" onClick={onClick}>
        Ok
      </Button>
      <More href={routes.page.documents()} />
    </div>
  );
}
