/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';
import useObserver from '../../../hooks/useIntersectionObserver';

export default function RestaurantsVideo({ activeEstablishment }) {
  const [toggle, setToggle] = useState(false);
  const [firstImage, setFirstImage] = useState('');
  const [secondImage, setSecondImage] = useState('');

  const ref = useRef();
  const onIntersect = ([entry]) => {
    if (entry.isIntersecting) ref.current.play();
    else if (ref.current) ref.current.pause();
  };

  const { setObservable, destroyObservable } = useObserver({
    callback: onIntersect,
    options: {
      threshold: 0.5,
    },
  });

  const firstImageClasses = cn('home-restaurants-video', {
    'home-restaurants-video--hidden': !toggle,
  });
  const secondImageClasses = cn('home-restaurants-video', {
    'home-restaurants-video--hidden': toggle,
  });

  useLayoutEffect(() => {
    setObservable(ref.current);
  }, []);

  useEffect(() => {
    setToggle(!toggle);
    if (!toggle && activeEstablishment?.headers.length > 0) {
      setFirstImage(
        activeEstablishment.headers.reduce((acc, item) => {
          const { file_type: fileType, file } = item;
          if (fileType === 0) {
            acc.coverImage = file;
          }
          return acc;
        }, {}).coverImage
      );
    } else if (toggle && activeEstablishment?.headers.length > 0) {
      setSecondImage(
        activeEstablishment.headers.reduce((acc, item) => {
          const { file_type: fileType, file } = item;
          if (fileType === 0) {
            acc.coverImage = file;
          }
          return acc;
        }, {}).coverImage
      );
    } else if (!toggle) {
      setFirstImage('/');
    } else if (toggle) {
      setSecondImage('/');
    }
  }, [activeEstablishment]);

  useEffect(() => {
    return () => {
      destroyObservable();
    };
  }, []);
  return (
    <div className="home-restaurants-video-holder">
      <img className={firstImageClasses} src={firstImage} alt="Рестораны" />
      <img className={secondImageClasses} src={secondImage} alt="Рестораны" />
      {/* <video
        className="home-restaurants-video"
        ref={ref}
        muted
        playsInline
        // src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        src="/videos/swiper-video.mp4"
      /> */}
    </div>
  );
}
