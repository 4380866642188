/* eslint-disable camelcase */
import {
  createContext,
  useMemo,
  useRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import routes from '../../routes';
import utilities from '../../utilities';
import { EstablishmentsContext } from '../Establishments';

export const ActivitiesContext = createContext();
export const ActivitiesFilterContext = createContext();

export const ActivitiesFilterContextProvider = (props) => {
  const { children } = props;
  const [params, setParams] = useState(null);

  return (
    <ActivitiesFilterContext.Provider value={[params, setParams]}>
      {children}
    </ActivitiesFilterContext.Provider>
  );
};

export const ActivitiesContextProvider = (props) => {
  const { children, entity } = props;
  const [state, setState] = useState({});
  const [params] = useContext(ActivitiesFilterContext);
  const establishments = useContext(EstablishmentsContext);
  const entityPromiseResolve = useRef(null);
  const entityPromise = useMemo(() => {
    return new Promise((resolve) => {
      entityPromiseResolve.current = resolve;
    });
  }, [entity, params]);

  useEffect(() => {
    const fetchEntity = async () => {
      const response = await utilities.api.get(routes.api[entity](), params);
      entityPromiseResolve.current(response);
    };
    setState(state.years ? { years: state.years } : {});
    fetchEntity();
  }, [params, entity]);

  const setEntity = () => {
    entityPromise.then(({ [`${entity}`]: results, ...rest }) => {
      setState({
        items: results.map((event) => {
          const { establishment_id } = event;
          const establishment = establishments.find(
            ({ id }) => id === +establishment_id
          );
          return {
            ...event,
            logo: establishment?.logo || '/images/madyarLogo.png',
            entity,
          };
        }),
        ...rest,
      });
    });
  };

  useEffect(() => {
    if (establishments) {
      setEntity();
    }
  }, [establishments, entity, params]);

  return (
    <ActivitiesContext.Provider value={state}>
      {children}
    </ActivitiesContext.Provider>
  );
};
