/* eslint-disable no-unused-vars */
import { useEffect, useState, useMemo } from 'react';
import utilities from '../utilities';

export default function useLocalStorageChange({ field = null }) {
  const [data, setData] = useState(utilities.storage.get(field));

  const onStorageEvent = () => {
    setData(utilities.storage.get(field));
  };

  useEffect(() => {
    window.addEventListener('storage', onStorageEvent);
    return () => {
      window.removeEventListener('storage', onStorageEvent);
    };
  }, []);
  return data;
}
