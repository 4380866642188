/* eslint-disable camelcase */
import { nanoid } from 'nanoid';
import ParallaxComponent from '../Parallax';
import useWindowSize from '../../hooks/useResize';
import Description from '../Description/Description';
import Title from '../Title/Title';
import utilities from '../../utilities';

const TeamSchool = (props) => {
  const { data } = props;
  const socials = [data.telegram_team];
  const phone = data.phone_number_team;
  const { isPhone, isTablet } = useWindowSize();

  return (
    <>
      <Title
        className="section__title swiper-container-title"
        size="huge"
        color="outlined"
      >
        {utilities.translate('Школа поваров')}
      </Title>
      <article className="team-school__offer">
        <ParallaxComponent
          disabled={isPhone || isTablet}
          y={['-50px', '100px']}
          className="team-school__image-holder"
        >
          <img
            className="team-school__image"
            src={data.cook_school_cover_team}
            alt={data.title_team}
          />
        </ParallaxComponent>
        <div className="team-school__content">
          <Description
            className="team-school__description"
            dangerHTML={data.cook_school_team}
          />
          {socials && (
            <ul className="team-school__socials">
              {socials?.map((social) => {
                return (
                  <li className="team-school__social" key={nanoid()}>
                    {social}
                  </li>
                );
              })}
              <a className="team-school__link" href={`tel:${phone}`}>
                {phone}
              </a>
            </ul>
          )}
        </div>
      </article>
    </>
  );
};

export default TeamSchool;
