import cn from 'classnames';

export default function Empty(props) {
  const { children, className } = props;
  const classNames = cn('empty--holder', className);

  return (
    <div className={classNames}>
      <div className="empty--text">{children}</div>
    </div>
  );
}
