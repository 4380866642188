import useWindowSize from '../../hooks/useResize';
import GridSkeleton from '../Skeleton/Grid';

const RestaurantPreloader = () => {
  const { isDesktop, isTablet } = useWindowSize();

  if (isDesktop) {
    return (
      <GridSkeleton
        count={4}
        columns={2}
        gap={20}
        proportion={1.3}
        label="Загрузка событий"
      />
    );
  }
  if (isTablet) {
    return (
      <GridSkeleton
        count={4}
        columns={2}
        gap={20}
        proportion={1.7}
        label="Загрузка событий"
      />
    );
  }
  return (
    <GridSkeleton
      count={2}
      columns={1}
      gap={15}
      proportion={1.5}
      label="Загрузка событий"
    />
  );
};

export default RestaurantPreloader;
