import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as ClipIcon } from '../../../icons/clip.svg';
import { ReactComponent as CloseIcon } from '../../../icons/closeButton.svg';
import utilities from '../../../utilities';

const File = () => {
  const { register, watch, setValue } = useFormContext();
  const watcher = watch('file');

  useEffect(() => {
    register('file');
  }, [register]);

  const clearFile = () => {
    setValue('file', '');
  };

  return (
    <div className="form__row form__row--row">
      <label className="form__upload" htmlFor="file">
        {watcher ? watcher.name : utilities.translate('или прикрепить файл')}
        {!watcher && <ClipIcon className="form__circle" />}
      </label>
      {watcher && <CloseIcon className="form__clear" onClick={clearFile} />}
      <input
        type="file"
        name="file"
        id="file"
        onChange={(event) => {
          setValue('file', event.target.files[0]);
        }}
        accept=".doc, .rtf, .docx, .odt, .pdf"
        className="form__file"
      />
    </div>
  );
};

export default File;
