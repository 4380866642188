/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import utilities from '../../../utilities';
import Button from '../../Button/Button';
import Modal from '../../Modal';
import CateringOrderForm from './CateringOrder';
import OrderCallback from './OrderCallback';
import Title from '../../Title/Title';

const SUCCESS_TEXT = {
  1: utilities.translate('Заявка на звонок принята. Мы скоро вам перезвоним.'),
  2: utilities.translate(
    'Бланк успешно отправлен. Мы ознакомимся с ним и перезвоним.'
  ),
};

export default function Actions() {
  const [cateringOrder, setCateringOrder] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [isOrderCallbackOpen, setOrdercallbackOpen] = useState(false);
  const [status, setStatus] = useState();

  return (
    <div className="orders__buttons">
      <OrderCallback
        state={[isOrderCallbackOpen, setOrdercallbackOpen]}
        onSubmutSuccess={() => {
          setStatus(1);
          setSuccessModalOpen(true);
        }}
      />
      <Modal state={[successModalOpen, setSuccessModalOpen]}>
        <Title>{SUCCESS_TEXT[status]}</Title>
      </Modal>
      <Button onClick={() => setCateringOrder(true)} className="orders__button">
        {utilities.translate('Заполнить бриф')}
      </Button>
      <Modal
        contentClassName="catering-form-modal"
        state={[cateringOrder, setCateringOrder]}
      >
        <CateringOrderForm
          onCallbackRequired={() => setOrdercallbackOpen(true)}
          onSubmutSuccess={() => {
            setStatus(2);
            setSuccessModalOpen(true);
          }}
        />
      </Modal>
    </div>
  );
}
