/* eslint-disable no-unused-vars */
import cn from 'classnames';
import BlockSkeleton from '../../Skeleton/Block';
import Slide from '../../Slide/Slide';
import Slider from '../../Slider';

export default function Preview({ data }) {
  const sliderClassNames = cn('home-slider-preview');

  if (!data) {
    return (
      <BlockSkeleton
        width="100%"
        height={430}
        className="section"
        label="Слайдер"
      />
    );
  }

  return (
    <Slider
      gap
      elements={data}
      autoplay={{
        delay: 4000,
      }}
      scrollbarShift
      scrollbarShrinked
      className={sliderClassNames}
      slide={Slide}
      slideProps={{
        className: 'home-slider-preview-slide',
      }}
      spaceBetween={30}
    />
  );
}
