import { useEffect, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import routes from '../../../../routes';
import utilities from '../../../../utilities';
import DeliveryOption from '../Option';
import DeliveryAddress from './Address';

export default function Address() {
  const { watch } = useFormContext();
  const watcher = watch('delivery_type');
  const locationsLoaded = useRef(false);
  const [locations, setLocations] = useState(null);
  const [fetchStatus, setFetchStatus] = useState({});
  const requestId = useRef(utilities.api.generateRequestId());

  const fetchLocations = async () => {
    try {
      const response = await utilities.api.get(
        routes.apiSwagger.locations(),
        {},
        {},
        requestId.current
      );
      setLocations(response.results);
      locationsLoaded.current = true;
    } catch (error) {
      if (
        error.response &&
        error.response?.data &&
        error.response?.data?.status
      ) {
        setFetchStatus({
          status: error.response.data.status,
          message: error.response.data.detail,
        });
      }
    }
  };

  useEffect(() => {
    if (watcher === '0' && !locationsLoaded.current) {
      fetchLocations();
    }
  }, [watcher]);

  useEffect(() => {
    return () => {
      utilities.api.cancelRequestById(requestId.current);
    };
  }, []);

  if (!watcher || watcher === '1') {
    return null;
  }

  return (
    <DeliveryOption label={`${utilities.translate('Куда доставить')}: `}>
      <DeliveryAddress locations={locations} fetchStatus={fetchStatus} />
    </DeliveryOption>
  );
}
