/* eslint-disable no-unused-vars */
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useWindowSize from '../../hooks/useResize';
import useScrollDirection from '../../hooks/useScrollDirection';
import routes from '../../routes';
import utilities from '../../utilities';
import Button from '../Button/Button';
import Description from '../Description/Description';
import Section from '../Section/Section';
import BlockSkeleton from '../Skeleton/Block';
import MultipleSkeleton from '../Skeleton/Multiple';
import TextSkeleton from '../Skeleton/Text';
import Title from '../Title/Title';

const RestaurantMenu = ({ restaurant, pictures }) => {
  const { isDesktop, isPhone } = useWindowSize();
  const sectionRef = useRef();
  const { id: establishmentId } = useParams();
  const { scrollPosition } = useScrollDirection();
  const showEventFired = useRef(false);
  const hideEventFired = useRef(false);

  useEffect(() => {
    if (sectionRef.current && scrollPosition) {
      if (scrollPosition > sectionRef.current.offsetTop - 180) {
        if (!showEventFired.current) {
          hideEventFired.current = false;
          window.dispatchEvent(
            new CustomEvent('showEstablishmentHeader', {
              detail: establishmentId,
            })
          );
          showEventFired.current = true;
        }
      } else if (!hideEventFired.current) {
        showEventFired.current = false;
        window.dispatchEvent(
          new CustomEvent('hideEstablishmentHeader', {
            detail: establishmentId,
          })
        );
        hideEventFired.current = true;
      }
    }
  }, [scrollPosition]);

  useLayoutEffect(() => {
    return () => {
      sectionRef.current = null;
      window.dispatchEvent(
        new CustomEvent('hideEstablishmentHeader', {
          detail: establishmentId,
        })
      );
    };
  }, []);

  return (
    <Section
      sectionRef={(element) => {
        sectionRef.current = element;
      }}
    >
      <div className="restaurant-menu-teaser">
        {pictures ? (
          <img
            className="restaurant-menu-teaser__image"
            src={pictures.restaurantMenu}
            alt=""
          />
        ) : (
          <BlockSkeleton
            width="100%"
            height={isDesktop ? 600 : 450}
            className="section"
            label="Загрузка истории"
          />
        )}
        <div className="restaurant-menu-teaser__content">
          {pictures && restaurant ? (
            <>
              <Title
                className="restaurant-menu-teaser__title"
                size="large"
                color="stroke"
              >
                {utilities.translate('Меню ресторана')}
              </Title>
              <Description className="restaurant-menu-teaser__description">
                {restaurant.menu_description}
              </Description>
              <Button
                className="restaurant-menu-teaser__button"
                to={routes.page.dishes(restaurant.slug)}
              >
                {utilities.translate('Смотреть меню')}
              </Button>
            </>
          ) : (
            <>
              <TextSkeleton
                width={550}
                height={140}
                label="Имя"
                style={
                  !isPhone
                    ? { width: '80%', margin: '-70px 50px 0' }
                    : {
                        width: '100%',
                        margin: '-40px 0 0',
                      }
                }
              />
              <MultipleSkeleton
                style={
                  !isPhone
                    ? { width: '80%', margin: '0 50px 0' }
                    : {
                        width: '100%',
                        margin: '-40px 0 0',
                      }
                }
                width={400}
                gap={15}
                count={4}
                height={20}
                size="downgrade"
                downgradeStep={40}
              />
            </>
          )}
        </div>
      </div>
    </Section>
  );
};

export default RestaurantMenu;
