import { useState } from 'react';
import useWindowSize from '../../../../hooks/useResize';
import Button from '../../../Button/Button';
import Modal from '../../../Modal';
import NewLocationForm from './LocationForm';
import utilities from '../../../../utilities';

export default function AddLocation({ className }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const { isPhone } = useWindowSize();
  return (
    <>
      <Button
        onClick={() => setModalOpen(true)}
        className={className}
        size={!isPhone ? 'medium' : 'full'}
      >
        {utilities.translate('Добавить')}
      </Button>
      <Modal state={[isModalOpen, setModalOpen]}>
        <NewLocationForm />
      </Modal>
    </>
  );
}
