/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import routes from '../../../../routes';
import utilities from '../../../../utilities';
import BlockSkeleton from '../../../Skeleton/Block';
import FormField from '../../../UI/Form/Field';
import Button from '../../../Button/Button';
import NewLocationForm from '../../../Account/Bindings/Locations/LocationForm';
import Modal from '../../../Modal';
import Another from './Another';
import CustomAddress from './CustomAddress';
import { GlobalBasketContext } from '../../../../context/GlobalBasketContext';

export default function DeliveryAddress({ locations, fetchStatus }) {
  const { watch, setValue, getValues } = useFormContext();
  const [showConfirm, setShowConfirm] = useState();
  const watcher = watch('helpers_address');
  const anoherAddressWatcher = watch('helpers_address_another');
  const [newAddressOpen, setNewAddressOpen] = useState(false);

  const addAddress = async () => {
    const response = await utilities.api.post(
      routes.apiSwagger.addShoppingCardAddress(),
      {
        point: getValues('point'),
      }
    );
  };

  useEffect(() => {
    setNewAddressOpen(anoherAddressWatcher);
  }, [anoherAddressWatcher]);

  const generateAddress = ({ street, house_number: house }) => {
    if (street) {
      return `ул. ${street} д. ${house}`;
    }
    return '';
  };

  useEffect(() => {
    if (watcher) {
      setValue(
        'helpers_address_name',
        `${watcher.street} ${watcher.house_number}`
      );
      const {
        street,
        house_number,
        flat_number,
        floor_number,
        porch_number,
        point,
        intercom_number,
      } = watcher;
      Object.entries({
        porch_number,
        intercom_number,
        street,
        house_number,
        flat_number,
        floor_number,
        point,
      }).forEach(([key, value]) => {
        setValue(key, value);
      });
      // addAddress();
    } else {
      setValue('helpers_address_name', null);
      setValue('intercom_number', null);
      setValue('street', null);
      setValue('house_number', null);
      setValue('flat_number', null);
      setValue('floor_number', null);
      setValue('point', null);
    }
  }, [watcher]);

  if (fetchStatus?.status === 'error' && !anoherAddressWatcher) {
    return (
      <>
        <FormField
          nomargin
          disabled
          holderClassname="delivery-address-preview"
          name="helpers_address_name"
        />
        <Another errorMessage={fetchStatus?.message} />
      </>
    );
  }

  if (anoherAddressWatcher) {
    return (
      <div className="delivery-custom-address-holder">
        <CustomAddress onSuccess={setShowConfirm} />
        <Button
          modifier="start"
          size="medium"
          onClick={() => {
            setValue('helpers_address_another', false);
            setValue('helpers_address', false);
          }}
        >
          Отмена
        </Button>
        {showConfirm && (
          <Button
            onClick={() => {
              setValue('helpers_address_another', false);
            }}
            size="medium"
          >
            {utilities.translate('Ок')}
          </Button>
        )}
      </div>
    );
  }

  return (
    <>
      {/* <div>
        <div className="address-item">
          <span className="address-item__title">{addressWatcher?.name}:</span>
          <span className="address-item__address">
            {generateAddress(addressWatcher || {})}
          </span>
        </div>
      </div> */}
      {/* <Modal state={[newAddressOpen, setNewAddressOpen]}>
        <NewLocationForm />
      </Modal> */}
      <FormField
        nomargin
        disabled
        holderClassname="delivery-address-preview"
        name="helpers_address_name"
      />
      <Another locations={locations} />
    </>
  );
}
