/* eslint-disable no-unused-vars */
import { Controller, useFormContext } from 'react-hook-form';
import utilities from '../../../utilities';
import Checkbox from '../../UI/Form/Checkbox';
import FormField from '../../UI/Form/Field';
import Phone from '../../UI/Form/Phone';

export default function DeliveryPhone() {
  const { control, unregister, register, watch } = useFormContext();
  const watcher = watch('callback_required');

  return (
    <>
      <FormField
        nomargin
        disabled={watcher}
        component={
          <Controller
            control={control}
            name="phone"
            render={({ field, fieldState }) => (
              <Phone
                disabled={Boolean(watcher)}
                {...field}
                fieldState={fieldState}
              />
            )}
          />
        }
      />
      <Controller
        control={control}
        name="callback_required"
        render={({ field }) => (
          <Checkbox
            square
            id={field.name}
            onChange={field.onChange}
            type="checkbox"
            name={field.name}
            label={utilities.translate('Только sms')}
          />
        )}
      />
    </>
  );
}
