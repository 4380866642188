import { useContext } from 'react';
import { LocationsContext } from '../../../../context/Bindings/Locations';
import { ReactComponent as DeleteIcon } from '../../../../icons/delete.svg';
import routes from '../../../../routes';
import utilities from '../../../../utilities';
import AddLocation from './AddLocation';
import AddressChange from './ChangeLocation';

export default function LocationsList() {
  const [locations, setLocations] = useContext(LocationsContext);

  const generateAddress = ({ street, house_number: house }) => {
    return `ул. ${street} д. ${house}`;
  };

  const removeAddress = async (id) => {
    await utilities.api.remove(routes.apiSwagger.modifyUserLocation(id));
    setLocations({
      ...locations,
      results: locations.results.filter(
        ({ id: locationId }) => locationId !== id
      ),
    });
  };

  return (
    <>
      <ul className="addresses-list">
        {locations.results.map(({ name, id, ...rest }) => (
          <li key={id} className="address-item">
            <span className="address-item__title">{name}:</span>
            <span className="address-item__address">
              {generateAddress(rest)}
            </span>
            <AddressChange location={{ id, name, ...rest }} />
            {/* <button type="button" className="address-item__change">
              Изменить
            </button> */}
            <button
              type="button"
              onClick={() => removeAddress(id)}
              className="address-item__delete"
            >
              Удалить
              <DeleteIcon className="address-item__delete-icon" />
            </button>
          </li>
        ))}
      </ul>
      <AddLocation />
    </>
  );
}
