import { useEffect, useState } from 'react';
import utilities from '../../utilities';
import routes from '../../routes';

export default function Socials() {
  const [socialsData, setSocialsData] = useState();

  useEffect(() => {
    const fetchSocialsData = async () => {
      const response = await utilities.api.get(routes.api.socials());
      setSocialsData({
        links: response.links,
      });
    };

    fetchSocialsData();
  }, []);

  return (
    <ul className="socials">
      {socialsData?.links?.map((social) => {
        return (
          <li className="socials__item" key={social.title}>
            <a
              className="socials__link"
              href={social.url}
              target="_blank"
              rel="noreferrer"
            >
              {social.title}
            </a>
          </li>
        );
      })}
    </ul>
  );
}
