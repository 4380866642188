import { useState } from 'react';
import Modal from '../../../Modal';
import utilities from '../../../../utilities';
import LocationForm from './LocationForm';

export default function LocationChange({ location }) {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <button
        onClick={() => setOpen(true)}
        type="button"
        className="address-item__change"
      >
        {utilities.translate('Изменить')}
      </button>
      <Modal state={[isOpen, setOpen]}>
        <LocationForm location={location} />
      </Modal>
    </>
  );
}
