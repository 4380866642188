import { createContext, useMemo, useState } from 'react';

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const { children } = props;
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const contextValue = useMemo(
    () => ({
      step: [step, setStep],
      data: [data, setData],
    }),
    [step]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
