// @ts-check
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

const baseClassName = 'title';

const Title = (props) => {
  const {
    children,
    className,
    color,
    align,
    size,
    fontStyle,
    isHidden,
    tag = 'h2',
    dangerHTML,
  } = props;
  const Tag = tag;

  const titleClassName = cn(className, baseClassName, {
    [`${baseClassName}--${color}`]: color,
    [`${baseClassName}--${align}`]: align,
    [`${baseClassName}--${size}`]: size,
    [`${baseClassName}--${fontStyle}`]: fontStyle,
    [`${baseClassName}--hidden`]: isHidden,
  });

  return (
    <Tag
      dangerouslySetInnerHTML={dangerHTML ? { __html: dangerHTML } : null}
      className={titleClassName}
    >
      {dangerHTML ? null : children}
    </Tag>
  );
};

Title.Link = (props) => {
  const { children, className, href, isReduced } = props;

  const titleLinkClassnames = cn(className, `${baseClassName}__link`, {
    [`${baseClassName}__link--reduced`]: isReduced,
  });

  return (
    <NavLink className={titleLinkClassnames} to={href}>
      {children}
    </NavLink>
  );
};

export default Title;
