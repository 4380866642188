import { useContext } from 'react';
import { GlobalBasketContext } from '../../../context/GlobalBasketContext';
import Empty from '../../Empty/Empty';
import OrdersList from './Orders';

export default function BasketList() {
  const { items } = useContext(GlobalBasketContext);

  if (!items.length) {
    return <Empty>Нет заказов</Empty>;
  }

  return <OrdersList />;
}
