import Slider from '../../Slider';
import Slide from './Slide';
import BlockSkeleton from '../../Skeleton/Block';

export default function HeadSlider({ data }) {
  if (!data) {
    return (
      <BlockSkeleton
        width="100%"
        height={430}
        className="section"
        label="Слайдер"
      />
    );
  }

  if (data.slider?.length === 0) {
    return null;
  }

  if (data.slider?.length === 1) {
    return (
      <div className="event__image-holder">
        <img className="event__image" alt="" src={data.slider[0].image_url} />
      </div>
    );
  }

  if (data.slider?.length > 1) {
    return (
      <Slider
        slideClassName="event__slide"
        elements={data.slider}
        // slidesPerView={1}
        spaceBetween={15}
        scrollbarShift
        scrollbarShrinked
        preloadImages
        slide={Slide}
      />
    );
  }

  return null;
}
