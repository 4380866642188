/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import { DevTool } from '@hookform/devtools';
import cn from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { AccountContext } from '../../../context/Account';
import { GlobalBasketContext } from '../../../context/GlobalBasketContext';
import useLocalStorageChange from '../../../hooks/useLocalStorageChange';
import routes from '../../../routes';
import utilities from '../../../utilities';
import callGtmEvent from '../../../utilities/gtm';
import AuthForm from '../../Auth/Login/Form';
import Modal from '../../Modal';
import Section from '../../Section/Section';
import BasketDelivery from '../Delivery';
import { CASH_PAYMENT_TYPE } from '../Delivery/PaymentMethods/helpers';
import BasketRegister from '../Register';

export default function BasketOrderForm() {
  const { account } = useContext(AccountContext);
  const history = useHistory();
  const {
    reset: resetBasket,
    bill_price_for_free,
    promocode,
    amount,
    branch_id,
    establishment_name,
    items,
  } = useContext(GlobalBasketContext);
  const [modalOpen, setModalOpen] = useState(false);
  const firstRender = useRef();
  const shoppingCard = useLocalStorageChange({
    field: utilities.basket.SHOPPING_CARD_KEY,
  });
  const methods = useForm({
    defaultValues: {
      shopping_card_id: utilities.basket.getCardKeyId(),
      name: account?.first_name || '',
      phone: account?.phone || '',
      payment_type: CASH_PAYMENT_TYPE,
      delivery_datetime: new Date().toISOString(),
      shopping_card: '1',
      delivery_type: '0' /* bill_price_for_free > 0 ? '0' : '1' */,
      create_address: false,
      note: '',
      person_count: '1',
      asap: true,
      code: promocode || '',
      branch: branch_id || null,
    },
  });
  const { getValues, register, handleSubmit, control, setValue } = methods;
  const onSubmit = async (values) => {
    if (!account) {
      setModalOpen(true);
    } else {
      const submittingKeys = Object.keys(values).filter(
        (key) => !key.includes('helpers') || key !== 'code'
      );
      const submittingValues = submittingKeys.reduce((acc, key) => {
        acc[key] = values[key];
        return acc;
      }, {});
      const response = await utilities.api.post(routes.apiSwagger.orders(), {
        ...submittingValues,
        source: 'website',
      });
      if (!response.error) {
        const categories = items.map(({ category_id }) => category_id);
        const categoryNames = await Promise.all(
          categories.map((id) =>
            utilities.api.get(routes.apiSwagger.dishCategoryInfo(id))
          )
        ).then((categoriesInfos) => {
          return categoriesInfos.reduce((acc, { id, name }) => {
            acc[id] = name;
            return acc;
          }, {});
        });
        callGtmEvent(
          'purchase',
          items.map((item) => ({
            item_name: item.title,
            item_id: item.item_id,
            item_category: categoryNames[item.category_id],
            quantity: item.count,
            currency: 'RUB',
            price: item.price,
          })),
          {
            currency: 'RUB',
            value: amount,
            coupon: promocode,
            affiliation: establishment_name,
            transaction_id: response.id,
          }
        );
        resetBasket();
      }
      const paymentResponse = await utilities.api.postMobile(
        routes.apiSwagger.pay(response.id),
        {}
      );
      window.open(paymentResponse.payment_form_url, '_self');
    }
  };

  useEffect(() => {
    if (!firstRender.current && account) {
      setValue('name', account.first_name);
      setValue('phone', account.phone);
    }
  }, [account]);

  useEffect(() => {
    register('delivery_datetime');
    register('shopping_card');
    register('street');
    register('house_number');
    register('flat_number');
    register('floor_number');
    register('helpers_address');
    register('asap');
    register('create_address');
    register('point');
    register('helpers_address_another');
    register('helpers_address_name');
    register('note');
  }, [register]);

  useEffect(() => {
    setValue('shopping_card', shoppingCard);
  }, [shoppingCard]);

  useEffect(() => {
    firstRender.current = false;

    return () => {
      firstRender.current = true;
    };
  }, []);

  const formClasses = cn('basket-order-form', 'section');
  return (
    <>
      {!account && (
        <Modal state={[modalOpen, setModalOpen]}>
          <AuthForm redirect={false} setModalOpen={setModalOpen} />
        </Modal>
      )}
      <FormProvider {...methods}>
        <Section>
          <RecoilRoot>
            <form className={formClasses} onSubmit={handleSubmit(onSubmit)}>
              {/* account && */}
              <div className="delivery">
                <Section.Title className="delivery__title">
                  {utilities.translate('Доставка')}
                </Section.Title>
                <BasketDelivery />
              </div>

              <div className="basket-register">
                <BasketRegister />
              </div>
              <DevTool control={control} />
            </form>
          </RecoilRoot>
        </Section>
      </FormProvider>
    </>
  );
}
