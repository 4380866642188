/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { SearchContext } from '../../../context/Search';
import Empty from '../../Empty/Empty';
import BlockSkeleton from '../../Skeleton/Block';
import ResultsList from './List';
import Section from '../../Section/Section';

export default function SearchResults() {
  const { results } = useContext(SearchContext);
  if (!results) {
    //   <GridSkeleton
    //   count={3}
    //   columns={1}
    //   gap={isDesktop ? 40 : 20}
    //   proportion={isPhone ? 0.3 : 0.1}
    //   style={isDesktop && { marginTop: '30px' }}
    //   label="Загрузка событий"
    // />
    return <BlockSkeleton width="100%" height={100} count={4} gap={20} />;
  }

  if (results.length === 0) {
    return (
      <Empty className="search-empty">
        Нет блюд, удовлетворяющих заданным фильтрам
      </Empty>
      // <Section observable={false}>
      // </Section>
    );
  }

  return <ResultsList />;
}
