/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */

export const ONLINE_CARD_PAYMENT_TYPE = '0';
export const CASH_PAYMENT_TYPE = '1';
export const COURIER_PAYMENT_TYPE = '2';
export const APPLE_PAY_PAYMENT_TYPE = '3';
export const GOOGLE_PAY_PAYMENT_TYPE = '4';

export function hasCashPaymentType(paymentsTypes) {
  return paymentsTypes?.includes(+CASH_PAYMENT_TYPE);
}

export function hasOnlineCardPaymentType(paymentsTypes) {
  return paymentsTypes?.includes(+ONLINE_CARD_PAYMENT_TYPE);
}

export function hasCourierPaymentType(paymentsTypes) {
  return paymentsTypes?.includes(+COURIER_PAYMENT_TYPE);
}

export function hasApplePayPaymentType(paymentsTypes) {
  return paymentsTypes?.includes(+APPLE_PAY_PAYMENT_TYPE);
}

export function hasGooglePayPaymentType(paymentsTypes) {
  return paymentsTypes?.includes(+GOOGLE_PAY_PAYMENT_TYPE);
}
