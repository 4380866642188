import routes from '../routes';
import { ReactComponent as CircleIcon } from '../icons/circle.svg';
import Apps from '../components/MobileApplication/Apps';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Section from '../components/Section/Section';
import Title from '../components/Title/Title';
import utilities from '../utilities';
import Meta from '../components/Meta';

const MobileApplication = () => {
  return (
    <>
      <Meta url="/mobile_application" />
      <Breadcrumbs
        data={[
          { content: 'Главная', href: routes.page.home() },
          {
            content: 'Мобильное приложение',
            href: routes.page.mobileApplication(),
          },
        ]}
      />
      <Section>
        <div className="application">
          <Title tag="h1" align="center">
            {utilities.translate('Новое удобное приложение Madyar Collection')}
          </Title>
          <div className="application__phone">
            <div className="application__image" />
            <div className="application__image" />
            <CircleIcon className="application__svg" />
            <Apps />
          </div>
          <ul className="application__list">
            <li className="application__item">
              {utilities.translate('Быстрое переключение между ресторанами')}
            </li>
            <li className="application__item">
              {utilities.translate('Лёгкий поиск блюд')}
            </li>
            <li className="application__item">
              {utilities.translate(
                'Оформление дисконтной карты прямо в приложении'
              )}
            </li>
            <li className="application__item">
              {utilities.translate('Оплата заказа онлайн или наличными')}
            </li>
            <li className="application__item">
              {utilities.translate(
                'Личный кабинет и персональная история заказов'
              )}
            </li>
          </ul>
        </div>
      </Section>
    </>
  );
};

export default MobileApplication;
