/* eslint-disable no-unused-vars */
import React, { Fragment, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { SearchContext } from '../../../../context/Search';
import Description from '../../../Description/Description';
import Title from '../../../Title/Title';
import routes from '../../../../routes';
import utilities from '../../../../utilities';
import Button from '../../../Button/Button';
import ListItem from './Item';
import Dish from '../../../Dishes/Dishes/Dish';

export default function ResultsList() {
  const { results } = useContext(SearchContext);
  return (
    <ul className="search-teasers">
      {results.map((result) => (
        <Dish key={result.id} data={result} />
        // <ListItem key={result.id} data={result} />
      ))}
    </ul>
  );
}
