import { useContext, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { EstablishmentsContext } from '../../../../context/Establishments';
import utilities from '../../../../utilities';
import Checkbox from '../../../UI/Form/Checkbox';
import TimeDays from './Days';
import TimeHours from './Hours';

export default function DeliveryTime() {
  const { setValue, watch, control } = useFormContext();
  const asapWatcher = watch('asap');
  const establishments = useContext(EstablishmentsContext);
  const [currentEstablishment, setCurrentEstablishment] = useState(null);
  const deliveryTimeWatcher = useWatch({
    control,
    name: ['helpers_timedays', 'helpers_timehours'],
  });

  useEffect(() => {
    if (establishments) {
      setCurrentEstablishment(
        establishments.find(
          ({ id }) =>
            id.toString() === utilities.basket.getEstablishmentId().toString()
        )
      );
    }
  }, [establishments]);

  useEffect(() => {
    const [day, time] = deliveryTimeWatcher;
    if (day && time) {
      const [hours, minutes] = time.split(':');
      const newDate = new Date(day);
      newDate.setHours(hours, minutes);
      setValue(
        'delivery_datetime',
        utilities.date.format(newDate, 'DD.MM.YYYY HH:mm:ss')
      );
    }
  }, [deliveryTimeWatcher]);
  return (
    <>
      <Checkbox
        type="radio"
        label={utilities.translate('Как можно скорее')}
        id="asap"
        onChange={() => {
          setValue('asap', true);
          setValue('delivery_datetime', new Date().toISOString());
        }}
        defaultChecked={Boolean(1)}
        name="delivery_time_check"
      />
      <Checkbox
        type="radio"
        label={utilities.translate('Выбрать дату и время')}
        id="chosen"
        onChange={() => {
          setValue('asap', false);
          setValue('delivery_datetime', true);
        }}
        name="delivery_time_check"
      />
      {!asapWatcher && currentEstablishment && (
        <>
          <TimeDays establishment={currentEstablishment} />
          <TimeHours establishment={currentEstablishment} />
        </>
      )}
    </>
  );
}
