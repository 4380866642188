/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import routes from '../../../routes';
import utilities from '../../../utilities';
import BlockSkeleton from '../../Skeleton/Block';
import ChefSlider from './Slider';

export default function Chef() {
  const { id } = useParams();
  const [state, setState] = useState(null);
  const [noChef, setNoChef] = useState(true);

  useEffect(() => {
    const fetchSlider = async () => {
      const response = await utilities.api.get(
        routes.api.establishmentChef(id)
      );
      if (response.error || response.message) {
        setNoChef(true);
      } else {
        setNoChef(false);
        setState(response.item.elements);
      }
    };
    fetchSlider();
  }, []);

  if (noChef) {
    return null;
  }

  if (!state) {
    return (
      <BlockSkeleton
        width="100%"
        height={600}
        className="section"
        label="Загрузка истории"
      />
    );
  }

  return <ChefSlider data={state} />;
}
