import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { EstablishmentsContext } from '../../../context/Establishments';
import useWindowSize from '../../../hooks/useResize';
import Description from '../../Description/Description';
import Section from '../../Section/Section';
import BlockSkeleton from '../../Skeleton/Block';
import TextSkeleton from '../../Skeleton/Text';
import Title from '../../Title/Title';
import utilities from '../../../utilities';

export default function Info() {
  const { establishmentId } = useParams();
  const establishments = useContext(EstablishmentsContext);
  const establishment = useMemo(() => {
    if (establishments) {
      return establishments.find(({ slug }) => establishmentId === slug);
    }
    return null;
  }, [establishments]);
  const { isDesktop } = useWindowSize();
  const language = utilities.getLang();

  const menuTitle = useMemo(() => {
    if (language === 'ru' && establishment?.menu_title_ru) {
      return establishment?.menu_title_ru;
    }
    if (language === 'en' && establishment?.menu_title_en) {
      return establishment?.menu_title_en;
    }
    if (establishment?.name) {
      return establishment?.name;
    }

    return 'Меню ресторана';
  }, [establishment]);

  return (
    <Section className="restaurant-menu">
      {establishment?.logo ? (
        <img
          className="restaurant-menu__logo"
          src={establishment.logo}
          alt=""
        />
      ) : (
        <BlockSkeleton
          width={isDesktop ? 120 : 85}
          height={isDesktop ? 120 : 85}
          style={isDesktop ? { margin: '30px auto' } : { margin: '25px auto' }}
        />
      )}
      {establishment?.menu_title_ru ||
      establishment?.menu_title_en ||
      establishment?.name ? (
        <Title
          className="restaurant-menu__title"
          tag="h1"
          align="center"
          size="large"
        >
          {menuTitle}
        </Title>
      ) : (
        <TextSkeleton
          style={
            isDesktop ? { marginBottom: '30px' } : { marginBottom: '20px' }
          }
          width={isDesktop ? 400 : 150}
          height={isDesktop ? 70 : 30}
          label="Имя"
        />
      )}
      {establishment?.description ? (
        <Description className="restaurant-menu__description" align="center">
          {establishment.description}
        </Description>
      ) : (
        <TextSkeleton
          width={isDesktop ? 700 : 245}
          height={isDesktop ? 35 : 60}
          label="Имя"
        />
      )}
    </Section>
  );
}
