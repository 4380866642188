/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useWindowSize from '../../hooks/useResize';
import routes from '../../routes';
import utilities from '../../utilities';
import EventTeaser from '../EventTeaser/EventTeaser';
import Section from '../Section/Section';
import BlockSkeleton from '../Skeleton/Block';
import Slider from '../Slider';

const EventSlider = () => {
  const [events, setEvents] = useState(null);
  const { isPhone } = useWindowSize();
  const { eventId } = useParams();
  const ref = useRef();

  useEffect(() => {
    const fetchNewsTeasers = async () => {
      const response = await utilities.api.get(routes.api.events());
      setEvents(
        response.events
          .filter(({ id }) => id.toString() !== eventId)
          .slice(0, 4)
      );
    };
    fetchNewsTeasers();
  }, []);

  return (
    <Section
      sectionRef={(element) => {
        ref.current = element;
      }}
    >
      <Section.Title>{utilities.translate('Другие события')}</Section.Title>
      {events?.length > 0 ? (
        <Slider
          slidesPerView={isPhone ? 1 : 2}
          slideClassName="event-teaser__slide"
          shift
          scrollbarShift
          elements={events}
          spaceBetween={15}
          slidesOffsetAfter={ref.current.clientWidth / 2 + 15}
          scrollbarShrinked
          slide={EventTeaser}
          centeredSlides={false}
        />
      ) : (
        <BlockSkeleton
          width="100%"
          className="section"
          height={500}
          style={{ marginTop: '35px', marginBottom: '20px' }}
        />
      )}
    </Section>
  );
};

export default EventSlider;
