/* eslint-disable no-unused-vars */
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import FormField from '../../../../UI/Form/Field';
import AddressSelect from './LocationAddressSelect';
import utilities from '../../../../../utilities';

export default function NewLocationAddress() {
  const { control } = useFormContext();

  return (
    <>
      <AddressSelect />
      <div className="account-address-form-wrapper">
        <FormField
          nomargin
          componentLabel={utilities.translate('Подъезд')}
          component={
            <Controller
              control={control}
              name="porch_number"
              render={({ field }) => (
                <NumberFormat
                  className="form__input"
                  onValueChange={(value) => {
                    field.onChange(value.value);
                  }}
                  {...field}
                />
              )}
            />
          }
        />
        <FormField
          nomargin
          componentLabel={utilities.translate('Квартира')}
          component={
            <Controller
              control={control}
              name="flat_number"
              render={({ field }) => (
                <NumberFormat
                  className="form__input"
                  onValueChange={(value) => {
                    field.onChange(value.value);
                  }}
                  {...field}
                />
              )}
            />
          }
        />
        <FormField
          nomargin
          componentLabel={utilities.translate('Этаж')}
          component={
            <Controller
              control={control}
              name="floor_number"
              render={({ field }) => (
                <NumberFormat
                  className="form__input"
                  onValueChange={(value) => {
                    field.onChange(value.value);
                  }}
                  {...field}
                />
              )}
            />
          }
        />
      </div>
    </>
  );
}
