/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import getLang from './lang';
import * as storage from './localStorage';
import * as params from './params';
import { nanoid } from 'nanoid';

let api;
let cache;

const currentRequests = [];

const { CancelToken } = axios;
const globalCancelTokenSource = CancelToken.source();

const ACCEPT_LANGUAGES = {
  en: 'en-US',
  ru: 'ru-RU',
};

export function cancelAllCurrentRequests() {
  globalCancelTokenSource.cancel();
}

export function generateRequestId() {
  const requestId = nanoid();
  const localCancelTokenSource = CancelToken.source();
  currentRequests.push({ id: requestId, tokenSource: localCancelTokenSource });
  return requestId;
}

export function getCacheData(url) {
  if (cache.store.store[url]) {
    return JSON.parse(cache.store.store[url]).data.data;
  }
  return null;
}

// export function invalidateCacheByMethod(method) {
//   console.log(cache.store, 'cache store');
// }

export function cancelRequestById(requestId) {
  if (requestId) {
    const requestCancelSourceIndex = currentRequests.findIndex(
      ({ id }) => id === requestId
    );
    currentRequests[requestCancelSourceIndex].tokenSource.cancel();
    currentRequests.splice(requestCancelSourceIndex, 1);
  }
}

function generateAuthHeaders() {
  if (storage.get('madyar-auth-token')) {
    return {
      Authorization: `Token ${storage.get('madyar-auth-token')}`,
    };
  }
  return {};
}

function parseOptions(options) {
  if (!options) return '';
  if (Object.keys(options).length === 0) return '';
  return `?${params.toString(options)}`;
}

export async function get(
  url,
  options = {},
  headers = {},
  requestId,
  clearCacheEntry
) {
  // currentRequests.push({ id: nanoid(), tokenSource: localCancelTokenSource });
  return api({
    url: `${url}${parseOptions(options)}`,
    // url: `${url}${params.toString(options)}`,
    method: 'get',
    cancelToken: requestId
      ? currentRequests.find(({ id }) => id === requestId).tokenSource.token
      : globalCancelTokenSource.token,
    clearCacheEntry,
    headers: {
      'Accept-Language': ACCEPT_LANGUAGES[getLang()],
      'Source-Name': 'web',
      ...generateAuthHeaders(),
      ...headers,
    },
  }).then(async (response) => {
    const { success, ...rest } = response.data;
    const length = await cache.store;
    if (success) {
      return rest;
    }
    return response.data;
  });
}

export async function postThirdParty(url, data, options = {}) {
  const response = await axios.post(url, data, {
    ...options,
    cancelToken: globalCancelTokenSource.token,
  });
  return response.data;
}

export async function remove(url) {
  const response = await axios.delete(url, {
    headers: {
      Authorization: `Token ${storage.get('madyar-auth-token')}`,
    },
  });
  return response.data;
}

export async function patch(url, data) {
  const response = await axios.patch(url, data, {
    headers: {
      Authorization: `Token ${storage.get('madyar-auth-token')}`,
    },
  });
  return response.data;
}

export async function post(url, data, headers = {}) {
  let dataToReturn = {};
  try {
    const response = await api({
      url,
      method: 'post',
      data,
      headers: {
        ...headers,
        ...generateAuthHeaders(),
      },
    }).catch((error) => {
      if (error?.response.data) {
        const { data: errors } = error.response;
        if (errors.messages) {
          dataToReturn = { error: true, validation_error: errors.messages };
        } else {
          dataToReturn = { error: true, ...error.response.data };
        }
      }
    });
    if (response) {
      const { success, ...rest } = response.data;
      if (success) {
        dataToReturn = rest;
      } else {
        dataToReturn = rest;
      }
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return dataToReturn;
}

export async function postMobile(url, data, headers = {}) {
  let dataToReturn = {};
  try {
    const response = await api({
      url,
      method: 'post',
      data,
      headers: {
        'Source-Name': 'web',
        ...headers,
        ...generateAuthHeaders(),
      },
    }).catch((error) => {
      if (error?.response.data) {
        const { data: errors } = error.response;
        if (errors.messages) {
          dataToReturn = { error: true, validation_error: errors.messages };
        } else {
          dataToReturn = { error: true, ...error.response.data };
        }
      }
    });
    if (response) {
      const { success, ...rest } = response.data;
      if (success) {
        dataToReturn = rest;
      } else {
        dataToReturn = rest;
      }
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return dataToReturn;
}

export function init() {
  cache = setupCache({
    maxAge: 15 * 60 * 1000,
    exclude: {
      paths: ['/locations/'],
      query: false,
      methods: ['put', 'post', 'delete', 'patch'],
    },
    invalidate: async (config, request) => {
      if (request.clearCacheEntry) {
        await config.store.removeItem(config.uuid);
      }
    },
  });
  api = axios.create({
    adapter: cache.adapter,
    headers: {
      Accept: 'application/json; version=1.2',
    },
    // headers: storage.get('madyar-auth-token')
    //   ? {
    //       Authorization: `Token ${storage.get('madyar-auth-token')}`,
    //     }
    //   : {},
  });
}
