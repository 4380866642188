/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { RecoilRoot } from 'recoil';
import DeliveryPrice from './DeliveryPrice';
import Name from './Name';
import Phone from './Phone';
import DeliveryType from './Type';
import DeliveryOption from './Option';
import DeliveryAddress from './Address';
import DeliveryTime from './Time';
import DeliveryComment from './Comment';
import DeliveryCulteries from './Culteries';
import DeliveryPaymentMethods from './PaymentMethods';
import Checkbox from '../../UI/Form/Checkbox';
import utilities from '../../../utilities';
import { EstablishmentsContext } from '../../../context/Establishments';
import BlockSkeleton from '../../Skeleton/Block';

export default function BasketDelivery() {
  const establishments = useContext(EstablishmentsContext);

  if (!establishments) {
    return <BlockSkeleton width="100%" count={4} height={50} gap={20} />;
  }

  return (
    <>
      <DeliveryOption>
        <DeliveryPrice />
      </DeliveryOption>
      <DeliveryOption>
        <DeliveryType />
      </DeliveryOption>
      <DeliveryOption label={`${utilities.translate('Имя получателя')}: `}>
        <Name />
      </DeliveryOption>
      <DeliveryOption label={`${utilities.translate('Телефон получателя')}: `}>
        <Phone />
      </DeliveryOption>
      <DeliveryAddress />
      <DeliveryTime />
      <DeliveryOption label={`${utilities.translate('Комментарий')}: `}>
        <DeliveryComment />
      </DeliveryOption>
      <DeliveryOption label={`${utilities.translate('Положить приборы')}: `}>
        <DeliveryCulteries />
      </DeliveryOption>
      <DeliveryOption label={`${utilities.translate('Способы оплаты')}: `}>
        <DeliveryPaymentMethods />
      </DeliveryOption>
      <DeliveryOption>
        <Checkbox
          type="checkbox"
          square
          name="agree"
          id="agree"
          label={utilities.translate(
            'Согласен(а) на обработку персональных данных'
          )}
        />
      </DeliveryOption>
    </>
  );
}
