import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import routes from '../../routes';
import utilities from '../../utilities';

export const LocationsContext = createContext();

export const LocationsContextProvider = (props) => {
  const { children } = props;
  const [state, setState] = useState(null);

  useEffect(() => {
    const fetchAddresses = async () => {
      const response = await axios.get(
        routes.apiSwagger.locations(utilities.storage.get('madyar-id')),
        {
          headers: {
            Authorization: `Token ${utilities.storage.get(
              'madyar-auth-token'
            )}`,
          },
        }
      );
      setState(response.data);
    };
    fetchAddresses();
  }, []);

  return (
    <LocationsContext.Provider value={[state, setState]}>
      {children}
    </LocationsContext.Provider>
  );
};
