/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import utilities from '../../utilities';

export default function Collapsible({
  children,
  duration = 500,
  open = false,
  initialOpen = false,
}) {
  const [isOpen, setOpened] = useState(initialOpen);
  const [isInAction, setInAction] = useState(false);
  const firstRender = useRef(true);
  const holderRef = useRef();
  const collapsibleRef = useRef();
  const wrapperRef = useRef();

  const expand = () => {
    utilities.animation
      .go(duration, (fraction) => {
        wrapperRef.current.style.height = `${
          holderRef.current.clientHeight * fraction
        }px`;
      })
      .then(() => {
        setOpened(true);
        setInAction(false);
      });
  };

  const shrink = () => {
    utilities.animation
      .go(duration, (fraction) => {
        wrapperRef.current.style.height = `${
          holderRef.current.clientHeight * (1 - fraction)
        }px`;
      })
      .then(() => {
        setOpened(false);
        setInAction(false);
      });
  };

  useEffect(() => {
    if (!firstRender.current) {
      if (!isInAction) {
        setInAction(true);
        if (open) {
          expand();
        } else {
          shrink();
        }
      }
    } else {
      firstRender.current = false;
    }
  }, [open]);

  const handleClick = () => {
    if (!isInAction) {
      setInAction(true);
      if (isOpen) {
        // если открыт
        shrink();
      } else {
        // если закрыт
        expand();
      }
    }
  };

  useEffect(() => {
    collapsibleRef.current.style = '';
  }, [isOpen]);

  return (
    <div ref={collapsibleRef} className="collapsible">
      {/* <button type="button" onClick={handleClick}>
        control
      </button> */}
      <div
        ref={wrapperRef}
        style={{ height: `${initialOpen ? 'auto' : ''}` }}
        className="collapsible__wrapper"
      >
        <div ref={holderRef} className="collapsible__holder">
          {children}
        </div>
      </div>
    </div>
  );
}
