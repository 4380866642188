import React from 'react';

export default function Arrow({ className }) {
  return (
    <svg
      className={className}
      height="10"
      viewBox="0 0 163 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 5H161" stroke="#101820" />
      <path
        d="M155.734 0.986918L161.449 5.02974L155.715 9.04478"
        stroke="#101820"
      />
    </svg>
  );
}
