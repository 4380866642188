import { Route } from 'react-router-dom';
import routes from '../../routes';
import Circle from './Circle';

const RoutesCircle = () => {
  return (
    <Route
      exact
      path={[
        routes.page.home(),
        routes.page.events(),
        routes.page.catering(),
        routes.page.collaboration(),
        routes.page.contacts(),
        routes.page.events(),
        routes.page.news(),
        routes.page.restaurants(),
        routes.page.team(),
        routes.page.vacancy(),
        routes.page.restaurant(),
        routes.page.newsItem(),
      ]}
    >
      <Circle />
    </Route>
  );
};

export default RoutesCircle;
