import React, { useContext } from 'react';
import { DishesContext } from '../../../context/Dishes';
import Section from '../../Section/Section';
import Categories from './Categories';
import Preloader from './Preloader';

export default function Dishes() {
  const { categories, dishes } = useContext(DishesContext);

  return (
    <Section>
      {dishes && categories ? (
        <Categories
          categories={categories}
          allowedCategories={categories}
          teasers={dishes}
        />
      ) : (
        <Preloader />
      )}
    </Section>
  );
}
