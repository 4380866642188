/* eslint-disable no-unused-vars */
import { useContext, useState } from 'react';
import { AccountContext } from '../../context/Account';
import Login from './Login';
import Logout from './Logout';

export default function Auth() {
  const { account } = useContext(AccountContext);
  if (account) return <Logout />;
  return <Login />;
}
