/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
import { useContext, useEffect, useMemo } from 'react';
import { GlobalBasketContext } from '../../../context/GlobalBasketContext';
import utilities from '../../../utilities';

export default function FinalPrice() {
  const {
    bill_price_for_free,
    actual_amount,
    amount,
    delivery_fee,
    discount_amount,
  } = useContext(GlobalBasketContext);

  const deliveryPrice = useMemo(() => {
    if (delivery_fee === 0) {
      return (
        <span className="order-final-total">
          {utilities.translate('Стоимость доставки: бесплатно')}
        </span>
      );
    }
    if (delivery_fee !== null) {
      return (
        <span className="order-final-total">
          {`${utilities.translate(
            'Стоимость доставки'
          )}: ${utilities.currency.toString(delivery_fee)}`}
        </span>
      );
    }
    return null;
  }, [delivery_fee]);

  const promoDiscount = useMemo(() => {
    if (discount_amount) {
      return (
        <span className="order-final-total">
          {`${utilities.translate(
            'Скидка по промокоду'
          )}: ${utilities.currency.toString(discount_amount)}`}
        </span>
      );
    }
    return null;
  }, [discount_amount]);

  return (
    <div className="order-final-price">
      <div className="order-final-price-info">
        <span className="order-final-total">
          {`${utilities.translate(
            'Стоимость заказа'
          )}: ${utilities.currency.toString(actual_amount)}`}
        </span>
        {bill_price_for_free > 0 && deliveryPrice}
        {promoDiscount}
      </div>
      <span>{utilities.translate('Итого')}:</span>
      <span
        dangerouslySetInnerHTML={{
          __html: `&nbsp${utilities.currency.toString(amount)}`,
        }}
      />
    </div>
  );
}
