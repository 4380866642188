import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import routes from '../../routes';
import utilities from '../../utilities';

const Meta = (props) => {
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    const language = utilities.getLang();
    const fetchMeta = async () => {
      const metaResponse = await utilities.api.get(routes.api.meta(), {
        slug: props.url,
        lang: language,
      });
      setMeta(metaResponse.result);
    };
    fetchMeta();
  }, [props]);

  if (!meta) return null;

  return (
    <Helmet>
      {meta.meta_title && meta.meta_title.length > 0 && (
        <>
          <title>{meta.meta_title}</title>
          <meta name="title" content={meta.meta_title} />
        </>
      )}
      {meta.meta_description && meta.meta_description.length > 0 && (
        <meta name="description" content={meta.meta_description} />
      )}
    </Helmet>
  );
};

export default Meta;
