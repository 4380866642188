import { useContext, useState } from 'react';
import cn from 'classnames';
import { BasketRecommendedContext } from '../../../context/Basket/Recommended';
import Collapsible from '../../Collapsible';
// import Title from '../../Title/Title';
import Section from '../../Section/Section';
import Slider from '../../Slider';
import RecommendedSlide from './RecommendedSlide';
import { ReactComponent as ArrowIcon } from '../../../icons/arrow.svg';
import useWindowSize from '../../../hooks/useResize';
import utilities from '../../../utilities';
import GridSkeleton from '../../Skeleton/Grid';

export default function Recommended() {
  const [open, setOpen] = useState(false);
  const recommended = useContext(BasketRecommendedContext);
  const { isDesktop, isTablet } = useWindowSize();

  const arrowClasses = cn('recommended__expand-button-icon', {
    'recommended__expand-button-icon--active': open,
  });

  const getSlidesPerView = () => {
    if (isDesktop) return 3;
    if (isTablet) return 2;
    return 1;
  };

  return (
    <>
      <Section.Title
        dangerHTML={utilities.translate(
          'Рекомендуем к&nbsp;вашему&nbsp;заказу'
        )}
      />
      <>
        <Collapsible open={open}>
          {recommended ? (
            <Slider
              nogap
              scrollbarShrinked={false}
              slidesPerView={getSlidesPerView()}
              spaceBetween={30}
              className="recommended__slider"
              slideClassName="recommended__slide"
              elements={recommended}
              slide={RecommendedSlide}
            />
          ) : (
            <GridSkeleton gap={15} columns={3} count={3} proportion={2.17} />
          )}
        </Collapsible>
      </>
      <button
        className="recommended__expand-button"
        type="button"
        onClick={() => setOpen((prev) => !prev)}
      >
        {open ? 'Свернуть' : 'Развернуть'}
        <ArrowIcon className={arrowClasses} />
      </button>
    </>
  );
}
