import utilities from '../../utilities';
import EventTeaser from '../EventTeaser/EventTeaser';
import Section from '../Section/Section';
import BlockSkeleton from '../Skeleton/Block';
import Slider from '../Slider';
import Title from '../Title/Title';

export default function Stories({ stories }) {
  if (!stories) {
    return (
      <BlockSkeleton
        width="100%"
        height={500}
        className="section"
        label="Загрузка истории"
      />
    );
  }

  if (stories.length === 0) {
    return null;
  }

  return (
    <Section className="stories">
      <Title
        className="section__title swiper-container-title"
        size="huge"
        color="black"
      >
        {utilities.translate('Истории успеха')}
      </Title>
      <Slider
        spaceBetween={30}
        elements={stories}
        slide={EventTeaser}
        slideProps={{ size: 'large' }}
      />
    </Section>
  );
}
