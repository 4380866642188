/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useEffect } from 'react';
import Dish from './Dish';
import Title from '../../Title/Title';
import callGtmEvent from '../../../utilities/gtm';

const GTM_EVENT_TIME = 1000 * 45;

export default function DishCategory({ categories, teasers }) {
  const filteredCategories = categories.filter((category) =>
    teasers.some(({ category_id }) => category_id === category.id)
  );

  const data = filteredCategories.map((category) => {
    const filteredTeasers = teasers.filter(
      (teaser) => category.id === teaser.category_id
    );
    return { id: category.id, title: category.name, cards: filteredTeasers };
  });

  return data.map(({ id, title, cards }) => (
    <div id={`dish-${id}`} className="food-teasers" key={id}>
      <Title className="food-teasers__title">{title}</Title>
      {cards.map((card) => (
        <Dish data={card} key={card.id} />
      ))}
    </div>
  ));
}
