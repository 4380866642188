/* eslint-disable no-unused-vars */
import { useState } from 'react';
import utilities from '../../../utilities';
import Button from '../../Button/Button';
import Modal from '../../Modal';
import AuthForm from './Form';

export default function Auth() {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Button
        modifier="text"
        onClick={() => setModalOpen(true)}
        className="profile-teaser__login"
      >
        {utilities.translate('Вход')}
      </Button>
      <Modal state={[modalOpen, setModalOpen]}>
        <AuthForm setModalOpen={setModalOpen} />
      </Modal>
    </>
  );
}
