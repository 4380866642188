import React, { Fragment, useRef } from 'react';
import utilities from '../../../../utilities';
import HistoryItem from '../OrderItem';

export default function HistoryByMonths({ history, isPhone }) {
  const ordersFilterd = useRef(
    history.reduce((acc, order) => {
      const orderDate = new Date(
        utilities.date.formatToValidDate(order.created, 'DD.MM.YYYY HH:mm:ss')
      );
      const orderMonth = orderDate.getMonth();
      const orderDay = orderDate.getDate();
      if (acc[orderMonth]) {
        if (acc[orderMonth][orderDay]) {
          acc[orderMonth][orderDay].push(order);
        } else {
          acc[orderMonth][orderDay] = [order];
        }
      } else {
        acc[orderMonth] = {};
        acc[orderMonth][orderDay] = [order];
      }
      return acc;
    }, {})
  );

  return Object.keys(ordersFilterd.current)
    .reverse()
    .map((monthKey) => (
      <Fragment key={Math.random().toString(16)}>
        <div className="history__row history__row--head">
          <span className="history__row--month-devider">
            {utilities.date.getMonthName(monthKey)}
          </span>
        </div>
        {Object.keys(ordersFilterd.current[monthKey])
          .reverse()
          .map((dayKey) =>
            ordersFilterd.current[monthKey][dayKey].map((order) => (
              <HistoryItem key={order.id} isPhone={isPhone} order={order} />
            ))
          )}
      </Fragment>
    ));
}
