import {
  useImperativeHandle,
  useRef,
  forwardRef,
  useMemo,
  useState,
  useEffect,
} from 'react';
import cn from 'classnames';
import { nanoid } from 'nanoid';

const Checkbox = (
  {
    square,
    type = 'checkbox',
    name,
    id,
    aside = true,
    onChange,
    holderClassName,
    labelClassName,
    defaultChecked = false,
    controlledChecked,
    value,
    label,
    error,
    dataset = {},
    onValueChange,
  },
  ref
) => {
  const [checked, setChecked] = useState(defaultChecked || controlledChecked);
  const labelClasses = cn('form-checkbox__label', labelClassName, {
    'form-checkbox__label--square': square,
    'form-checkbox__label--aside': aside,
  });
  const holderClasses = cn('form-checkbox', holderClassName, {
    'form-checkbox--error': error,
  });
  const componentId = useRef(id || `${name}-${nanoid()}`);

  const holderRef = useRef();
  const labelRef = useRef();
  const inputRef = useRef();

  useImperativeHandle(
    ref,
    () => ({
      get input() {
        return inputRef.current;
      },
      get label() {
        return labelRef.current;
      },
      get holder() {
        return holderRef.current;
      },
    }),
    [ref]
  );

  useEffect(() => {
    setChecked(controlledChecked);
  }, [controlledChecked]);

  const datasetProps = useMemo(() => {
    return Object.entries(dataset).reduce(
      (acc, [key, entry]) => ({
        ...acc,
        [`data-${key}`]: entry,
      }),
      {}
    );
  }, [dataset]);

  const handleChange = ({ target }) => {
    if (onChange) onChange(target.checked);
    if (onValueChange) onValueChange(target.value);
  };

  return (
    <div ref={holderRef} className={holderClasses}>
      {defaultChecked ? (
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...datasetProps}
          value={value}
          type={type}
          ref={inputRef}
          hidden
          onChange={handleChange}
          defaultChecked={checked}
          name={name}
          id={componentId.current}
        />
      ) : (
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...datasetProps}
          value={value}
          type={type}
          ref={inputRef}
          hidden
          checked={checked}
          onChange={handleChange}
          name={name}
          id={componentId.current}
        />
      )}

      <label
        ref={labelRef}
        className={labelClasses}
        htmlFor={componentId.current}
      >
        {label}
      </label>
      {error && !square && (
        <span className="form__error-text">{error.message}</span>
      )}
    </div>
  );
};

export default forwardRef(Checkbox);
