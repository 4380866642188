/* eslint-disable import/prefer-default-export */
import getLang from './lang';
import { translate } from './dictionary';

export function toString(value) {
  if (value === 0 || value === '0') return `0 ${translate('руб', getLang())}.`;
  if (!value) return null;
  return `${new Intl.NumberFormat().format(value)} ${translate(
    'руб',
    getLang()
  )}.`;
}
