/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { GlobalBasketContext } from '../../../context/GlobalBasketContext';
import routes from '../../../routes';
import utilities from '../../../utilities';
import BlockSkeleton from '../../Skeleton/Block';

export default function DeliveryPrice() {
  const { watch, getValues } = useFormContext();
  const {
    totalPrice,
    delivery_fee,
    free_delivery_shortage,
    bill_price_for_free,
    setRestInfo,
  } = useContext(GlobalBasketContext);
  const watcher = watch('delivery_type');
  const [isModalOpen, setModalOpen] = useState(false);
  const addressWatcher = watch('helpers_address');
  const [isOutOfBoundaries, setOutOfBoundaries] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState(
    bill_price_for_free
      ? { delivery_fee, free_delivery_shortage, bill_price_for_free }
      : null
  );

  const addAddress = async () => {
    const response = await utilities.api.post(
      routes.apiSwagger.addShoppingCardAddress(),
      {
        point: getValues('point'),
      }
    );
    const { items, ...rest } = response;
    setRestInfo({ ...rest });
  };

  const fetchDeliveryPrice = async (address) => {
    const response = await utilities.api.post(
      routes.apiSwagger.deliveryPrice(),
      {
        point: address.point,
        bill_price: totalPrice,
        establishment: utilities.basket.getEstablishmentId(),
      }
    );
    if (response.error) {
      setOutOfBoundaries(response.detail.non_field_errors[0]);
      setModalOpen(true);
    } else {
      setOutOfBoundaries(false);
      setDeliveryInfo(response);
      addAddress();
    }
  };

  useEffect(() => {
    if (deliveryInfo) {
      setDeliveryInfo((prev) => ({
        ...prev,
        delivery_fee,
        bill_price_for_free,
        free_delivery_shortage,
      }));
    }
  }, [free_delivery_shortage]);

  useEffect(() => {
    if (addressWatcher) {
      setDeliveryInfo(null);
      fetchDeliveryPrice(addressWatcher);
    }
  }, [addressWatcher]);

  if (!watcher) {
    return null;
  }

  if (
    watcher === '1' ||
    (deliveryInfo?.free_delivery_shortage === 0 &&
      deliveryInfo?.bill_price_for_free)
  ) {
    return (
      <span className="delivery-free-remains">
        {utilities.currency.toString(0)}
      </span>
    );
  }

  if (!addressWatcher && !deliveryInfo) {
    return (
      <span className="address-notification">
        {utilities.translate(
          'Введите хотя бы один адрес, чтобы расчитать стоимость доставки'
        )}
      </span>
    );
  }

  if (isOutOfBoundaries) {
    return (
      <>
        <span style={{ color: '#fb5b47' }}>{isOutOfBoundaries}</span>
        {/* <Modal state={[isModalOpen, setModalOpen]}>
          <Title>{isOutOfBoundaries}</Title>
        </Modal> */}
      </>
    );
  }

  if (!deliveryInfo) {
    return <BlockSkeleton width="50%" height={40} />;
  }

  return (
    <>
      <span className="delivery-free-remains">
        {utilities.currency.toString(
          deliveryInfo.price || deliveryInfo.delivery_fee
        )}
      </span>
      {deliveryInfo.price === 0 ? null : (
        <span className="delivery-free-desc">
          Закажите еще на{' '}
          {utilities.currency.toString(
            deliveryInfo.free_delivery_shortage ||
              deliveryInfo.bill_price_for_free - deliveryInfo.bill_price
          )}{' '}
          и за доставку можно не платить, она у нас бесплатная от{' '}
          {utilities.currency.toString(deliveryInfo.bill_price_for_free)}
        </span>
      )}
    </>
  );
}
