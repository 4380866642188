import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import EventTeasers from '../components/Events/EventTeasers';
import Gallery from '../components/Restaurant/Gallery';
import Hero from '../components/Restaurant/Hero';
import Info from '../components/Restaurant/Info';
import Menu from '../components/Restaurant/Menu';
// import Music from '../components/Restaurant/Music';
import routes from '../routes';
import utilities from '../utilities';
import ChefSlider from '../components/Restaurant/Chef';

const Restaurant = () => {
  const [restaurant, setRestaurant] = useState(null);
  const [branches, setBranches] = useState(null);
  const [pictures, setPictures] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchRestaurant = async () => {
      const response = await utilities.api.get(
        routes.apiSwagger.establishments(id)
      );
      setRestaurant(response);
    };
    fetchRestaurant();
  }, []);

  useEffect(() => {
    if (restaurant) {
      setBranches([...restaurant.branches]);
    }
    if (restaurant?.images?.length > 1) {
      const { images } = restaurant;
      setPictures(
        images.reduce(
          (acc, item) => {
            const { file_type: fileType, file } = item;
            if (fileType === 1) {
              acc.bottomSlider.push(file);
            } else if (fileType === 3) {
              acc.restaurantMenu = file;
            } else if (fileType === 5) {
              acc.restaurantCover = file;
            }
            return acc;
          },
          {
            bottomSlider: [],
          }
        )
      );
    }
  }, [restaurant]);

  return (
    <>
      <Helmet>
        <title>{restaurant?.meta_title || 'Madyar'}</title>
        <meta name="title" content={restaurant?.meta_title || ''} />
        <meta name="description" content={restaurant?.meta_description || ''} />
      </Helmet>
      <Breadcrumbs
        exact={{
          [`${id}`]: restaurant?.name,
        }}
      />
      <Hero pictures={pictures} restaurant={restaurant} />
      <Info restaurant={restaurant} branches={branches} />
      <Menu pictures={pictures} restaurant={restaurant} />
      <EventTeasers />
      <ChefSlider />
      {/* <Music restaurant={restaurant} /> */}
      <Gallery pictures={pictures} />
    </>
  );
};

export default Restaurant;
