/* eslint-disable camelcase */
import { createContext, useEffect, useState } from 'react';
import routes from '../../routes';
import utilities from '../../utilities';

export const EstablishmentsContext = createContext();

export const EstablishmentsContextProvider = (props) => {
  const { children } = props;
  const [state, setState] = useState(null);

  useEffect(() => {
    const fetchEstablishments = async () => {
      const response = await utilities.api.get(
        routes.apiSwagger.establishments(),
        {
          establishment_type: '0',
        }
      );
      setState(response);
    };
    fetchEstablishments();
  }, []);

  return (
    <EstablishmentsContext.Provider value={state}>
      {children}
    </EstablishmentsContext.Provider>
  );
};
