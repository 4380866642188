/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { EstablishmentsContext } from '../../../context/Establishments';
import BlockSkeleton from '../../Skeleton/Block';
import RestaurantsSection from './RestaurantsSection';

export default function Establishments() {
  const establishments = useContext(EstablishmentsContext);

  if (!establishments) {
    return (
      <BlockSkeleton
        width="100%"
        height={300}
        className="section"
        label="Слайдер"
      />
    );
  }

  return <RestaurantsSection />;
}
