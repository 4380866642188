/* eslint-disable no-unused-vars */
// @ts-check
import { forwardRef, useMemo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import cn from 'classnames';

const Button = (props, ref) => {
  const {
    children,
    className,
    to,
    htmlFor,
    onClick,
    active,
    color,
    selected,
    size,
    type = 'button',
    phoneSize,
    modifier,
    align,
    disabled,
    captcha,
    linkProps,
    dataset = {},
  } = props;

  const buttonClassName = cn(className, 'button', {
    [`button--active-${active}`]: active,
    [`button--selected`]: selected,
    [`button--${color}`]: color,
    [`button--${size}`]: size,
    [`button--phone-${phoneSize}`]: phoneSize,
    [`button--${modifier}`]: modifier,
    [`button--${align}`]: align,
    [`button--captcha`]: captcha,
  });

  const datasetProps = useMemo(() => {
    return Object.entries(dataset).reduce(
      (acc, [key, entry]) => ({
        ...acc,
        [`data-${key}`]: entry,
      }),
      {}
    );
  }, [dataset]);

  if (htmlFor) {
    return (
      <label className={buttonClassName} htmlFor={htmlFor}>
        {children}
      </label>
    );
  }

  if (to) {
    return (
      <NavLink {...linkProps} className={buttonClassName} to={to}>
        {children}
      </NavLink>
    );
  }

  return (
    <button
      {...datasetProps}
      disabled={disabled}
      onClick={onClick}
      className={buttonClassName}
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {children}
    </button>
  );
};

export default forwardRef(Button);
