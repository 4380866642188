/* eslint-disable no-unused-vars */
import debounce from 'debounce-promise';
import React, { useCallback, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../../icons/search.svg';
import utilities from '../../../utilities';

export default function InputFilter({ initialSearch, onChange }) {
  const inputRef = useRef();
  const location = useLocation();
  const urlWithParameters = decodeURI(location.search);
  const paramFromURL = urlWithParameters.split('search=')[1];

  useEffect(() => {
    inputRef.current.value = paramFromURL || '';
  }, [paramFromURL]);

  const onInputChange = () => {
    onChange(inputRef.current.value);
  };

  const debounceUpdate = debounce(onInputChange, 250, { leading: false });
  const debounceCallback = useCallback(() => {
    debounceUpdate();
  }, []);

  return (
    <div className="search-form search-page">
      <input
        ref={inputRef}
        className="search-form__input"
        onChange={debounceCallback}
        placeholder={utilities.translate('Введите название блюда')}
        defaultValue={initialSearch.current.value}
      />
      <button
        className="search-form__submit search-form__button"
        type="submit"
        aria-label="Найти"
      >
        <SearchIcon className="search-form__button-icon" />
      </button>
    </div>
  );
}
