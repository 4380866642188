import { useContext, useEffect, useState } from 'react';
import { EstablishmentsContext } from '../../../context/Establishments';
import useWindowSize from '../../../hooks/useResize';
import routes from '../../../routes';
import utilities from '../../../utilities';
import Button from '../../Button/Button';
import Description from '../../Description/Description';
import Map from '../../Map';
import ParallaxComponent from '../../Parallax';
import Section from '../../Section/Section';

export default function Delivery({ data }) {
  const establishments = useContext(EstablishmentsContext);
  const { isPhone, isTablet } = useWindowSize();
  const [rerender, setRerender] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setRerender(rerender + 1);
    }, 1000);
  }, [establishments]);

  return (
    <Section>
      <div className="info">
        <Section.Title
          className="info__title"
          dangerHTML={utilities.translate('Доставка по&nbsp;Краснодару')}
        />
        {data && <Description color="black" dangerHTML={data} />}
        <Button
          className="info__button"
          to={routes.page.deliveringRestaurants()}
        >
          {utilities.translate('Доставка еды')}
        </Button>
        {establishments && (
          <ParallaxComponent
            disabled={isPhone || isTablet}
            className="info__map-holder"
            y={['-50%', '20%']}
          >
            <Map
              onLoad={() => {
                setTimeout(() => setRerender((prev) => prev + 1), 1000);
              }}
              className="info__map"
              placemarks={establishments}
              zoom={11}
            />
          </ParallaxComponent>
        )}
      </div>
    </Section>
  );
}
