import cn from 'classnames';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-scroll';
import useObserver from '../../../hooks/useIntersectionObserver';
import utilities from '../../../utilities';
import Description from '../../Description/Description';
import Title from '../../Title/Title';
import ParallaxComponent from '../../Parallax';
import useWindowSize from '../../../hooks/useResize';

export default function Advertisement({ item }) {
  const [isVisible, setVisible] = useState(false);
  const { isPhone, isTablet } = useWindowSize();
  const classNames = cn('collaboration__offer', {
    'collaboration__offer--intersected': isVisible,
  });

  const onIntersect = ([entry]) => {
    if (entry.isIntersecting) {
      setVisible(true);
    }
  };

  const { setObservable, destroyObservable } = useObserver({
    callback: onIntersect,
    triggerOnce: true,
    options: {
      threshold: 0.15,
    },
  });

  const ref = useRef();

  useLayoutEffect(() => {
    setObservable(ref.current);
  }, []);

  useEffect(() => {
    return () => {
      destroyObservable();
    };
  }, []);

  return (
    <article ref={ref} className={classNames}>
      <ParallaxComponent
        disabled={isPhone || isTablet}
        y={['-50px', '100px']}
        className="collaboration__image-holder"
      >
        <img
          className="collaboration__image"
          src={item.image}
          alt={item.title}
        />
      </ParallaxComponent>
      <div className="collaboration__content">
        {item.presentation && (
          <a className="collaboration__presentation" href={item.presentation}>
            {utilities.translate('Презентация.pdf')}
          </a>
        )}
        <Title className="collaboration__title">{item.title}</Title>
        <Description className="collaboration__description">
          {item.description}
        </Description>
        <Link
          className="collaboration__button button"
          to="form"
          smooth
          offset={-250}
          duration={500}
        >
          {utilities.translate('Заказать')}
        </Link>
      </div>
    </article>
  );
}
