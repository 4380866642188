import { NavLink } from 'react-router-dom';
import Description from '../components/Description/Description';
import Section from '../components/Section/Section';
import Title from '../components/Title/Title';
import Circle from '../components/RoutesCircle/Circle';
import routes from '../routes';
import useWindowSize from '../hooks/useResize';

export default function NotFound() {
  const { isDesktop } = useWindowSize();
  return (
    <>
      {isDesktop && <Circle />}
      <Section className="not-found">
        <Title tag="h1">404</Title>
        <Description>
          Данной страницы не существует, но вы можете перейти на{' '}
          <NavLink to={routes.page.home()}>главную страницу</NavLink>.
        </Description>
      </Section>
    </>
  );
}
