import useWindowSize from '../../hooks/useResize';
import GridSkeleton from '../Skeleton/Grid';

const RestaurantPreloader = () => {
  const { isDesktop, isTablet } = useWindowSize();

  if (isDesktop) {
    return (
      <GridSkeleton
        count={6}
        columns={3}
        gap={10}
        proportion={0.7}
        label="Загрузка ресторанов"
      />
    );
  }
  if (isTablet) {
    return (
      <GridSkeleton
        count={6}
        columns={3}
        gap={10}
        proportion={1}
        label="Загрузка ресторанов"
      />
    );
  }
  return (
    <GridSkeleton
      count={6}
      columns={1}
      gap={40}
      proportion={0.5}
      label="Загрузка ресторанов"
    />
  );
};

export default RestaurantPreloader;
