/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { DevTool } from '@hookform/devtools';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, Controller, useForm, useWatch } from 'react-hook-form';
import { ModalContext } from '../../../context/Modal';
import useWindowSize from '../../../hooks/useResize';
import { ReactComponent as StarIcon } from '../../../icons/big-star.svg';
import Select from '../../UI/Form/Select';
import routes from '../../../routes';
import utilities from '../../../utilities';
import Button from '../../Button/Button';
import Checkbox from '../../UI/Form/Checkbox';
import Title from '../../Title/Title';
import FormField from '../../UI/Form/Field';
import ReasonsCheckboxes from './ReasonsCheckboxes';

export default function ReasonForm({ orderId, state, data }) {
  const methods = useForm();
  const [differentModalOpen, setDifferentModalOpen] = state;
  const [formData] = data;
  const { watch, register, control, handleSubmit } = methods;
  const { isPhone } = useWindowSize();
  const [reasons, setReasons] = useState(null);

  useEffect(() => {
    const fetchCollaboration = async () => {
      const response = await utilities.api.get(
        routes.apiSwagger.rateOrderReason()
      );
      setReasons([...response]);
    };
    fetchCollaboration();
  }, []);

  const onSubmit = async (values) => {
    const response = await utilities.api.post(
      routes.apiSwagger.rateOrder(orderId),
      {
        ...formData,
        rate_reasons: Object.entries(values).reduce((acc, [id, value]) => {
          if (value) {
            acc.push(Number.parseInt(id, 10));
          }
          return acc;
        }, []),
      }
    );
    setDifferentModalOpen(false);
  };

  return (
    reasons && (
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="rate-form">
          <Title>
            {utilities.translate('Пожалуйста, укажите причину низкой оценки')}
          </Title>
          <FormField
            holderClassname="catering-order-field"
            columnComponent
            component={<ReasonsCheckboxes data={reasons} />}
          />
          <Button size={isPhone && 'full'} type="submit">
            {utilities.translate('Отправить')}
          </Button>
          {/* <DevTool control={control} /> */}
        </form>
      </FormProvider>
    )
  );
}
